const vnCustomMessages = {
  ra: {
    action: {
      add_filter: "Thêm bộ lọc",
      add: "Thêm",
      back: "Trở về",
      bulk_actions: "%{smart_count} đã chọn",
      cancel: "Hủy bỏ",
      clear_input_value: "Xóa giá trị",
      clone: "Nhân bản",
      confirm: "Xác nhận",
      create: "Tạo mới",
      delete: "Xóa",
      edit: "Sửa",
      export: "Xuất",
      list: "Danh sách",
      refresh: "Làm mới",
      remove_filter: "Bỏ bộ lọc",
      remove: "Xóa bỏ",
      save: "Lưu",
      search: "Tìm kiếm",
      show: "Hiển thị",
      sort: "Sắp xếp",
      undo: "Hoàn tác",
      unselect: "Huỷ chọn",
      expand: "Mở rộng",
      close: "Đóng",
      open_menu: "Mở menu",
      close_menu: "Đóng menu",
    },
    boolean: {
      true: "Có",
      false: "Không",
      null: "",
    },
    page: {
      create: "Tạo %{name}",
      dashboard: "Bảng điều khiển",
      edit: "%{name} #%{id}",
      error: "Đã xảy ra lỗi",
      list: "Danh sách %{name}",
      loading: "Đang tải",
      not_found: "Không tìm thấy",
      show: "%{name} #%{id}",
      empty: "Chưa có %{name} nào.",
      invite: "Bạn có muốn tạo mới không?",
    },
    input: {
      file: {
        upload_several: "Nhấp để chọn một tệp tin hoặc kéo thả nhiều tệp tin để tải lên.",
        upload_single: "Nhấp để chọn một tệp tin hoặc kéo thả để tải lên.",
      },
      image: {
        upload_several: "Nhấp để chọn một bức ảnh hoặc kéo thả nhiều bức ảnh để tải lên.",
        upload_single: "Nhấp để chọn một bức ảnh hoặc kéo thả để tải lên.",
      },
      references: {
        all_missing: "Không thể tìm thấy dữ liệu tham khảo.",
        many_missing: "Ít nhất một tài liệu tham khảo liên quan không còn tồn tại.",
        single_missing: "Tài liệu tham khảo liên quan không còn tồn tại.",
      },
      password: {
        toggle_visible: "Ẩn mật khẩu",
        toggle_hidden: "Hiện mật khẩu",
      },
    },
    message: {
      about: "Về",
      are_you_sure: "Bạn có chắc chắn không?",
      bulk_delete_content:
        "Bạn có chắc chắn muốn xóa %{name} này? |||| Bạn có chắc chắn muốn xóa %{smart_count} vật phẩm này?",
      bulk_delete_title: "Xóa %{name} |||| Xóa %{smart_count} vật phẩm %{name}",
      delete_content: "Bạn có chắc chắn muốn xóa vật phẩm này?",
      delete_title: "Xóa %{name} #%{id}",
      details: "Chi tiết",
      error: "Đã xảy ra lỗi ở phía người dùng và yêu cầu của bạn không thể hoàn thành.",
      invalid_form: "Dữ liệu nhập không đúng. Vui lòng kiểm tra lại",
      loading: "Trang web đang tải, vui lòng đợi trong giây lát",
      no: "Không",
      not_found: "Bạn đã nhập một URL sai hoặc bạn đã làm theo một liên kết không đúng.",
      yes: "Có",
      unsaved_changes: "Một vài thay đổi của bạn chưa được lưu. Bạn có chắc là muốn bỏ qua chúng?",
    },
    navigation: {
      no_results: "Không tìm thấy kết quả nào",
      no_more_results: "Trang số %{page} đã vượt giới hạn. Vui lòng trở lại trang trước.",
      page_out_of_boundaries: "Trang số %{page} đã vượt giới hạn",
      page_out_from_end: "Không thể đi tiếp sau trang cuối",
      page_out_from_begin: "Không thể trở lại trước trang 1",
      page_range_info: "%{offsetBegin}-%{offsetEnd} của %{total}",
      page_rows_per_page: "Số hàng trên mỗi trang:",
      next: "Tiếp",
      prev: "Trước",
    },
    auth: {
      auth_check_error: "Vui lòng đăng nhập để tiếp tục",
      user_menu: "Hồ sơ",
      username: "Tên đăng nhập",
      password: "Mật khẩu",
      sign_in: "Đăng nhập",
      sign_in_error: "Đăng nhập không thành công, vui lòng thử lại",
      logout: "Đăng xuất",
    },
    notification: {
      updated: "Cập nhật thành công |||| Cập nhật thành công %{smart_count} thành phần",
      created: "Đã tạo thành công",
      deleted: "Đã xóa thành công |||| Xóa thành công %{smart_count} thành phần",
      bad_item: "Thành phần không đúng",
      item_doesnt_exist: "Thành phần không tồn tại",
      http_error: "Lỗi giao tiếp với máy chủ",
      data_provider_error: "Lỗi dataProvider. Kiểm tra console để biết chi tiết.",
      i18n_error: "Không thể tải bản dịch cho ngôn ngữ này",
      canceled: "Hủy bỏ hành động",
      logged_out: "Phiên làm việc của bạn đã kết thúc, vui lòng kết nối lại.",
    },
    validation: {
      required: "Bắt buộc",
      minLength: "Phải ít nhất %{min} ký tự",
      maxLength: "Phải nhỏ hơn hoặc bằng %{max} ký tự",
      minValue: "Phải lớn hơn hoặc bằng %{min}",
      maxValue: "Phải nhỏ hơn hoặc bằng %{max}",
      number: "Phải là số",
      email: "Phải là địa chỉ email hợp lệ",
      oneOf: "Phải chọn một trong số lựa chọn sau: %{options}",
      regex: "Phải phù hợp với định dạng (regexp): %{pattern}",
    },
    isEmpty: "%{code} is empty",
    module: {
      "General Ledger": "Sổ cái",
      "General Ledgers": "Sổ cái",
      "Journal Voucher": "Ghi sổ cái",
      "Allocation Voucher": "Chứng từ phân bổ",
      "Standard Journal Voucher": "Nhập chứng từ chuẩn",
      "Template Voucher": "Chứng từ toán sổ cái chuẩn",
      "Recurring Voucher": "Chứng từ kết chuyển cuối tháng",
      "Amortization Voucher": "Chứng từ khấu hao, phân bổ",
      "Amortization Voucher Template": "Chứng từ khấu hao, phân bổ mẫu",
      "Amortize Account": "Tài khoản phân bổ",
      "Account Summary": "Tổng hợp tài khoản",
      "Financial Report": "Financial Report",
      "Chart of accounts": "Hệ thống tài khoản",
      Budget: "Kế hoạch",
      "Account Payable": "Phải trả",
      "Accounts Payable": "Phải trả",
      Vendor: "Cập nhật Nhà cung cấp",
      Information: "Thông tin NCC",
      "Invoice Default": "Định nghĩa hóa đơn",
      "Payment Default": "Định nghĩa thanh toán",
      "AutoPay Information": "Thông tin thanh toán tự động",
      Invoice: "Cập nhật hóa đơn",
      Payment: "Cập nhật thanh toán",
      "Account Receivable": "Phải thu",
      "Accounts Receivable": "Phải thu",
      Profile: "Khách hàng",
      "A/P Invoice": "Cập nhật hóa đơn",
      "A/P Payment": "Cập nhật thanh toán",
      "A/R Profile": "Cập nhật khách hàng",
      "A/R Contract": "Cập nhật hợp đồng",
      "A/R Folio": "Cập nhật Folio",
      "A/R Invoice": "Cập nhật hóa đơn",
      "A/R Receipt": "Cập nhật thanh toán",
      Folio: "Folio",
      Receipt: "Cập nhật thanh toán",
      Asset: "Tài sản",
      "Pre-Register Asset": "Tìm kiếm tài sản",
      "Asset Management": "Quản lý tài sản",
      "Asset management": "Quản lý tài sản",
      "Asset Register": "Đăng ký TS, CCDC",
      "Asset Disposal": "Thanh lý, nhượng bán",
      "Asset Impairment": "Chỉnh sửa tài sản",
      Procedure: "Quy trình",
      Receiving: "Nhận hàng",
      Inventory: "Hàng tồn kho",
      "Apply Recurring Template": "Nhập chứng từ chuẩn",
      "Apply Amortization Template": "Cập nhật chứng từ phân bổ, khấu hao",
      "Posting from": "Ghi sổ từ",
      "Close Period": "Đóng kỳ",
      "Cheque Reconciliation": "Đối soát séc",
      Reconciliation: "Đối soát",
      "Input Tax Reconciliation": "Chỉnh sửa đối soát VAT",
      "Edit VAT Reconciliation": "Chỉnh sửa đối soát VAT",
      "Withholding TAX Reconciliation": "Đối soát thuế nhà thầu nước ngoài",
      "Apply Contract": "Cập nhật hợp đồng",
      Report: "Báo cáo",
      Setting: "Thiết lập",
      Configuration: "Cấu hình",
      "Product License": "Số License",
      "Company Profile": "Thông tin công ty",
      "System Preference": "Tham chiếu hệ thống",
      Users: "Người dùng",
      Permissions: "Cấp quyền",
      Department: "Bộ phận",
      "Payment Type": "Hình thức thanh toán",
      Currency: "Loại tiền",
      "Currency Exchange Rate": "Tỷ giá",
      Dimension: "Mã phân tích",
      Unit: "Đơn vị",
      Interface: "Giao tiếp",
      Email: "Email",
      EmailTemplate: "Email mẫu",
      Workflow: "Quy trình công việc",
      "Activity Log": "Nhật ký",
      "Trial Balance": "Cân đối phát sinh",
      "Account Detail": "Tài khoản chi tiết",
      "Hotel Information": "Thông tin khách sạn",
      "Company Information": "Thông tin công ty",
    },
    actionMenu: {
      Back: "Trở về",
      Add: "Thêm",
      Edit: "Chỉnh sửa",
      Delete: "Xóa",
      Void: "Hủy",
      Copy: "Sao chép",
      Template: "Mẫu",
      "Pre-Asset": "Trước tài sản",
      Print: "In",
      Printed: "In",
      Payment: "Thanh toán",
      Receipt: "Biên nhận thanh toán",
      Disposal: "Thanh lý",
      Effective: "Hiệu lực",
      Normal: "Bình thường",
      Draft: "Chưa ghi sổ",
      Posted: "Được đăng",
      POST: "ĐĂNG",
      CLOSE: "ĐÓNG",
      APPLY: "ÁP DỤNG",
      OK: "ĐƯỢC",
      VIEW: "Xem",
    },
    info: {
      Unbalanced: "Unbalanced. Please check debit or credit amount",
      notransaction: "Không tồn tại giao dịch",
      help: "Trợ giúp",
      maxSizeUpload: "Kích thước tệp tải lên tối đa là %{size} Mb",
      maxImageSizeUpload: "Kích thước tệp tải lên tối đa là %{size} Mb",
      reportNotFound: "Báo cáo không tìm thấy",
      Success: "Thành công",
      Warning: "Cảnh báo",
      Information: "Thông tin",
      Error: "Lỗi",
    },
    question: {
      confirmDel: "Xác nhận xóa bỏ?",
      confirmVoid: "Xác nhận hủy bỏ?",
      confirmPrintCheque: "Xác nhận in séc?",
      currencyWarning: "Do you want to update the currency rate ?",
    },
    field: {
      " ": " ",
      "#": "#",
      "No.": "Số",
      All: "Tất cả",
      "Unpaid Invoice": "Hóa đơn chưa thanh toán",
      "Outstanding Invoice": "Hóa đơn chưa thanh toán",
      "Paid Invoice": "Hóa đơn đã thanh toán",
      "Settled Invoice": "Hóa đơn đã thanh toán",
      "Open Period": "Đầu kỳ",
      "Period Date": "Khoảng thời gian",
      User: "Người dùng",
      Username: "Tên người dùng",
      Password: "Mật khẩu",
      ConfirmPassword: "Xác nhận mật khẩu",
      "Business Unit": "Đơn vị kinh doanh",
      Language: "Ngôn ngữ",
      ViewBy: "Xem bởi",
      FilterBy: "Xem bởi",
      Date: "Ngày",
      Prefix: "Tiền tố",
      "Journal Voucher": "Chứng từ sổ cái",
      "Standard Journal Voucher": "Chứng từ chuẩn",
      "Journal Voucher No.": "Số chứng từ",
      Description: "Diễn giải",
      "Description (Local)": "Diễn giải (Tiếng Việt)",
      "Voucher No.": "Số chứng từ",
      "Recurring Every Period": "Lặp lại mỗi kỳ",
      Code: "Mã",
      From: "Từ",
      To: "Đến",
      FromAcc: "Từ tài khoản",
      ToAcc: "Đến tài khoản",
      Source: "Nguồn",
      Status: "Trạng thái",
      Active: "Kích hoạt",
      "In-Active": "Hủy kích hoạt",
      Account: "Tên tài khoản",
      "Account #": "Tài khoản",
      Department: "Bộ phận",
      "Dept.": "Bộ phận",
      "Department Name": "Tên bộ phận",
      "Acc.": "Tài khoản",
      "Account Code": "Tài khoản",
      "Account Name": "Tên tài khoản",
      Currency: "Loại tiền",
      Rate: "Tỷ lệ",
      "Rate (%)": "Tỷ lệ (%)",
      Nature: "Thuộc tính",
      Debit: "Ghi Nợ",
      Credit: "Ghi Có",
      Address: "Địa chỉ",
      Amount: "Số tiền (NT)",
      "Dr. Amount": "Số tiền ghi Nợ (NT)",
      "Cr. Amount": "Số tiền ghi Có (NT)",
      Base: "Số tiền",
      "Dr. Base": "Số tiền ghi Nợ",
      "Cr. Base": "Số tiền ghi Có",
      Total: "Tổng tiền",
      "Total Cost": "Tổng tiền giá mua ",
      "Cheq. Date": "Ngày lập UNC",
      "Cheq. No. From": "Từ số",
      "Cheq. No. To": "Đến số",
      "Clearing Date": "Ngày thanh toán",
      "Clearing Amount": "Số tiền thanh toán",
      Comment: "Ghi chú",
      "Base Summary": "Tổng tiền trước thuế",
      "A/R Profile": "Tìm kiếm khách hàng",
      "A/R No.": "Mã khách",
      "AR No.": "Mã khách",
      Title: "Danh xưng",
      Type: "Nhóm",
      "First Name": "Họ, chữ lót",
      "Last Name": "Tên",
      "Register Date": "Ngày tạo",
      Name: "Tên khách",
      "Contact Name": "Chú ý",
      "Company Name": "Tên công ty",
      Company: "Công Ty",
      Position: "Chức vụ",
      Telephone: "Số điện thoại",
      "TAX ID.": "Mã số thuế",
      "View Invoice": "View Invoice",
      Email: "Email",
      Remark: "Ghi chú",
      Address1: "Địa chỉ 1",
      Address2: "Địa chỉ 2",
      Address3: "Địa chỉ 3",
      Payment: "Hình thức thanh toán",
      "Credit Limit": "Hạn mức công nợ",
      "Credit Term": "Thời hạn công nợ",
      Billing: "Thanh toán",
      "Billing To": "Gửi bill đến",
      "Mailing To": "Gửi mail đến",
      "Tax Invoice Address": "Địa chỉ hóa đơn",
      "A/R Contract": "Hợp đồng",
      Contract: "Hợp đồng",
      "Contract No.": "Số hợp đồng",
      "Start Date": "Ngày bắt đầu",
      "End Date": "Ngày kết thúc",
      "Start Contract": "Ngày bắt đầu",
      "End Contract": "Ngày kết thúc",
      Owner: "Chủ đầu tư",
      Project: "Khách sạn",
      "Charge Every Month": "Giá trị mỗi tháng",
      ThisYear: "Năm nay",
      Year: "Năm",
      Revision: "Sửa đổi",
      "Vendor No.": "Mã nhà cung cấp",
      "Vendor Name": "Tên nhà cung cấp",
      "Vendor Category": "Nhóm",
      "WHT. Category": "Nhà cung cấp nhà thầu nước ngoài",
      "WHT. Name": "Tên nhà thầu nước ngoài",
      "Chq. Name": "Tên séc",
      Fax: "Fax",
      Reference: "Tham chiếu",
      "Mapping Ref.": "Mapping tham chiếu",
      "Branch No.": "Mã Chi nhánh",
      "Bus Reg No.": "Mã số doanh nghiệp",
      "Discount Term": "Thời hạn chiết khấu",
      "Less (%)": "Tỷ lệ",
      "Vat Type 1": "Loại thuế VAT 1",
      "Vat Rate 1 (%)": "Thuế suất VAT 1 (%)",
      "Dept. Dr Vat 1": "BP thuế VAT 1",
      "Acc. Dr Vat 1": "TK thuế VAT 1",
      "Vat Type 2": "Loại thuế VAT 2",
      "Vat Rate 2 (%)": "Thuế suất VAT 2 (%)",
      "Dept. Dr Vat 2": "BP thuế VAT 2",
      "Acc. Dr Vat 2": "TK thuế VAT 2",
      "Cr A/P Dept.": "Bộ phậnCông nợ",
      "Cr A/P Acc.": "Tài khoản Công nợ",
      "Cr. Payment Dept.": "Ghi Có bộ phận thanh toán",
      "Cr. Payment Acc.": "Ghi Có tài khoản thanh toán",
      "Withholding Tax": "Thuế nhà thầu",
      "WHT. Code": "WHT. Code",
      "Cr. WHT. Dept.": "Ghi Có bộ phận khấu lưu",
      "Cr. WHT. Acc.": "Ghi Có tài khoản khấu lưu",
      "WHT. Account": "Tài khoản khấu lưu",
      "Bank Charge": "Phí ngân hàng",
      "Bank Name": "Tên ngân hàng",
      "Acc Name": "Tên tài khoản",
      "Acc No": "Tài khoản",
      "Bank Ref": "Ngân hàng tham chiếu",
      "Bank Ref 1": "Ngân hàng tham chiếu 1",
      "Bank Ref 2": "Ngân hàng tham chiếu 2",
      "Bank Ref 3": "Ngân hàng tham chiếu 3",
      Width: "Bề rộng",
      Seq: "Mã số giao dịch",
      "Invoice No.": "Số hóa đơn",
      "Rcpt. Ref No.": "Số biên nhận thanh toán",
      Vendor: "Nhà cung cấp",
      "Input Date": "Ngày lập",
      "Invoice Date": "Ngày hóa đơn",
      "Cheque No": "Số Séc",
      "Cheque Date": "Ngày lập Séc",
      "Due Date": "Ngày đáo hạn",
      FolioDate: "Ngày Folio",
      "Folio Date": "Ngày Folio",
      "Folio No.": "Số Folio",
      "Guest No.": "Mã số khách",
      "Tax Inv No.": "Số hóa đơn",
      "Tax Status": "Trạng thái hóa đơn",
      "Tax Inv Date": "Ngày hóa đơn",
      Period: "Kỳ kế toán",
      "Tax Period": "Kỳ kế toán",
      "Default Tax Period": "Kỳ thuế mặc định",
      "Default clearing date": "Ngày thanh toán mặc định",
      "Payment No.": "Mã số thanh toán",
      "Payment Date": "Ngày thanh toán",
      "Payment Type": "Hình thức thanh toán",
      Payee: "Người thụ hưởng",
      "WHT. Service Type": "WHT. Service Type",
      "WHT. Form": "Từ nhà thầu",
      "WHT. No": "Đến nhà thầu",
      "WHT. Desc": "Diễn giải nhà thầu nước ngoài",
      "WHT. Ref.": "WHT. Ref.",
      "WHT. Rate %": "% Tỷ lệ nhà thầu nước ngoài",
      "WHT. Amount": "Số nhà thầu nước ngoài",
      "Withholding Tax Date": "Withholding Tax Date",
      "WHT Condition": "WHT Condition",
      Ref: "Số tham chiếu",
      "Ref No.": "Số tham chiếu",
      Paid: "Trả",
      "Base Paid": "Trả",
      Unpaid: "Số dư",
      "Base Unpaid": "Số dư",
      "Total Unpaid": "Tổng chưa thanh toán",
      "Total Outstanding": "Tổng chưa thu",
      "Payment Amount": "Số tiền thanh toán",
      "Settlement Base Amount": "Tổng tiền thanh toán",
      "Settlement Amount": "Tổng tiền thanh toán",
      "Net Amount": "Tiền trước thuế",
      "Net Payment": "Tiền trước thuế",
      "Payment Account": "Tài khoản thanh toán",
      Tax: "Tiền thuế",
      TAX: "Tiền thuế",
      "Tax Amount": "Tiền thuế",
      "Tax 1": "Thuế 1",
      "Tax 2": "Thuế 2",
      "Tax Base Amount": "Số tiền cơ sở tính thuế",
      "Tax Type": "Loại thuế",
      "Tax Rate": "Thuế suất",
      "Tax Rate %": "Thuế suất (%)",
      "Tax Account Code": "Mã tài khoản thuế",
      "Dept. Code": "Mã bộ phận",
      "Acc. Code": "Tài khoản",
      "Dr Account": "Ghi Nợ tài khoản",
      "Dr Dept. Code": "Bộ phận Nợ",
      "Dr Acc. Code": "Tài khoản Nợ",
      "Cr Dept. Code": "Bộ phận Có",
      "Cr Account": "Ghi Có tài khoản",
      "Cr Acc. Code": "Tài khoản Có",
      "Tax Dr Dept. Code": "Tax Dr. Dept. Code",
      "Tax Dr Acc. Code": "TK (thuế) Nợ",
      "Tax Cr Dept. Code": "Tax Cr. Dept. Code",
      "Tax Cr Acc. Code": "TK (thuế) Có",
      "Receipt Ref. No.": "Số chứng từ",
      "Receipt Date": "Ngày chứng từ",
      "Pay Ref No.": "Số tham chiếu",
      "Pay Date": "Ngày thanh toán",
      "Pay Type": "Hinh thức thanh toán",
      "Asset Name": "Tên tài sản cố định",
      "Asset No.": "Mã tài sản",
      "Acquire Date": "Ngày mua",
      BarCode: "Mã vạch",
      Category: "Nhóm tài sản",
      Location: "Mã kho",
      "Serial No.": "Ký hiệu",
      "Transfer Date": "Ngày đăng ký",
      Specification: "Quy cách",
      "Asset Life": "Tuổi thọ TS, CCDC",
      "Init Accu Depre": "Đã khấu hao",
      "Salvage/Unit": "Đơn giá thu hồi",
      "Total Salvage": "Giá trị thu hồi",
      "Amount/Unit": "Đơn giá mua (NT)",
      "Price/Unit": "Nguyên giá",
      Price: "Giá",
      Group: "Nhóm",
      Unit: "Đơn vị tính",
      Qty: "Số lượng",
      Overwrite: "Ghi đè lên",
      Available: "Có sẵn",
      "Settle on": "Settle on",
      "Base Amt.": "Giá mua",
      "Base Amount": "Giá mua",
      "Net Book Value": "Giá trị còn lại",
      "Total Value": "Tổng nguyên giá tài sản",
      "Life (Days)": "Số ngày khấu hao, phân bổ",
      "Cost Dept.": "Bộ phận mua",
      "Cost Acc.": "Tài khoản TS, CCDC",
      "Asset Dept.": "Bộ phận mua",
      "Asset Acc.": "Tài khoản TS, CCDC",
      "Accu Dept.": "Bộ phận hao mòn",
      "Accu Acc.": "TK hao mòn lũy kế",
      Depreciation: "khấu hao",
      "Depre Dept.": "Bộ phận khấu hao",
      "Depre Acc.": "TK chi phí khấu hao",
      "Accu Depre.": "TK hao mòn lũy kế",
      LastCost: "Giá mua gần nhất",
      "Disposal Account": "Tài khoản thanh lý",
      "Disposal Type": "Loại thanh lý",
      "Disposal Amount": "Tiền thanh lý",
      "Sale Amount": "Tiền thanh lý",
      "Impairment Value": "Tiền suy giảm tài sản",
      "Impairment Amount": "Tiền suy giảm tài sản",
      "Disposal Date": "Ngày thanh lý",
      "Gain/Loss Amount": "Lãi/Lỗ",
      "Total Asset Value": "Tổng nguyên giá tài sản",
      "Asset Department": "Bộ phận",
      "Asset Account": "Tài khoản TS, CCDC",
      "Accu Department": "Bộ phận hao mòn",
      "Accu Account": "TK hao mòn lũy kế",
      "Gain/Loss Department": "Lãi lỗ bộ phận",
      "Gain/Loss Account": "TK chi phí lãi/lỗ",
      "Sale Department": "Doanh thu bộ phận",
      "Sale Account": "TK Doanh thu",
      "Impairment Account": "Tài khoản suy giảm tài sản",
      "Cr. Impairment Department": "Bộ phận suy giảm Nợ",
      "Dr. Impairment Department": "Bộ phận suy giảm Có",
      "Cr. Impairment Account": "Tài khoản suy giảm Nợ",
      "Dr. Impairment Account": "Tài khoản suy giảm Có",
      "Accu Depre Department": "Bộ phận hao mòn",
      "Accu Depre Account": "TK hao mòn lũy kế",
      Actual: "Thực tế",
      "Variance (%)": "Độ lệch (%)",
      "Last Year": "Năm rồi",
      "Annual Budget": "Ngân sách năm",
      "Revision Budget": "Ngân sách sửa đổi",
      "Hotel Name": "Tên khách sạn ",
      "Register Name": "Tên đăng ký",
      "Company Register ID": "Mã số doanh nghiệp",
      "Branch ID": "Mã chi nhánh ",
      "Opening Balance": "Số dư đầu kỳ",
      "This Month": "Số phát sinh",
      "Closing Balance": "Số dư cuối kỳ",
      Balance: "Balance",
      "Last Closed Period": "Kỳ vừa đóng",
      "Current Period": "Kỳ hiện hành",
      "Amount to be allocated": "Số tiền (NT) phân bổ",
      "Allocate Unit": "Đơn vị tính được phân bổ",
      "Amortize Amount": "Số tiền (NT) phân bổ",
      "Amortized by": "Được phân bổ bởi",
      "Amount to Amortize": "Số tiền (NT) phân bổ",
      "Base Amount to Amortize": "Số tiền phân bổ",
      "Use In A/P": "Use In A/P",
      "Use In A/R": "Use In A/R",
      "Previous Day": "Ngày trước đó",
      Summary: "Tóm tắt",
      Undue: "Chưa tới hạn",
      Overdue: "Quá hạn",
      Current: "Hiện hành",
      MTD: "LKT - Lũy kế tháng",
      YTD: "LKN",
      "YTD-Last Year": "LKN - Năm vừa rồi",
      "YTD Budget": "LKN Ngân sách",
      "Setup Lookup": "Tra cứu thiết lập",
      Revenue: "Doanh thu",
      "Market Segment": "Thị phần",
      "Statistical Data": "Thống kê",
      "Days In Month": "Days In Month",
    },
    fieldAbbr: {
      select: "Lựa chọn",
      searchby: "Tìm theo ",
      date: "Ngày",
      prefix: "Tiền tố",
      type: "Loại",
      jv: "Ghi sổ cái",
      jvNo: "Số chứng từ",
      desc: "Diễn giải",
      vouNo: "Số chứng từ",
      source: "Nguồn",
      status: "Trạng thái",
      active: "Hoạt động",
      inactive: "Ẩn, không hoạt động",
      account: "Tài khoản",
      account1: "Số tài khoản",
      dept: "Bộ phận",
      deptName: "Tên bộ phận",
      SelectAccountCode: "Chọn mã tài khoản",
      accountCode: "Tài khoản",
      accountName: "Tên tài khoản",
      currency: "Loại tiền",
      rate: "Tỷ lệ",
      percent: "Phần trăm",
      ratePercent: "Tỷ lệ (%)",
      nature: "Thuộc tính",
      dr: "Ghi Nợ",
      cr: "Ghi Có",
      address: "Địa chỉ",
      amount: "Số tiền (NT)",
      base: "Số tiền",
      totalAmount: "Tổng tiền",
      cheqDate: "Ngay lập séc",
      cheqNoFrom: "Từ số",
      cheqNoTo: "Đến số",
      clearingDate: "Ngày thanh toán",
      comment: "Ghi chú",
      "Selected Folio": "Folio đã cập nhật",
      "Unselected Folio": "Folio chưa cập nhật",
    },
    gl: {
      allocateAmtWarnningZero: "Cannot process, allocate amount is zero",
      allocateUnitWarnningZero: "Cannot process, allocate unit is zero",
      "Allow edit JV. from posting": "Cho phép sửa chứng từ đã ghi sổ",
      "Allow edit JV. from posting desc": "Allow to edit Journal Voucher that posted from other source",
      "Reverse Account Nature When Negative": "Reverse Account Nature When Negative",
      "Reverse Account Nature When Negative desc": "Allow entry negative amount in ledger",
      "Reverse Account Nature When Negative info":
        "Inactive : JV posted from other source if negative amount then auto change Dr. > Cr., Cr.<Dr. \nNot allow to save JV if amount is negative",
      "Retain Earning Account": "Giữ lại tk thu",
      "Gl Posting Account": "Gl Posting Account",
      "Gl Posting Account info":
        "CM4 force department code in transaction and all mapping from other system force to identify department of transaction so this configuration should be removed",
      "Fiscal Year": "Năm tài chính",
      "Closed Period": "Xem dư cuối kỳ",
      "Current Period": "Xem hiện hành kỳ",
    },
    ap: {
      "Aging Period": "Tuổi nợ",
      "Payment Posting": "Thanh toán đã ghi sổ",
      "Default Tax Invoice Status": "Trạng thái mặc định HĐ",
      "Default Unit": "Đơn vị tính mặc định",
      "Allow duplicate Cheque No.": "Cho phép trùng số chứng từ",
      "Allow to edit posting invoice": "Cho phép chỉnh sửa hóa đơn đã ghi sổ",
      "Allow to delete posting invoice": "Cho phép xóa hóa đơn đã ghi sổ",
      "Allow re-print form cheque": "Cho phép in lại chứng từ thanh toán",
      "Allow create duplicate TaxID on vendor profile": "Cho phép tạo nhà cung cấp cùng MST",
      "Enable Thai Tax": "Bật thuế Thái",
      "Auto Posting Vat Reconcile JV": "Tự động ghi sổ chứng từ đối soát VAT",
    },
    apInvoice: {
      title: "Hóa đơn",
      delInvoiceSettled: "Không thể void, hóa đơn nãy đã được thanh toán",
      settleWarnning:
        "Hóa đơn đã được thanh toán hoặc được chuyển đến từ một hệ thống khác hoặc đang trong kỳ kế toán đã đóng sổ. Hệ thống chỉ cho chỉnh sửa thông tin hóa đơn thuế. Vui lòng xác nhận nếu bạn muốn sửa đổi",
      invoiceAmountWarnningZero: "Không thể xử lý vì số tiền thanh toán bằng 0",
      invoiceAmountWarnning1: "Không thể xử lý vì số tiền trên hóa đơn nhỏ hơn số tiền thanh toán",
      invoiceAmountWarnning2: "Số tiền trên hóa đơn nhỏ hơn số tiền thanh toán",
      folioAmountWarnning1: "Cannot process, paid is more than unpaid.",
    },
    apPayment: {
      title: "Thanh toán",
      closePeriodWarnning:
        "Mục chỉnh sửa đang trong kỳ kế toán đã đóng sổ. Bạn ch ỉ có thể chỉnh sửa thông tin sec thanh toán. Vui lòng xác nhận nếu bạn mn sửa đổi.",
      chequeWarnning: "Cheque number or cheque date cannot be empty.",
    },
    ar: {
      "Default Value (A/R Invoice)": "Giá trị mặc định (Hóa đơn)",
      "(Debit) Account Code": "Tài khoản Nợ",
      "(Credit) Account Code": "Tài khoản Có",
      "Default Value (A/R Receipt)": "Giá trị mặc định (thanh toán)",
      "Copy Receipt Ref No. to Tax Invoice": "Sao chép số tham chiếu thanh toánh đến hóa đơn thuế",
      "Allow Edit Folio In (A/R Invoice)": "Allow Edit Folio In (A/R Invoice)",
      amountWarnningZero: "Cannot process, amount is zero.",
      receiptAmountWarnning1: "Cannot process, payment amount is less than settlement amount.",
      "Invoice - Tax Invoice Prefix": "Invoice - Tax Invoice Prefix",
      checkAcc: "Please Check transaction seq = %{row} Acc. Code is Empty",
    },
    asset: {
      disposed: "Tài sản này đã được thanh lý",
      "Asset Register Number Format": "Định dạng số đăng ký tài sản",
      "Enable Asset Barcode": "Bật mã vạch tài sản",
      "Reverse Assets and Acc. Depre. Account": "Tài sản dự trữ và TK hao mòn lũy kế",
      "Set Asset Account Code for Posting": "Thiết lập tài khoản TS, CCDC để ghi sổ",
    },
    closePeriod: {
      warning: "Hành động này không thể thực hiện được vì nằm trong kỳ đã khóa sổ",
      dateWarning:
        "Ngày thanh toán, ngày thanh toán bù trừ hoặc ngày kiểm tra đang trong kỳ kế toán đã đóng. Vui lòng nhập nó một cách chính xác",
      setclearing: "Ngày thanh toán cần cứ chứng tù ngân hàng",
    },
    permission: {
      denied: "Truy cập bị từ chối",
      selectVendorFirst: "Vui lòng chọn nhà cung cấp trước",
      selectProfileFirst: "Vui lòng chọn khách hàng trước.",
      selectWHTAccount: "Vui lòng chọn tài khoản khấu lưu",
      selectGainLossAccount: "Vui lòng chọn tài khoản lãi lỗ",
    },
  },
};

export default vnCustomMessages;
