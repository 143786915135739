import { differenceInDays, format } from "date-fns";
import decodeJwt from "jwt-decode";
import axiosAuth from "utils/request";
import axios from "axios";
import SnackbarUtils from "utils/SnackbarUtils";
import { checkIsUsedHq } from "services/hq";

const listOfCompany = window.config.arrCompany;
var { apiUrl, adminToken } = window.config;

function InitApiUrl() {
  let url = new URL(window.location.href);
  let tkFromDesktopApp = url.searchParams.get("tk");
  let desktopUrlApi = url.searchParams.get("urlApi");
  if (tkFromDesktopApp) {
    const DecodeToken = decodeJwt(tkFromDesktopApp);
    localStorage.setItem("adminToken", DecodeToken.AdminToken);
    apiUrl = desktopUrlApi;
    adminToken = DecodeToken.adminToken;
  } else {
    let aToken = localStorage.getItem("adminToken");
    if (aToken) {
      let obj = listOfCompany.find((i) => i.adminToken === aToken);
      apiUrl = obj.apiUrl;
      adminToken = obj.adminToken;
    } else {
      apiUrl = window.config.apiUrl;
      adminToken = window.config.adminToken;
    }
  }
}

async function CheckLicense(accessToken) {
  const { data, error } = await axios.get(`${apiUrl}/api/licenseInterface/false`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  });

  if (error) {
    let url = new URL(window.location.origin);
    window.location.replace(url + "#/login");
  }

  if (!data) {
    return null;
  }

  let licObj = data?.find((i) => i?.Description === "GL");
  if (licObj) {
    let expDate = new Date(licObj?.LicenseInterface?.ExpireDate);
    return expDate;
  } else {
    return new Date(data[0]?.LicenseInterface?.ExpireDate);
  }
}

const ReleaseInfoModal = () => {
  localStorage.setItem("userLastSeenUpdateVersion", window.VersionList[0].Version);
  
  return SnackbarUtils.custom(
    {
      position: "top",
      icon: "info",
      customClass: {
        container: "swalContainer",
      },
      title: "Introduction",
      html: `We're excited to announce the release of Version ${window.VersionList[0].Version} of Carmen Cloud. This update includes major new features, performance improvements, and bug fixes to enhance your experience.
      We value your feedback! If you encounter any issues or have suggestions, please contact our support team at <a href="mailto:support@carmensoftware.com"> support@carmensoftware.com </a>`,
      allowOutsideClick: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Learn More",
      showCloseButton: true,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
    },
    function () {
      var arrayVersionList = window.VersionList[0];
      var str = "";
      //New Features
      if (arrayVersionList["New Features"].length > 0) {
        str += `<h4 style="text-align:left;">New Features</h4>
        <ul class="release-note-list">`;
        arrayVersionList["New Features"].forEach(function (i) {
          str += "<li>" + i + "</li>";
        });
        str += `</ul>`;
      }
      //Enhancements
      if (arrayVersionList["Enhancements"].length > 0) {
        str += `<h4 style="text-align:left;">Enhancements</h4>
                  <ul class="release-note-list">`;
        arrayVersionList["Enhancements"].forEach(function (i) {
          str += "<li>" + i + "</li>";
        });
        str += `</ul>`;
      }
      //Bug Fixes
      if (arrayVersionList["Bug Fixes"].length > 0) {
        str += `<h4 style="text-align:left;">Bug Fixes</h4>
      <ul class="release-note-list">`;
        arrayVersionList["Bug Fixes"].forEach(function (i) {
          str += "<li>" + i + "</li>";
        });
        str += `</ul>`;
      }

      SnackbarUtils.custom(
        {
          position: "top",
          icon: "",
          width: 640,
          //title: "V 3.300 (2024-06-10)",
          html: str,
          allowOutsideClick: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "More Detail",
          showCancelButton: true,
          cancelButtonText: "Close",
          backdrop: true,
          showCloseButton: true,
          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `,
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `,
          },
        },
        function () {
          window.open(window.VersionList[0].LinkDoc, "_blank").focus();
        }
      );
    }
  );
};

const authProvider = {
  login: async ({ Username, Password, Tenant, Language }) => {
    InitApiUrl();
    try {
      const { data, status } = await axios.post(
        `${apiUrl}/api/login?adminToken=${adminToken}`,
        { Username, Password, Tenant, Language },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (status < 200 || status === 204 || status >= 300) {
        throw new Error(data.UserMessage);
      }

      const DecodeToken = decodeJwt(data.AccessToken);
      localStorage.setItem("AccessToken", data.AccessToken);
      localStorage.setItem("Permissions", JSON.stringify(data.Permissions));
      axiosAuth.defaults.headers.common["Authorization"] = data.AccessToken;
      localStorage.setItem("UserName", DecodeToken.UserName);
      if (data.Photo) {
        localStorage.setItem("UserPhoto", data.Photo);
      }
      localStorage.setItem("Tenant", DecodeToken.Tenant);
      localStorage.setItem("Language", Language);
      localStorage.setItem("adminToken", DecodeToken.AdminToken);

      //CheckLicense Expire After Login
      var expiryDate = await CheckLicense(data.AccessToken);
      if (expiryDate) {
        localStorage.setItem("expLicense", expiryDate);
        var days = differenceInDays(expiryDate, new Date());
        if (days > 30 && days <= 60 && !localStorage.getItem("userLastSeenWarningLicense")) {
          SnackbarUtils.warning(
            `Product license will be expired in ${days} day(s) (${format(
              expiryDate,
              "dd/MM/yyyy"
            )}).\nPlease contact your administrator to extend the license.`,
            function () {
              localStorage.setItem("userLastSeenWarningLicense", 1);
              //TODO: modify modal noti new version
              if (localStorage.getItem("userLastSeenUpdateVersion") !== window.VersionList[0].Version) {
                ReleaseInfoModal();
              }
            }
          );
        }
        if (days <= 30) {
          SnackbarUtils.warning(
            `Product license will be expired in ${days} day(s) (${format(
              expiryDate,
              "dd/MM/yyyy"
            )}).\nPlease contact your administrator to extend the license.`,
            function () {
              if (localStorage.getItem("userLastSeenUpdateVersion") !== window.VersionList[0].Version) {
                ReleaseInfoModal();
              }
            }
          );
        }
        if (days < 0) {
          SnackbarUtils.warning(
            `Product license has expired since ${format(
              expiryDate,
              "dd/MM/yyyy"
            )}.\nPlease contact your administrator to extend the license.`,
            function () {
              throw new Error("License Expired");
            }
          );
        }
        if (days > 60) {
          if (localStorage.getItem("userLastSeenUpdateVersion") !== window.VersionList[0].Version) {
            ReleaseInfoModal();
          }
        }
      }

      return data;
    } catch (e) {
      throw new Error(e.response ? e.response.data.UserMessage : e);
    }
  },
  getTenantByUserName: async ({ Username }) => {
    InitApiUrl();
    const { data, status } = await axios(`${apiUrl}/api/userTenant/tenantListIn/${adminToken}/${Username}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "Application/json",
      },
    });
    if (status < 200 || status === 204 || status >= 300) {
      throw new Error(data.UserMessage);
    } else {
      localStorage.setItem("UserName", Username);
      localStorage.setItem("adminToken", adminToken);
    }
    return data;
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("AccessToken");
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem("AccessToken");
    if (token && token !== null) {
      const { exp } = decodeJwt(token);
      return new Date().getTime() <= exp * 1000 ? Promise.resolve() : Promise.reject();
    } else {
      return Promise.reject();
    }
  },
  logout: async () => {
    let adminToken = localStorage.getItem("adminToken") ?? window.config.adminToken;
    const obj = listOfCompany.find((i) => i.adminToken === adminToken);
    const token = localStorage.getItem("AccessToken");
    if (obj && token) {
      const { exp } = decodeJwt(token);
      if (new Date().getTime() <= exp * 1000) {
        await axios(`${obj.apiUrl}/api/logout`, {
          method: "delete",
          headers: {
            "Content-Type": "application/json",
            Accept: "Application/json",
            Authorization: `${token}`,
          },
        });
      } else {
        Promise.reject();
      }
    }
  },
  getAPIVersion: async () => {
    InitApiUrl();
    const { data } = await axios(`${apiUrl}/api/version`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "Application/json",
      },
    });
    localStorage.setItem("APIVersion", data);
    return data;
  },
  getIdentity: async () => {
    try {
      const c = await checkIsUsedHq(localStorage.getItem("Tenant"));
      if (c) {
        localStorage.setItem("isUsedHq", c);
      }

      return Promise.resolve({
        id: "user",
        fullName: `${localStorage.getItem("UserName")} @${localStorage.getItem("TenantDesc")}`,
        avatar: localStorage.getItem("UserPhoto")
          ? `data:image/png;base64,${localStorage.getItem("UserPhoto")}`
          : undefined,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getSettingAll: async (url) => {
    InitApiUrl();
    try {
      if (url) {
        const { data } = await axiosAuth(`${url}/setting/all`, {
          method: "get",
        });

        if (data) {
          localStorage.setItem("SettingAll", JSON.stringify(data));
        } else {
          const url = new URL(window.location.origin);
          if (window.location.pathname.search("carmen") === -1) {
            window.location.replace(url + "#/login");
          } else {
            window.location.replace(url + "carmen/#/login");
          }
        }

        return data;
      } else {
        let adminToken = localStorage.getItem("adminToken") ?? window.config.adminToken;
        const obj = listOfCompany.find((i) => i.adminToken === adminToken);
        const { data } = await axiosAuth(`${obj.apiUrl}/api/setting/all`, {
          method: "get",
        });

        if (data) {
          localStorage.setItem("SettingAll", JSON.stringify(data));
        } else {
          const url = new URL(window.location.origin);
          if (window.location.pathname.search("carmen") === -1) {
            window.location.replace(url + "#/login");
          } else {
            window.location.replace(url + "carmen/#/login");
          }
        }

        return data;
      }
    } catch (error) {
      let url = new URL(window.location.href);
      if (url) {
        let callFromDesktopApp = url.searchParams.get("ShowOnlyDashboard") === "true";
        if (callFromDesktopApp) {
          return false;
        } else {
          SnackbarUtils.error(error, function () {
            window.location.replace(url + "#/login");
          });
        }
      }
    }
  },
  getPermissions: () => {
    const p = JSON.parse(localStorage.getItem("Permissions"));
    return p ? Promise.resolve(p) : Promise.reject();
  },
  getForgotPasswordCode: async (adminToken, username, tenant) => {
    const { data } = await axios(
      `${apiUrl}/api/user/forgotPassword/${username}?adminToken=${adminToken}&tenant=${tenant}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Accept: "Application/json",
        },
      }
    ).catch((error) => {
      SnackbarUtils.error(error.response.data.UserMessage);
      return false;
    });
    return data;
  },
  resetPassword: async (adminToken, param) => {
    const r = await axios
      .post(
        `${apiUrl}/api/user/resetPassword?adminToken=${adminToken}`,
        { ...param },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        SnackbarUtils.error(error.response.data.UserMessage);
      });

    return r?.data;
  },
};

export default authProvider;
