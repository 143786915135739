/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import clsx from "clsx";
import { GblContext } from "providers/formatter";
import { Loading, useTranslate } from "react-admin";
import { Box, FormControl, Select, InputLabel, MenuItem, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getPreAssetSearchList } from "services/asset";
import MuiTranslateTable from "components/MuiTranslateTable";
import CustomTablePagination from "components/CustomTablePagination";
import DatePickerFormat from "components/DatePickerFormat";
import ModelUriQueryString from "models/uriQueryString";
import { addDays, startOfMonth } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  statusDraft: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
  },
  statusNormal: {
    backgroundColor: "#2196f3",
    color: "white",
  },
  statusVoid: {
    backgroundColor: "#e57373",
    color: "white",
  },
}));

const List = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const { settingAll, ToMySqlDate, DateToString, NumberFormat } = useContext(GblContext);
  const { ClosePeriodAp } = settingAll.SettingClosePeriod;
  const [Data, setData] = useState();
  const [Paging, setPaging] = useState();
  const [loading, setLoading] = useState(true);
  const [uriQueryString, setUriQueryString] = useState({
    Limit: ModelUriQueryString.Limit,
    Page: ModelUriQueryString.Page,
    OrderBy: ModelUriQueryString.OrderBy,
    WhereGroupList: ModelUriQueryString.WhereGroupList,
    Exclude: ModelUriQueryString.Exclude,
    WhereRaw: ModelUriQueryString.WhereRaw,
    WhereLike: ModelUriQueryString.WhereLike,
    WhereLikeFields: ModelUriQueryString.WhereLikeFields,
  });
  const [searchText, setSearchText] = useState();
  const [optionDate, setOptionDate] = useState("Date");
  const [status, setStatus] = useState("D");
  const [filterDate, setFilterDate] = useState({
    from: startOfMonth(addDays(new Date(ClosePeriodAp), 1)),
    to: new Date(),
  });

  const handleFilterList = () => {
    let fromMySqlDate = ToMySqlDate(filterDate.from);
    let toMySqlDate = ToMySqlDate(filterDate.to);

    switch (optionDate) {
      case "Date":
        uriQueryString.WhereGroupList = [
          {
            AndOr: "And",
            ConditionList: [
              {
                AndOr: "And",
                Field: "InvhDate",
                Operator: ">=",
                Value: fromMySqlDate,
              },
              {
                AndOr: "And",
                Field: "InvhDate",
                Operator: "<=",
                Value: toMySqlDate,
              },
            ],
          },
        ];
        break;
      default:
        uriQueryString.WhereGroupList = [
          {
            AndOr: "And",
            ConditionList: [],
          },
        ];
        break;
    }

    uriQueryString.WhereLikeFields = ["VnCode", "VnName", "InvhDate", "InvNo", "Comment"];

    var condition = uriQueryString.WhereGroupList[0].ConditionList.find((item) => item.Field === "Status");
    switch (status) {
      case "P":
        if (!condition) {
          uriQueryString.WhereGroupList[0].ConditionList.push({
            AndOr: "And",
            Field: "Status",
            Operator: "=",
            Value: "P",
          });
        }
        break;
      case "D":
        if (!condition) {
          uriQueryString.WhereGroupList[0].ConditionList.push({
            AndOr: "And",
            Field: "Status",
            Operator: "=",
            Value: "D",
          });
        }
        break;
      case "V":
        if (!condition) {
          uriQueryString.WhereGroupList[0].ConditionList.push({
            AndOr: "And",
            Field: "Status",
            Operator: "=",
            Value: "V",
          });
        }
        break;
      default:
        break;
    }
  };

  const fetchSearchList = async (uriQueryString, mounted = true) => {
    setLoading(true);
    handleFilterList();
    const { Data, Paging } = await getPreAssetSearchList(uriQueryString);
    if (Data) {
      setData(Data);
      setPaging(Paging);
      setUriQueryString(uriQueryString);
    } else {
      setData([]);
    }
    if (mounted) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    fetchSearchList(uriQueryString, mounted);
  }, [uriQueryString, filterDate, optionDate, status]);

  const handleRequestSort = (property, order) => {
    uriQueryString.OrderBy = { [property]: order };
    fetchSearchList(uriQueryString);
  };

  if (loading) return <Loading />;
  if (!Data) return null;

  const handleChangePage = (e, newPage) => {
    uriQueryString.Page = newPage + 1;
    fetchSearchList(uriQueryString);
  };

  const handleChangeRowsPerPage = (e) => {
    let newRowsPerPage = parseInt(e.target.value);
    uriQueryString.Limit = newRowsPerPage;
    fetchSearchList(uriQueryString);
  };

  const handleTableChange = (action, tableState) => {
    if (action === "onSearchClose") {
      setSearchText();
      uriQueryString.WhereLike = "";
      fetchSearchList(uriQueryString);
    }
  };

  const columns = [
    {
      name: "PastSeq",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        display: false,
        // customBodyRender: (value) => {
        //   return (
        //     <a href={`#${basePath}/${value}/show`}>
        //       <VisibilityIcon
        //         fontSize="small"
        //         color="primary"
        //         style={{ cursor: "pointer" }}
        //         onClick={() => redirect("show", basePath, value)}
        //       />
        //     </a>
        //   );
        // },
      },
    },
    {
      name: "InvhDate",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? DateToString(new Date(value)) : null;
        },
      },
    },
    {
      name: "InvNo",
      label: "Invoice No.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "VnCode",
      label: "Vendor",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "VnName",
      label: "Vendor Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Comment",
      label: "Comment",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "Unit",
      label: "Unit",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "TotalAmt",
      label: "Total",
      options: {
        filter: true,
        sort: true,

        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "Status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Chip
              label={value}
              size="small"
              className={clsx(classes.statusDraft, {
                [classes.statusNormal]: value === "Posted",
                [classes.statusVoid]: value === "Void",
              })}
            />
          );
        },
      },
    },
  ];

  const options = {
    filter: false, // show the filter icon in the toolbar (true by default)
    responsive: "standard",
    selectableRows: "none",
    serverSide: true,
    confirmFilters: true,
    searchText: searchText,
    searchProps: {
      onKeyUp: (e) => {
        if (e.keyCode === 13) {
          setSearchText(e.target.value);
          uriQueryString.WhereLike = `%${e.target.value}%`;
          uriQueryString.WhereLikeFields = ["VnCode", "VnName", "InvNo"];
          uriQueryString.Page = 1;
          fetchSearchList(uriQueryString);
        }
      },
    },
    download: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      return `\uFEFF${buildHead(columns)}${buildBody(data)}`;
    },
    onTableChange: (action, tableState) => handleTableChange(action, tableState),
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    sortOrder: {
      name: Object.keys(uriQueryString.OrderBy)[0],
      direction: Object.values(uriQueryString.OrderBy)[0],
    },
    onColumnSortChange: (changedColumn, direction) => {
      handleRequestSort(changedColumn, direction);
    },
    customFooter: () => {
      return (
        <CustomTablePagination
          rowsPerPageOptions={[15, 50, 100]}
          component="div"
          count={Paging?.TotalRecordCount ?? 0}
          rowsPerPage={Paging?.Limit ?? 15}
          page={Paging?.Page ? Paging.Page - 1 : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      );
    },
  };

  const CustomFilterList = () => {
    return (
      <Box display="flex">
        <Box p={1}>
          <FormControl variant="outlined">
            <InputLabel id="viewMode">{translate("ra.field.ViewBy")}</InputLabel>
            <Select
              labelId="viewMode"
              label={translate("ra.field.ViewBy")}
              variant="outlined"
              margin="dense"
              value={optionDate}
              defaultValue={optionDate}
              onChange={(e) => {
                setUriQueryString({ ...uriQueryString, Page: 1 });
                setOptionDate(e.target.value);
              }}
            >
              <MenuItem value={"All"}>{translate("ra.field.All")}</MenuItem>
              <MenuItem value={"Date"}>{translate("ra.field.Date")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {optionDate !== "All" && (
          <Box p={1}>
            <DatePickerFormat
              label={translate("ra.field.From")}
              value={filterDate.from}
              onChange={(e) => {
                setUriQueryString({ ...uriQueryString, Page: 1 });
                setFilterDate((state) => ({
                  ...state,
                  from: e,
                  to: e > filterDate.to ? e : filterDate.to,
                }));
              }}
              style={{ width: 160, margin: "0 10px" }}
            />
            <DatePickerFormat
              label={translate("ra.field.To")}
              value={filterDate.to}
              onChange={(e) => {
                setUriQueryString({ ...uriQueryString, Page: 1 });
                setFilterDate((state) => ({
                  ...state,
                  to: e,
                }));
              }}
              minDate={filterDate.from}
              minDateMessage={"Date must be more than from date"}
              style={{ width: 160, margin: "0 10px" }}
            />
          </Box>
        )}
        <Box p={1}>
          <FormControl variant="outlined">
            <InputLabel id="status">{translate("ra.field.Status")}</InputLabel>
            <Select
              variant="outlined"
              margin="dense"
              labelId="status"
              label={translate("ra.field.Status")}
              value={status}
              onChange={(e) => {
                setUriQueryString({ ...uriQueryString, Page: 1 });
                setStatus(e.target.value);
              }}
              style={{ width: 200 }}
            >
              <MenuItem value={"A"}>{translate("ra.field.All")}</MenuItem>
              <MenuItem value={"P"}>{translate("ra.actionMenu.Posted")}</MenuItem>
              <MenuItem value={"D"}>{translate("ra.actionMenu.Draft")}</MenuItem>
              <MenuItem value={"V"}>{translate("ra.actionMenu.Void")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      <MuiTranslateTable
        title={"Pre-Register Asset"}
        data={Data}
        columns={columns}
        options={options}
        components={{
          TableFilterList: CustomFilterList,
        }}
      />
    </div>
  );
};

export default List;
