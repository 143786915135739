import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import TablePL from "./TablePL";
import TableBSMonthly from "./TableBS_Monthly";
import TableBSAnnual from "./TableBS_Annual";
import { getFinancialReport } from "services/financialdata";
import { Loading } from "react-admin";
import { getDepartmentList } from "services/setting";
import ListBox from "components/ListBox";
import PopperListBox from "components/PopperListBox";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { matchSorter } from "match-sorter";
import { useTranslate } from "react-admin";
//import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
//import CheckBoxIcon from "@material-ui/icons/CheckBox";

const List = (props) => {
  const { lookupData, basePath } = props;
  const translate = useTranslate();
  const [isLoading, setLoading] = useState(true);
  const [selectedReport, setSelectedReport] = useState("PL");
  const [reportData, setReportData] = useState([]);
  const [titleHead, setTitleHead] = useState("Profits and loss");
  const [lookupDept, setLookupDept] = useState();
  const [period, setPeriod] = useState(new Date());
  const [revision, setRevision] = useState(0);
  const [reportType, setReportType] = useState(1);
  const [isAllDepartment, setIsAllDepartment] = useState(false);
  const [department, setDepartment] = useState();

  const fetchData = async (type) => {
    console.log(type);

    setLoading(true);
    const getIdReport = lookupData.find((i) => i.Code === type)?.Code;
    const { Data } = await getDepartmentList();
    setLookupDept(Data);
    try {
      switch (type) {
        case "PL":
          let paramPL = {
            ReportId: getIdReport,
            Year: period.getFullYear(),
            Period: period.getMonth() + 1,
            BudgetRevision: revision,
            Departments: [],
          };

          if (department) {
            paramPL.Departments = [department.DeptCode];
          } else {
            paramPL.Departments = [Data[0].DeptCode];
          }

          if (isAllDepartment) {
            paramPL.Departments = [];
          }

          const plData = await getFinancialReport(paramPL);
          console.log(plData);
          if (plData?.Code < 0) {
            setReportData([]);
          } else {
            setReportData(plData);
          }
          setLoading(false);
          break;
        case "BS":
          let paramBS = {
            ReportId: getIdReport,
            ReportType: reportType,
            Year: period.getFullYear(),
            Period: period.getMonth() + 1,
            Departments: [],
          };

          if (department) {
            paramBS.Departments = [department.DeptCode];
          } else {
            paramBS.Departments = [Data[0].DeptCode];
          }

          if (isAllDepartment) {
            paramBS.Departments = [];
          }

          const bsData = await getFinancialReport(paramBS);
          console.log(bsData);

          if (bsData?.Code < 0) {
            setReportData([]);
          } else {
            setReportData(bsData);
          }
          setLoading(false);
          break;
        default:
          setLoading(false);
          break;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(selectedReport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport, isAllDepartment, department, period, revision, reportType]);

  const handleButtonClick = (buttonName) => {
    console.log("in");

    if (buttonName === "PL") {
      setTitleHead("Profits and Loss");
    }
    if (buttonName === "BS") {
      setTitleHead("Balance Sheet");
    }
    setSelectedReport(buttonName);
  };

  if (isLoading) return <Loading />;

  const filterOptions = (options, { inputValue }, optKey, optDesc) => {
    return matchSorter(options, inputValue, {
      keys: [`${optKey}`, `${optDesc}`],
    });
  };

  return (
    <>
      <Box height={20} />
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            {lookupData &&
              lookupData.map((item, idx) => (
                <Button
                  key={idx}
                  onClick={() => handleButtonClick(`${item.Code}`)}
                  variant={selectedReport === item.Code ? "contained" : "outlined"}
                >
                  {item.Name}
                </Button>
              ))}
            {/* <Button
              onClick={() => handleButtonClick("Setting")}
              variant={selectedReport === "Setting" ? "contained" : "outlined"}
            >
              {"Setting"}
            </Button> */}
          </ButtonGroup>
        </Grid>
        <Grid item>
          {selectedReport !== "Setting" && (
            <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAllDepartment}
                      onChange={(e) => {
                        setIsAllDepartment(e.target.checked);
                      }}
                      name="All Department"
                    />
                  }
                  label="All Department"
                />
                {/* <Autocomplete
                    multiple
                    limitTags={6}
                    options={lookupDept}
                    disableCloseOnSelect
                    disableListWrap
                    value={department}
                    onChange={(e, newValue) => setDepartment(newValue)}
                    // classes={{
                    //   inputRoot: classes.inputRoot,
                    //   option: classes.option,
                    // }}
                    style={{
                      width: 210,
                      display: "inline-flex",
                      marginRight: 20,
                    }}
                    getOptionLabel={(option) => option.DeptCode}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.DeptCode} : {option.Description}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Department"
                        margin="dense"
                        placeholder="Department"
                      />
                    )}
                  /> */}
                <Autocomplete
                  options={lookupDept}
                  disableClearable
                  disableCloseOnSelect
                  disableListWrap
                  defaultValue={lookupDept[0]}
                  value={department}
                  onChange={(e, newItem) => {
                    setDepartment(newItem);
                  }}
                  ListboxComponent={ListBox}
                  PopperComponent={PopperListBox}
                  getOptionLabel={(option) => {
                    return typeof option === "object" ? option.DeptCode : option;
                  }}
                  getOptionSelected={(option, value) => option.DeptCode === value.DeptCode}
                  renderOption={(option, { inputValue }) => {
                    const mergestring = `${option.DeptCode} : ${option.Description}`;
                    const matches = match(mergestring, inputValue);
                    const parts = parse(mergestring, matches);
                    return (
                      <div>
                        {parts.map((part, index) => (
                          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                            {part.text}
                          </span>
                        ))}
                      </div>
                    );
                  }}
                  style={{
                    width: 210,
                    display: "inline-flex",
                    marginRight: 20,
                  }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Department"
                      margin="dense"
                      placeholder="Department"
                    />
                  )}
                  filterOptions={(options, { inputValue }) =>
                    filterOptions(options, { inputValue }, "DeptCode", "Description")
                  }
                  disabled={isAllDepartment}
                />
              </>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableFuture
                  openTo="year"
                  format={"MM/yyyy"}
                  views={["year", "month"]}
                  inputVariant="outlined"
                  margin="dense"
                  label="Select Period"
                  value={period}
                  onChange={setPeriod}
                  autoOk={true}
                  okLabel=""
                  cancelLabel=""
                  inputProps={{
                    style: { padding: 10 },
                  }}

                  //animateYearScrolling
                />
              </MuiPickersUtilsProvider>

              {selectedReport === "PL" && (
                <FormControl variant="outlined" margin="dense" fullWidth style={{ width: 210, marginLeft: 20 }}>
                  <InputLabel id="size-select">Select Budget (Revision)</InputLabel>
                  <Select
                    labelId="size-select"
                    value={revision}
                    onChange={(e) => {
                      setRevision(e.target.value);
                    }}
                    label="Select Budget (Revision)"
                    style={{
                      width: 210,
                      padding: 2,
                    }}
                  >
                    {[
                      { id: 0, name: "Annual" },
                      { id: 1, name: "Reivision 1" },
                      { id: 2, name: "Reivision 2" },
                      { id: 3, name: "Reivision 3" },
                      { id: 4, name: "Reivision 4" },
                    ].map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {selectedReport === "BS" && (
                <FormControl variant="outlined" margin="dense" fullWidth style={{ width: 210, marginLeft: 20 }}>
                  <InputLabel id="viewMode">{translate("ra.field.ViewBy")}</InputLabel>
                  <Select
                    labelId="viewMode"
                    label={translate("ra.field.ViewBy")}
                    value={reportType}
                    onChange={(e) => {
                      setReportType(e.target.value);
                    }}
                    style={{
                      width: 210,
                      padding: 2,
                    }}
                  >
                    {[
                      { id: 1, name: "Monthly" },
                      { id: 2, name: "Annual" },
                    ].map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          )}
        </Grid>
      </Grid>

      <Box height={20} />
      {selectedReport === "PL" && (
        <TablePL basePath={basePath} translate={translate} dataTable={reportData} title={titleHead} />
      )}
      {selectedReport === "BS" && reportType === 1 && (
        <TableBSMonthly basePath={basePath} dataTable={reportData} title={titleHead} />
      )}
      {selectedReport === "BS" && reportType === 2 && (
        <TableBSAnnual basePath={basePath} dataTable={reportData} title={titleHead} />
      )}
    </>
  );
};

export default List;
