import React, { useContext, useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useSetLocale, Loading, Layout, Sidebar } from "react-admin";
import ErrorDashboard from "layout/ErrorDashboard";
import authProviders from "providers/auth";
import AppBar from "./AppBar";
import Menu from "./Menu";
import { darkTheme, lightTheme } from "utils/themes";
import { makeStyles } from "@material-ui/core/styles";
import { GblContext } from "providers/formatter";
import { getWorkflowStepByUser } from "services/workflow";

const useStyles = makeStyles((theme) => ({
	bgSideBar: {
		backgroundColor: theme.palette.type === "dark" ? "inherit" : "#f1f5f9",
	},
}));

const CustomSidebar = (props) => {
	const classes = useStyles();
	const url = new URL(window.location.href);
	let callFromDesktopApp = undefined;
	if (url) {
		callFromDesktopApp = url.searchParams.get("ShowOnlyDashboard") === "true";
	}

	return !callFromDesktopApp ? (
		<Sidebar {...props} size={200} className={classes.bgSideBar} />
	) : (
		<div style={{ margin: "12px" }} />
	);
};

const CustomAppBar = (props) => {
	const url = new URL(window.location.href);
	let callFromDesktopApp = undefined;
	if (url) {
		callFromDesktopApp = url.searchParams.get("ShowOnlyDashboard") === "true";
	}
	const tkFromDesktopApp = url.searchParams.get("tk");
	if (tkFromDesktopApp) {
		localStorage.setItem("AccessToken", tkFromDesktopApp);
	}

	return !callFromDesktopApp ? (
		<AppBar {...props} />
	) : (
		<div style={{ marginTop: "-40px" }} />
	);
};

const CustomLayout = (props) => {
	const { UpdateSettingAll } = useContext(GblContext);
	const theme = useSelector((state) =>
		state.theme === "dark" ? darkTheme : lightTheme,
	);
	const setLocale = useSetLocale();
	const [loading, setLoading] = useState(true);
	const [error] = useState(false);

	const GetSetting = useCallback(
		async (mounted) => {
			const localSetting = JSON.parse(localStorage.getItem("SettingAll"));
			const language = localStorage.getItem("Language");
			if (language) {
				setLocale(language);
			} else {
				setLocale("en-US");
			}
			if (localSetting) {
				UpdateSettingAll(localSetting);
				if (mounted) {
					setLoading(false);
				}
			} else {
				console.log("call api settingAll");
				const response = await authProviders.getSettingAll();
				if (response) {
					localStorage.setItem("SettingAll", JSON.stringify(response));
					UpdateSettingAll(response);
				}

				const wfVendor = await getWorkflowStepByUser("AP_VENDOR");
				if (wfVendor?.data) {
					localStorage.setItem(
						"defaultWfVendor",
						wfVendor?.data[0]?.StepNo ?? "E",
					);
				}
				const wfApInvoice = await getWorkflowStepByUser("AP_INVOICE");
				if (wfApInvoice?.data) {
					localStorage.setItem(
						"defaultWfApInvoice",
						wfApInvoice?.data[0]?.StepNo ?? "E",
					);
				}
				const wfArInvoice = await getWorkflowStepByUser("AR_INVOICE");
				if (wfArInvoice?.data) {
					localStorage.setItem(
						"defaultWfArInvoice",
						wfArInvoice?.data[0]?.StepNo ?? "E",
					);
				}
				const wfApPayment = await getWorkflowStepByUser("AP_PAYMENT");
				if (wfApPayment?.data) {
					localStorage.setItem(
						"defaultWfApPayment",
						wfApPayment?.data[0]?.StepNo ?? "E",
					);
				}
				const wfArReceipt = await getWorkflowStepByUser("AR_Receipt");
				if (wfArReceipt?.data) {
					localStorage.setItem(
						"defaultWfArReceipt",
						wfArReceipt?.data[0]?.StepNo ?? "E",
					);
				}
				if (mounted) {
					setLoading(false);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useEffect(() => {
		let mounted = true;
		GetSetting(mounted);
		return function cleanup() {
			mounted = false;
		};
	}, [GetSetting]);

	if (loading) return <Loading style={{ marginTop: 50 }} />;
	if (error) return <ErrorDashboard />;
	return (
		<>
			<Layout
				{...props}
				appBar={CustomAppBar}
				sidebar={CustomSidebar}
				menu={Menu}
				theme={theme}
			/>
		</>
	);
};

export default CustomLayout;
