import { useState, useEffect, useCallback } from "react";
import { useAuthState } from "react-admin";
import List from "./List";
import SettingFS from "./SettingFS";
import { getFinancialReportList } from "services/financialdata";

const Show = (props) => {
  return <SettingFS {...props} />;
};

const LoadLookupList = (props) => {
  const { authenticated } = useAuthState();
  const [lookupData, setLookupData] = useState();

  const fetchLookup = useCallback(async () => {
    const lookupData = await getFinancialReportList();
    console.log(lookupData);
    setLookupData(lookupData);
  }, []);

  useEffect(() => {
    if (authenticated) {
      fetchLookup();
    }
  }, [authenticated, fetchLookup]);
  if (lookupData) {
    return <List {...props} lookupData={lookupData} />;
  } else {
    return null;
  }
};

const LoadLookupShow = (props) => {
  const { authenticated } = useAuthState();
  const [lookupData, setLookupData] = useState();

  const fetchLookup = useCallback(async () => {
    const lookupData = await getFinancialReportList();

    setLookupData(lookupData);
  }, []);

  useEffect(() => {
    if (authenticated) {
      fetchLookup();
    }
  }, [authenticated, fetchLookup]);

  if (lookupData) {
    return <Show {...props} lookupData={lookupData} />;
  } else {
    return null;
  }
};

export default {
  list: LoadLookupList,
  show: LoadLookupShow,
};
