/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { GblContext } from "providers/formatter";
import { useRedirect, Loading, Error } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { getAssetRegisterSearchList } from "services/asset";
import MuiTranslateTable from "components/MuiTranslateTable";
import ActionMenu from "components/ActionMenu";
import CardImage from "components/CardImage";
import PreviewImage from "assets/previewImage.png";
import CustomTablePagination from "components/CustomTablePagination";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { permissionName } from "utils/constants";
import ModelUriQueryString from "models/uriQueryString";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  statusDraft: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
  },
  statusNormal: {
    backgroundColor: "#2196f3",
    color: "white",
  },
  statusVoid: {
    backgroundColor: "#e57373",
    color: "white",
  },
  divComment: {
    position: "relative",
    height: "20px",
    width: "200px",
  },
  parentStyle: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "100%",
  },
  cellStyleEllipsis: {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const List = (props) => {
  const classes = useStyles();
  const { basePath, permissions } = props;

  //initial Value with window.sessionStorage
  const ws = window.sessionStorage;
  let t = localStorage.getItem("Tenant");
  const filterValue =
    ws.getItem("basePath") === basePath && ws.getItem(`${t}_searchDetails`)
      ? JSON.parse(ws.getItem(`${t}_searchDetails`)).filterValue
      : undefined;
  const searchQuery =
    ws.getItem("basePath") === basePath && ws.getItem(`${t}_searchDetails`)
      ? JSON.parse(ws.getItem(`${t}_searchDetails`)).searchQuery
      : undefined;
  const searchResults =
    ws.getItem("basePath") === basePath && ws.getItem(`${t}_searchDetails`)
      ? JSON.parse(ws.getItem(`${t}_searchDetails`)).searchResults
      : undefined;

  const { DateToString, NumberFormat } = useContext(GblContext);
  const [loading, setLoading] = useState(true);
  const [error] = useState();
  const [Data, setData] = useState(searchResults ? searchResults : undefined);
  const [Paging, setPaging] = useState();
  const [uriQueryString, setUriQueryString] = useState({
    Limit: searchQuery ? searchQuery.Limit : ModelUriQueryString.Limit,
    Page: searchQuery ? searchQuery.Page : ModelUriQueryString.Page,
    OrderBy: searchQuery ? searchQuery.OrderBy : ModelUriQueryString.OrderBy,
    WhereGroupList: searchQuery ? searchQuery.WhereGroupList : ModelUriQueryString.WhereGroupList,
    Exclude: searchQuery ? searchQuery.Exclude : ModelUriQueryString.Exclude,
    WhereRaw: searchQuery ? searchQuery.WhereRaw : ModelUriQueryString.WhereRaw,
    WhereLike: searchQuery ? searchQuery.WhereLike : ModelUriQueryString.WhereLike,
    WhereLikeFields: searchQuery ? searchQuery.WhereLikeFields : ModelUriQueryString.WhereLikeFields,
  });
  const [searchText, setSearchText] = useState(filterValue ? filterValue.searchValue : undefined);

  const redirect = useRedirect();

  const fetchSearchList = async (uriQueryString) => {
    setLoading(true);
    uriQueryString.Exclude = [
      "Barcode",
      "SerialNo",
      "CurRate",
      "Qty",
      "Salvage",
      "InitAccu",
      "TotalCost",
      "Life",
      "LifeType",
      "RemainDay",
      "RemainTotalCost",
      "DimList",
      "RemainInfo",
    ];

    const { Data, Paging } = await getAssetRegisterSearchList(uriQueryString);
    if (Data) {
      setData(Data);
      setPaging(Paging);
      setUriQueryString(uriQueryString);
    } else {
      setData([]);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchSearchList(uriQueryString);
  }, [uriQueryString]);

  const handleRequestSort = (property, order) => {
    uriQueryString.OrderBy = { [property]: order };
    fetchSearchList(uriQueryString);
  };

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!Data) return null;

  const handleChangePage = (e, newPage) => {
    uriQueryString.Page = newPage + 1;
    fetchSearchList(uriQueryString);
  };

  const handleChangeRowsPerPage = (e) => {
    let newRowsPerPage = parseInt(e.target.value);
    uriQueryString.Limit = newRowsPerPage;
    fetchSearchList(uriQueryString);
  };

  const handleTableChange = (action, tableState) => {
    if (action === "onSearchClose") {
      //clear window.sessionstorage (when user click close icon)
      ws.clear();
      setSearchText();
      uriQueryString.WhereLike = "";
      fetchSearchList(uriQueryString);
    }
  };

  const columns = [
    {
      name: "RegId",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        setCellProps: () => {
          return {
            style: {
              width: "2vw",
            },
          };
        },
        customBodyRender: (value) => {
          return (
            <a href={`#${basePath}/${value}/show`}>
              <VisibilityIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  //set window.storage value
                  let filterValueObj = {
                    searchValue: searchText,
                  };
                  ws.setItem("basePath", basePath);
                  ws.setItem(
                    `${t}_searchDetails`,
                    JSON.stringify({ filterValue: filterValueObj, searchQuery: uriQueryString })
                  );
                  redirect("show", basePath, value);
                }}
              />
            </a>
          );
        },
      },
    },
    {
      name: "AstPhoto",
      label: " ",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => {
          return {
            style: {
              width: "8vw",
              textAlign: "center",
            },
          };
        },
        customBodyRender: (value) => {
          return <CardImage base64Src={value ? value : undefined} imgSrc={PreviewImage} customSize={{ height: 60 }} />;
        },
      },
    },
    {
      name: "InputDate",
      label: "Input Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? DateToString(new Date(value)) : null;
        },
      },
    },
    {
      name: "AcquireDate",
      label: "Acquire Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? DateToString(new Date(value)) : null;
        },
      },
    },
    {
      name: "Id",
      label: "Code",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "No",
      label: "Asset No.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "CategoryCode",
      label: "Category",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return value
            ? `${value} ${Data[tableMeta.rowIndex].AstCateDesc ? `: ${Data[tableMeta.rowIndex].AstCateDesc}` : ""}`
            : "";
        },
      },
    },
    {
      name: "DepartmentCode",
      label: "Department",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return value
            ? `${value} ${Data[tableMeta.rowIndex].AstDeptDesc ? `: ${Data[tableMeta.rowIndex].AstDeptDesc}` : ""}`
            : "";
        },
      },
    },
    {
      name: "Remark",
      label: "Remark",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (val) => {
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{val}</p>} arrow placement="top">
              <div className={classes.divComment}>
                <div className={classes.parentStyle}>
                  <div className={classes.cellStyleEllipsis}>{val}</div>
                </div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "Spec",
      label: "Specification",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (val) => {
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{val}</p>} arrow placement="top">
              <div className={classes.divComment}>
                <div className={classes.parentStyle}>
                  <div className={classes.cellStyleEllipsis}>{val}</div>
                </div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "Amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
  ];

  const options = {
    filter: false, // show the filter icon in the toolbar (true by default)
    responsive: "standard",
    selectableRows: "none",
    serverSide: true,
    confirmFilters: true,
    searchText: searchText,
    searchProps: {
      onKeyUp: (e) => {
        if (e.keyCode === 13) {
          setSearchText(e.target.value);
          uriQueryString.WhereLike = `%${e.target.value}%`;
          uriQueryString.WhereLikeFields = [
            "AstRegId",
            "DATE_FORMAT(AstInputDate, '%d/%m/%Y')",
            "AstId",
            "AstNo",
            "AstName",
            "AstRemark",
          ];
          uriQueryString.Page = 1;
          fetchSearchList(uriQueryString);
        }
      },
    },
    download: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      return `\uFEFF${buildHead(columns)}${buildBody(data)}`;
    },
    onTableChange: (action, tableState) => handleTableChange(action, tableState),
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    sortOrder: {
      name: Object.keys(uriQueryString.OrderBy)[0],
      direction: Object.values(uriQueryString.OrderBy)[0],
    },
    onColumnSortChange: (changedColumn, direction) => {
      handleRequestSort(changedColumn, direction);
    },
    customFooter: () => {
      return (
        <CustomTablePagination
          rowsPerPageOptions={[15, 50, 100]}
          component="div"
          count={Paging?.TotalRecordCount ?? 0}
          rowsPerPage={Paging?.Limit ?? 15}
          page={Paging?.Page ? Paging.Page - 1 : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      );
    },
  };

  const menuControlProp = [{ name: "Add", fnc: () => redirect("create", basePath) }];

  return (
    <div className={classes.root}>
      <ActionMenu
        menuControl={menuControlProp}
        permission={permissions.find((i) => i.Name === permissionName["Ast.Register"])}
      />
      <MuiTranslateTable title={"Asset Register"} data={Data} columns={columns} options={options} />
    </div>
  );
};

export default List;
