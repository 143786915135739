import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Box, Grid, FormControl, Select, InputLabel, MenuItem, TextField, Switch } from "@material-ui/core";
import NumberFormatInput from "components/NumberFormatInput";
import DatePickerFormat from "components/DatePickerFormat";
import { format, parse } from "date-fns";

const SwitchTypeBox = ({ item, update }) => {
  function useForceUpdate() {
    const [value, setState] = useState(true);
    return () => setState(!value);
  }

  const forceUpdate = useForceUpdate();

  var arrListOfValue = [];
  if (item.ListOfValues !== null) {
    let items = item.ListOfValues.replace(/\n/g, "").split("],[").map((item) => item.replace(/[[\]]/g, ""));
    let result = items.map((item) => {
      let [code, desc] = item.split(":");
      return { code: code?.replace(/ /g, ""), desc: desc };
    });
    arrListOfValue = result;
  }

  switch (item.Type) {
    case "Text":
      return (
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          label={item.Caption}
          defaultValue={item.Value ?? ""}
          onChange={(e) => update(item, e.target.value)}
          //style={{ width: width ? width : 206, marginTop: -0.5 }}
        />
      );
    case "Number":
      return (
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          label={item.Caption}
          defaultValue={item.Value ?? ""}
          onChange={(e) => update(item, e.target.value)}
          InputProps={{
            inputComponent: NumberFormatInput,
          }}
          inputProps={{
            style: { textAlign: "right" },
            maxLength: 18,
            decimal: 2,
          }}
          //style={{ width: width ? width : 206, marginTop: -0.5 }}
        />
      );
    case "Date":
      return (
        <DatePickerFormat
          label={item.Caption}
          value={typeof item.Value === "string" ? parse(item.Value, "dd/MM/yyyy", new Date()) : item.Value}
          showTodayButton
          onChange={(e) => {
            update(item, e);
            forceUpdate();
          }}
          style={{ width: "100%" }}
        />
      );
    case "Boolean":
      return (
        <FormControlLabel
          style={{ marginLeft: 0 }}
          value={typeof item.Value === "string" ? (item.Value.toLowerCase() === "true" ? true : false) : item.Value}
          control={
            <Switch
              checked={
                typeof item.Value === "string" ? (item.Value.toLowerCase() === "true" ? true : false) : item.Value
              }
              onChange={(e, newValue) => {
                update(item, newValue);
              }}
            />
          }
          label={item.Caption}
          labelPlacement="top"
          color="primary"
        />
      );
    case "List": {
      return (
        <FormControl variant="outlined" fullWidth>
          <InputLabel
            id="caption"
            style={{
              marginTop: item.Value === "" || item.Value === null ? -6 : 0,
            }}
          >
            {item.Caption}
          </InputLabel>
          <Select
            variant="outlined"
            margin="dense"
            labelId="caption"
            label={item.Caption}
            value={item.Value ?? ""}
            onChange={(e) => update(item, e.target.value)}
            //style={{ width: width ? width : 206 }}
          >
            <MenuItem value="">
              <em>--None--</em>
            </MenuItem>
            {arrListOfValue.map((item) => (
              <MenuItem key={item?.code.toString()} value={item?.code}>
                {item?.code} : {item?.desc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    default:
      return <div />;
  }
};

const SwitchViewTypeBox = ({ item }) => {
  switch (item.Type) {
    case "Date":
      return (
        <TextField
          label={item.Caption}
          variant="outlined"
          margin="dense"
          disabled
          value={typeof item.Value === "string" ? item.Value : format(new Date(item.Value), "dd/MM/yyyy")}
          fullWidth
        />
      );
    case "Boolean":
      return (
        <FormControlLabel
          style={{ marginLeft: 0 }}
          value={typeof item.Value === "string" ? (item.Value.toLowerCase() === "true" ? true : false) : item.Value}
          control={
            <Switch
              checked={
                typeof item.Value === "string" ? (item.Value.toLowerCase() === "true" ? true : false) : item.Value
              }
              disabled
            />
          }
          label={item.Caption}
          labelPlacement="top"
          color="primary"
        />
      );
    case "List":
      var arrListOfValue = [];
      var v = "";
      if (item.ListOfValues !== null) {
        let items = item.ListOfValues.replace(/\n/g, "").split("],[").map((item) => item.replace(/[[\]]/g, ""));
        let result = items.map((item) => {
          let [code, desc] = item.split(":");
          return { code: code?.replace(/ /g, ""), desc: desc};
        });
        arrListOfValue = result;
        v = arrListOfValue.find((i) => i.code === item.Value);
        if (v) {
          return (
            <TextField
              label={item.Caption}
              variant="outlined"
              margin="dense"
              disabled
              value={`${v.code ?? ""} ${v.desc ? ": " + v.desc : ""}`}
              fullWidth
            />
          );
        }
      }

      return <TextField label={item.Caption} variant="outlined" margin="dense" disabled value={item.Value} fullWidth />;
    default:
      return <TextField label={item.Caption} variant="outlined" margin="dense" disabled value={item.Value} fullWidth />;
  }
};

const Modify = ({ data, update, width, typeOfFlex }) => {
  return (
    <React.Fragment>
      <Grid
        container
        alignItems="center"
        spacing={1}
        style={{
          marginBottom: 10,
          display: "flex",
          flexDirection: typeOfFlex ? typeOfFlex : "column",
          justifyContent: " space-between",
        }}
      >
        {data
          ? data.map((item, idx) => (
              <Grid item xs={6}>
                <SwitchTypeBox item={item} update={update} width={width} />
              </Grid>
            ))
          : ""}
      </Grid>
    </React.Fragment>
  );
};

const View = ({ data, width, typeOfFlex }) => {
  if (typeOfFlex === "column") {
    return (
      <React.Fragment>
        <Grid
          container
          alignItems="center"
          spacing={1}
          style={{
            marginBottom: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: " space-between",
          }}
        >
          {data
            ? data.map((item, idx) => (
                <Grid item xs={6}>
                  <SwitchViewTypeBox item={item} width={width} />
                </Grid>
              ))
            : ""}
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {data
          ? data.map((item, idx) => (
              <Box mx={0.5} p={0.5}>
                <SwitchViewTypeBox item={item} width={width} />
              </Box>
            ))
          : ""}
      </React.Fragment>
    );
  }
};

const DimensionContent = ({ data, update, modify, width, typeOfFlex }) => {
  return (
    <React.Fragment>
      {modify ? (
        <Modify data={data} update={update} width={width} typeOfFlex={typeOfFlex} />
      ) : (
        <View data={data} width={width} typeOfFlex={typeOfFlex} />
      )}
    </React.Fragment>
  );
};

export default DimensionContent;
