/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { GblContext } from "providers/formatter";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { Loading, useRedirect, useTranslate } from "react-admin";
import { Paper, Grid, Typography, Card, CardContent, Box } from "@material-ui/core";
import { useForm } from "react-hook-form";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import ButtonFooter from "components/ButtonFooter";
import ButtonUpload from "components/ButtonUpload";
import CardImage from "components/CardImage";
import PreviewImage from "assets/previewImage.png";
import DialogTemplate from "./DialogTemplate";
import { createAssetRegDetail, getPreAssetDetail } from "services/asset";
import { getActiveDimListByModuleName } from "services/dimension";
import Model from "models/assetRegister";
import SnackbarUtils from "utils/SnackbarUtils";
import fileReader from "utils/fileReader";
import Compressor from "compressorjs";

const Create = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const { settingAll, NumberFormat, ToNumber } = useContext(GblContext);
  const { SettingAsset, SettingSystem } = settingAll;
  const redirect = useRedirect();
  const { basePath, templateId, formFields, formFieldsRegister, formFieldsAccount } = props;
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initData, setInitData] = useStateWithCallbackLazy(Model);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);

  const fetchPreAssetByRegId = useCallback(async (templateId) => {
    setLoading(true);
    const response = await getPreAssetDetail(templateId);
    if (response) {
      const newModel = Object.assign({}, Model);
      newModel.CurCode = response.CurCode ?? SettingSystem.DefaultCurrencyCode;
      newModel.CurRate = response.CurRate ?? SettingSystem.DefaultCurrencyRate;
      if (SettingAsset.IdFormat !== "Manual") {
        if (SettingAsset.IdFormat === "CategoryAndDepartment") {
          newModel.Id = "Category+Department";
        } else {
          newModel.Id = SettingAsset.IdFormat;
        }
      } else {
        newModel.Id = "";
      }

      newModel.Name = response.Comment;
      newModel.InputDate = response.InvhDate;
      newModel.AcquireDate = response.InvhDate;
      newModel.TransferDate = response.InvhDate;
      newModel.VnCode = response.VnCode;
      newModel.VnName = response.VnName;
      newModel.InvoiceNo = response.InvNo;
      newModel.Amount = ToNumber(response.Amount);
      newModel.Qty = response.InvdQty ?? 1;
      newModel.UnitCode = response.Unit;
      newModel.PastSeq = templateId;
      newModel.CostDeptCode = response?.DeptCode;
      newModel.CostDeptDesc = response?.DeptDesc;
      newModel.CostAccCode = response?.InvdBTaxDr;
      newModel.CostAccDesc = response?.InvdBTaxDrDesc;

      let CurRate = ToNumber(newModel["CurRate"]);
      let Amount = ToNumber(newModel["Amount"]);
      let Qty = ToNumber(newModel["Qty"]);
      let InitAccu = ToNumber(newModel["InitAccu"]);
      let Salvage = ToNumber(newModel["Salvage"]);
      let TotalSalvage = NumberFormat(Salvage * Qty);
      let BaseAmount = NumberFormat(Amount * CurRate);
      let TotalValue = NumberFormat(Amount * CurRate * Qty);
      let NetBookValue = NumberFormat(Amount * CurRate * Qty);

      newModel.BaseAmount = BaseAmount;
      newModel.TotalCost = TotalValue;
      newModel.TotalSalvage = TotalSalvage;
      newModel.RemainNet = ToNumber(NetBookValue) - InitAccu;
      setInitData(newModel);
      reset(newModel);
      localStorage.removeItem("templateId");
    }
    setLoading(false);
  }, []);

  const CloseTemplateDialog = (templateId) => {
    setOpenTemplateDialog(false);
    setLoading(true);
    if (templateId) {
      localStorage.setItem("templateId", templateId);
      fetchPreAssetByRegId(templateId);
    } else {
      setLoading(false);
    }
  };

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", disabled: true },
    { name: "Edit", disabled: true },
    { name: "Delete", disabled: true },
    { name: "Copy", disabled: true },
    { name: "Disposal", disabled: true },
    { name: "Pre-Asset", fnc: () => setOpenTemplateDialog(true) },
    { name: "Print", disabled: true },
    { name: "Print BarCode", disabled: true },
  ];

  const methods = useForm({ defaultValues: initData });

  const { handleSubmit, getValues, setValue, reset } = methods;

  const updateModel = () => {
    initData.CurCode = SettingSystem.DefaultCurrencyCode;
    initData.CurRate = SettingSystem.DefaultCurrencyRate;
    if (SettingAsset.IdFormat !== "Manual") {
      if (SettingAsset.IdFormat === "CategoryAndDepartment") {
        Model.Id = "Category+Department";
      } else {
        Model.Id = SettingAsset.IdFormat;
      }
    } else {
      Model.Id = "";
    }
    const cloneModel = _.cloneDeep(Model);
    setInitData(cloneModel);
    reset(cloneModel);
  };

  useEffect(() => {
    let mounted = true;
    if (templateId !== 0) {
      fetchPreAssetByRegId(templateId);
    } else {
      updateModel();
    }
    if (mounted) {
      setLoading(false);
    }
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async () => {
    const values = getValues();
    setBtnLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));

    var lifeType;
    //Switch Value AssetLifeType
    switch (values["LifeType"]) {
      case "Month":
        lifeType = 1;
        break;
      case "Day":
        lifeType = 2;
        break;
      default:
        lifeType = 0;
        break;
    }

    if (SettingAsset.IdFormat !== "Manual") {
      values.Id = "";
    }
    //Adjust parameter before save
    setInitData(
      (state) => ({
        ...state,
        ...values,
        LifeType: lifeType,
        RemainQty: values.Qty,
        RemainTotalCost: values.RemainNet,
      }),
      (nextState) => Save(nextState)
    );
  };

  const fetchDimListByModule = useCallback(async () => {
    const { Data } = await getActiveDimListByModuleName(10, 1, "AST-R");
    setInitData((state) => ({
      ...state,
      DimList: {
        Dim: Data,
      },
    }));
  }, []);

  useEffect(() => {
    fetchDimListByModule();
  }, [fetchDimListByModule]);

  const UpdateForm = (e) => {
    const values = getValues();
    if (e.target.name === "InputDate") {
      let inputDate = new Date(e.target.value);
      let acquireDate = new Date(values.AcquireDate);
      if (inputDate < acquireDate) {
        setValue("AcquireDate", inputDate);
      }
      setValue("TransferDate", inputDate);
    }
  };

  const CalculateForm = (e) => {
    const values = getValues();
    let CurRate = ToNumber(values["CurRate"]);
    let Amount = ToNumber(values["Amount"]);
    let Qty = ToNumber(values["Qty"]);
    let InitAccu = ToNumber(values["InitAccu"]);
    let Salvage = ToNumber(values["Salvage"]);
    let TotalSalvage = NumberFormat(Salvage * Qty);
    let BaseAmount = NumberFormat(Amount * CurRate);
    let TotalValue = NumberFormat(Amount * CurRate * Qty);
    let NetBookValue = NumberFormat(Amount * CurRate * Qty);

    setValue("BaseAmount", BaseAmount);
    setValue("TotalCost", TotalValue);
    setValue("TotalSalvage", TotalSalvage);
    setValue("RemainNet", ToNumber(NetBookValue) - InitAccu);
  };

  const Save = async (values) => {
    const { Code, InternalMessage, UserMessage } = await createAssetRegDetail(values);
    if (Code === 0) {
      SnackbarUtils.success(UserMessage, function () {
        redirect(`${InternalMessage}/show`);
      });
      setBtnLoading(false);
    } else {
      setBtnLoading(false);
      if (InternalMessage) {
        SnackbarUtils.error(InternalMessage);
      } else {
        SnackbarUtils.warning(UserMessage);
      }
    }
  };

  const CancelFnc = () => {
    localStorage.removeItem("templateId");
    redirect("list", basePath);
  };

  const UploadImg = (e) => {
    if (e.target.files[0]) {
      // let msg = fileReader.CheckLimitImgAssetSize(e.target.files);
      // if (msg) {
      //   document.getElementById("ast_img").value = "";
      //   SnackbarUtils.error(translate(msg, { size: "1" }));
      //   return;
      // }
      const file = e.target.files[0];

      new Compressor(file, {
        quality: 0.6,
        width: 1200,
        height: 1200,
        convertSize: 0,
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
          const msg = fileReader.CheckLimitImgAssetSize(result);
          if (msg) {
            document.getElementById("ast_img").value = "";
            SnackbarUtils.error(translate(msg, { size: "1" }));
            return;
          }
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
            setInitData((state) => ({
              ...state,
              AstPhoto: base64String,
            }));
          };
          reader.readAsDataURL(result);
        },
        error(err) {
          console.log(err.message);
        },
      });
    }
  };

  if (loading) return <Loading />;
  if (!initData) return null;

  return (
    <div>
      <ActionMenu menuControl={menuControlProp} />

      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
        <Card>
          <CardContent>
            <Grid container alignItems="center" justifyContent="center" spacing={1}>
              <Grid item xs={9}>
                <BoxHeader header={"Asset Register"} />
              </Grid>
              <Grid item xs={3}>
                <ButtonUpload
                  id="ast_img"
                  name="ast_img"
                  style={{ display: "none" }}
                  type="file"
                  onChange={UploadImg}
                  accept="image/png, image/gif, image/jpeg"
                  btntext="Choose Image"
                  maxSize={1}
                />
              </Grid>
            </Grid>

            <Grid container alignItems="center" justifyContent="center" spacing={1} style={{ marginBottom: "auto" }}>
              <Grid item xs={9}>
                <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: "auto" }}>
                  {formFields
                    ? formFields.map((item) => (
                        <Grid item xs={item.size} key={item.field.props.name?.toString()} style={item.style}>
                          {React.createElement(item.field.type, {
                            ...{
                              ...item.field.props,
                              methods,
                              key: item.field.props.name,
                              onChange: UpdateForm,
                            },
                          })}
                        </Grid>
                      ))
                    : ""}
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Paper style={{ padding: 4, textAlign: "center" }}>
                  {initData.AstPhoto ? (
                    <CardImage base64Src={initData.AstPhoto} noBorder={true} customSize={{ height: 200 }} />
                  ) : (
                    <CardImage imgSrc={PreviewImage} noBorder={true} customSize={{ height: 200 }} />
                  )}
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box height={5} />

        <Card>
          <CardContent>
            <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 10 }}>
              <Grid item xs={12}>
                <Typography className={classes.heading}>Register Value</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="flex-start" spacing={1}>
                  {formFieldsRegister
                    ? formFieldsRegister.map((item) => (
                        <Grid item xs={item.size} key={item.field.props.name?.toString()}>
                          {React.createElement(item.field.type, {
                            ...{
                              ...item.field.props,
                              methods,
                              key: item.field.props.name,
                              onChange: CalculateForm,
                            },
                          })}
                        </Grid>
                      ))
                    : ""}
                </Grid>
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </CardContent>
        </Card>
        <Box height={5} />

        <Card>
          <CardContent>
            <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
              <Grid item xs={12}>
                <Typography className={classes.heading}>{translate("ra.field.Asset Account")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="center" spacing={1}>
                  {formFieldsAccount
                    ? formFieldsAccount.map((item) => (
                        <Grid item xs={item.size} key={item.field.props.name?.toString()}>
                          {React.createElement(item.field.type, {
                            ...{
                              ...item.field.props,
                              methods,
                              key: item.field.props.name,
                            },
                          })}
                        </Grid>
                      ))
                    : ""}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(initData, 0, 2) : ""}</pre>
        <ButtonFooter disabled={isBtnLoading} CancelFnc={CancelFnc} />
      </form>

      {openTemplateDialog && <DialogTemplate open={openTemplateDialog} onClose={CloseTemplateDialog} />}
    </div>
  );
};

export default Create;
