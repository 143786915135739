/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { GblContext } from "providers/formatter";
import clsx from "clsx";
import { Loading, useRedirect, useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import {
  useMediaQuery,
  Grid,
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Chip,
  Typography,
} from "@material-ui/core";
import { getApPaymentSearchList } from "services/accountPayable";
import { getWorkflowStep } from "services/workflow";
import { getEnumApInvoiceStatus } from "services/enum";
import MuiTranslateTable from "components/MuiTranslateTable";
import ActionMenu from "components/ActionMenu";
import CustomTablePagination from "components/CustomTablePagination";
import MbCard from "./MbCard";
import MbTextFieldSearch from "./MbTextFieldSearch";
import DatePickerFormat from "components/DatePickerFormat";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { permissionName } from "utils/constants";
import { addDays, startOfMonth, endOfMonth } from "date-fns";
import ModelUriQueryString from "models/uriQueryString";
import CustomToolbarSelect from "./CustomToolbarSelect";
import { GetWfStatus } from "utils/options";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  statusDraft: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
  },
  statusNormal: {
    backgroundColor: "#2196f3",
    color: "white",
  },
  statusVoid: {
    backgroundColor: "#e57373",
    color: "white",
  },
}));

const arrFilterDate = ["All", "Payment Date", "Cheque Date"];

const List = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const { basePath, permissions } = props;

  //initial Value with window.sessionStorage
  const ws = window.sessionStorage;
  let t = localStorage.getItem("Tenant");
  const filterValue =
    ws.getItem("basePath") === basePath && ws.getItem(`${t}_searchDetails`)
      ? JSON.parse(ws.getItem(`${t}_searchDetails`)).filterValue
      : undefined;
  const searchQuery =
    ws.getItem("basePath") === basePath && ws.getItem(`${t}_searchDetails`)
      ? JSON.parse(ws.getItem(`${t}_searchDetails`)).searchQuery
      : undefined;
  const searchResults =
    ws.getItem("basePath") === basePath && ws.getItem(`${t}_searchDetails`)
      ? JSON.parse(ws.getItem(`${t}_searchDetails`)).searchResults
      : undefined;

  const { settingAll, DateToString, NumberFormat, ToMySqlDate } = useContext(GblContext);
  const { SettingClosePeriod } = settingAll;
  const { ClosePeriodAp } = SettingClosePeriod;
  const [Data, setData] = useState(searchResults ? searchResults : undefined);
  const [Paging, setPaging] = useState();
  const [uriQueryString, setUriQueryString] = useState({
    Limit: searchQuery ? searchQuery.Limit : ModelUriQueryString.Limit,
    Page: searchQuery ? searchQuery.Page : ModelUriQueryString.Page,
    OrderBy: searchQuery ? searchQuery.OrderBy : ModelUriQueryString.OrderBy,
    WhereGroupList: searchQuery ? searchQuery.WhereGroupList : ModelUriQueryString.WhereGroupList,
    Exclude: searchQuery ? searchQuery.Exclude : ModelUriQueryString.Exclude,
    WhereRaw: searchQuery ? searchQuery.WhereRaw : ModelUriQueryString.WhereRaw,
    WhereLike: searchQuery ? searchQuery.WhereLike : ModelUriQueryString.WhereLike,
    WhereLikeFields: searchQuery ? searchQuery.WhereLikeFields : ModelUriQueryString.WhereLikeFields,
  });
  const [searchText, setSearchText] = useState(filterValue ? filterValue.searchValue : undefined);
  const [wfSteps, setWfSteps] = useState();
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [wfEnumStatus, setWfEnumStatus] = useState();
  const [loading, setLoading] = useState(true);
  const [optionDate, setOptionDate] = useState(filterValue ? filterValue.optionDate : "Payment Date");
  const [status, setStatus] = useState("E");
  const [wfStatus, setWfStatus] = useState(
    filterValue ? filterValue.status : localStorage.getItem("defaultWfApPayment") ?? null
  );
  const [filterDate, setFilterDate] = useState({
    from: filterValue ? new Date(filterValue.filterDate.from) : startOfMonth(addDays(new Date(ClosePeriodAp), 1)),
    to: filterValue ? new Date(filterValue.filterDate.to) : endOfMonth(new Date()),
  });

  const redirect = useRedirect();

  const handleFilterList = (tempWfSteps) => {
    const fromMySqlDate = ToMySqlDate(filterDate.from);
    const toMySqlDate = ToMySqlDate(filterDate.to);

    switch (optionDate) {
      case "Payment Date":
        uriQueryString.WhereGroupList = [
          {
            AndOr: "And",
            ConditionList: [
              {
                AndOr: "And",
                Field: "PyhDate",
                Operator: ">=",
                Value: fromMySqlDate,
              },
              {
                AndOr: "And",
                Field: "PyhDate",
                Operator: "<=",
                Value: toMySqlDate,
              },
            ],
          },
        ];
        break;
      case "Cheque Date":
        uriQueryString.WhereGroupList = [
          {
            AndOr: "And",
            ConditionList: [
              {
                AndOr: "And",
                Field: "PyhChqDt",
                Operator: ">=",
                Value: fromMySqlDate,
              },
              {
                AndOr: "And",
                Field: "PyhChqDt",
                Operator: "<=",
                Value: toMySqlDate,
              },
            ],
          },
        ];
        break;
      default:
        uriQueryString.WhereGroupList = [
          {
            AndOr: "And",
            ConditionList: [],
          },
        ];
        break;
    }

    const condition = uriQueryString.WhereGroupList[0].ConditionList.find((item) => item.Field === "PyhStatus");
    switch (status) {
      case "All":
        // if (!condition) {
        //   uriQueryString.WhereGroupList[0].ConditionList.push({
        //     AndOr: "And",
        //     Field: "PyhStatus",
        //     Operator: "=",
        //     Value: "E",
        //   });
        //   uriQueryString.WhereGroupList[0].ConditionList.push({
        //     AndOr: "Or",
        //     Field: "PyhStatus",
        //     Operator: "=",
        //     Value: "V",
        //   });
        //   uriQueryString.WhereGroupList[0].ConditionList.push({
        //     AndOr: "Or",
        //     Field: "PyhStatus",
        //     Operator: "=",
        //     Value: "P",
        //   });
        // }
        break;
      case "InProcess":
        if (!condition) {
          uriQueryString.WhereGroupList[0].ConditionList.push({
            AndOr: "And",
            Field: "PyhStatus",
            Operator: "!=",
            Value: "E",
          });
          uriQueryString.WhereGroupList[0].ConditionList.push({
            AndOr: "And",
            Field: "PyhStatus",
            Operator: "!=",
            Value: "V",
          });
          uriQueryString.WhereGroupList[0].ConditionList.push({
            AndOr: "And",
            Field: "PyhStatus",
            Operator: "!=",
            Value: "P",
          });
          uriQueryString.WhereGroupList[0].ConditionList.push({
            AndOr: "And",
            Field: "PyhStatus",
            Operator: "!=",
            Value: "0",
          });
        }
        break;
      default:
        if (!condition) {
          uriQueryString.WhereGroupList[0].ConditionList.push({
            AndOr: "And",
            Field: "PyhStatus",
            Operator: "=",
            Value: tempWfSteps
              ? tempWfSteps?.length === parseInt(status)
                ? "E"
                : status
              : wfSteps?.length === parseInt(status)
              ? "E"
              : status,
          });
        }
        break;
    }
  };

  const fetchSearchList = async (uriQueryString, mounted = true) => {
    setLoading(true);
    handleFilterList(uriQueryString.tempWfSteps);
    uriQueryString.Exclude = ["Detail", "PayWht", "DimHList"];
    if (isXSmall) {
      uriQueryString.Limit = 50;
    }
    const { Data, Paging } = await getApPaymentSearchList(uriQueryString);
    if (Data) {
      setData(Data);
      setPaging(Paging);
      setUriQueryString(uriQueryString);
    } else {
      setData([]);
    }

    if (mounted) {
      setLoading(false);
    }
  };

  const fetchWorkflow = async () => {
    const enumData = await getEnumApInvoiceStatus();
    setWfEnumStatus(enumData);
    const { data } = await getWorkflowStep("AP_PAYMENT");
    if (data) {
      const showc = data?.find((item) => item.StepNo === parseInt(status));
      if (showc && data) {
        setShowCheckBox(true);
      } else {
        setShowCheckBox(false);
      }
      setWfSteps(data);
    }
  };

  useEffect(() => {
    const mounted = true;
    fetchWorkflow().then((r) => {
      uriQueryString.tempWfSteps = r;
      fetchSearchList(uriQueryString, mounted);
    });
  }, [uriQueryString, filterDate, optionDate, status]);

  const handleRequestSort = (property, order) => {
    uriQueryString.OrderBy = { [property]: order };
    fetchSearchList(uriQueryString);
  };

  if (loading) return <Loading />;

  const handleChangePage = (e, newPage) => {
    uriQueryString.Page = newPage + 1;
    fetchSearchList(uriQueryString);
  };

  const handleChangeRowsPerPage = (e) => {
    const newRowsPerPage = parseInt(e.target.value);
    uriQueryString.Limit = newRowsPerPage;
    fetchSearchList(uriQueryString);
  };

  const handleTableChange = (action, tableState) => {
    if (action === "onSearchClose") {
      //clear window.sessionstorage (when user click close icon)
      ws.clear();
      setSearchText();
      uriQueryString.WhereLike = "";
      fetchSearchList(uriQueryString);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    uriQueryString.WhereLike = `%${value}%`;
    uriQueryString.WhereLikeFields = [
      "PyhSeq",
      "VnCode",
      "VnName",
      "AppyCode",
      "DATE_FORMAT(PyhDate, '%d/%m/%Y')",
      "PyhDesc",
      "PyhChqFr",
      "PyhChqDt",
      "CurCode",
      "PyhAmt",
    ];
    uriQueryString.Page = 1;
    fetchSearchList(uriQueryString);
  };

  const columns = [
    {
      name: "PyhSeq",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <a href={`#${basePath}/${value}/show`}>
              <VisibilityIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const filterValueObj = {
                    searchValue: searchText,
                    optionDate: optionDate,
                    status: status,
                    filterDate: filterDate,
                  };
                  ws.setItem("basePath", basePath);
                  ws.setItem(
                    `${t}_searchDetails`,
                    JSON.stringify({
                      filterValue: filterValueObj,
                      searchQuery: uriQueryString,
                      searchDetails: Data,
                    })
                  );
                  redirect("show", basePath, value);
                }}
              />
            </a>
          );
        },
      },
    },
    {
      name: "PyhSeq",
      label: "Seq",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "PyhDate",
      label: "Payment Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? DateToString(new Date(value)) : null;
        },
      },
    },
    {
      name: "VnCode",
      label: "Vendor",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "VnName",
      label: "Vendor Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "AppyCode",
      label: "Payment Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "PyhChqFr",
      label: "Cheque No",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "PyhChqDt",
      label: "Cheque Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? DateToString(new Date(value)) : null;
        },
      },
    },
    {
      name: "PyhDesc",
      label: "Description",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "CurCode",
      label: "Currency",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "CurRate",
      label: "Rate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "currency");
        },
      },
    },
    {
      name: "PyhAmt",
      label: "Total",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "PyhStatus",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Chip
              label={
                wfSteps?.length === status
                  ? GetWfStatus(wfSteps, wfEnumStatus, wfStatus)
                  : GetWfStatus(wfSteps, wfEnumStatus, value)
              }
              size="small"
              className={clsx(classes.statusDraft, {
                [classes.statusNormal]: value === "Effective",
                [classes.statusVoid]: value === "Void",
              })}
            />
          );
        },
      },
    },
  ];

  const options = {
    filter: false, // show the filter icon in the toolbar (true by default)
    responsive: "standard",
    selectableRows: showCheckBox ? "multiple" : "none",
    isRowSelectable: (dataIndex) => {
      if (
        Data[dataIndex].PyhStatus === "Effective" ||
        Data[dataIndex].PyhStatus === "Void" ||
        Data[dataIndex].PyhStatus === "Printed"
      ) {
        return false;
      }
      return true;
    },
    serverSide: true,
    confirmFilters: true,
    searchText: searchText,
    searchProps: {
      onKeyUp: (e) => {
        if (e.keyCode === 13) {
          handleSearch(e.target.value);
        }
      },
    },
    download: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      return `\uFEFF${buildHead(columns)}${buildBody(data)}`;
    },
    onTableChange: (action, tableState) => handleTableChange(action, tableState),
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    sortOrder: {
      name: Object.keys(uriQueryString.OrderBy)[0],
      direction: Object.values(uriQueryString.OrderBy)[0],
    },
    onColumnSortChange: (changedColumn, direction) => {
      handleRequestSort(changedColumn, direction);
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <CustomToolbarSelect
          noBorder
          data={Data}
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          status={status}
          wfCode={"AP_PAYMENT"}
          fncSuccess={() => fetchSearchList(uriQueryString)}
        />
      );
    },
    customFooter: () => {
      return (
        <CustomTablePagination
          rowsPerPageOptions={[15, 50, 100]}
          component="div"
          count={Paging?.TotalRecordCount ?? 0}
          rowsPerPage={Paging?.Limit ?? 15}
          page={Paging?.Page ? Paging.Page - 1 : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      );
    },
  };

  const menuControlProp = [{ name: "Add", fnc: () => redirect("create", basePath) }];

  const CustomFilterList = () => {
    return (
      <>
        <Box display="flex">
          <Box p={1}>
            <FormControl variant="outlined">
              <InputLabel id="viewMode">{translate("ra.field.ViewBy")}</InputLabel>
              <Select
                labelId="viewMode"
                label={translate("ra.field.ViewBy")}
                variant="outlined"
                margin="dense"
                value={optionDate}
                defaultValue={optionDate}
                onChange={(e) => {
                  setUriQueryString({ ...uriQueryString, Page: 1 });
                  setOptionDate(e.target.value);
                }}
              >
                {arrFilterDate.map((item, idx) => (
                  <MenuItem key={idx} value={item}>
                    {translate(`ra.field.${item}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {optionDate !== "All" && (
            <Box p={1}>
              <DatePickerFormat
                label={translate("ra.field.From")}
                value={filterDate.from}
                onChange={(e) => {
                  setUriQueryString({ ...uriQueryString, Page: 1 });
                  setFilterDate((state) => ({
                    ...state,
                    from: e,
                    to: e > filterDate.to ? e : filterDate.to,
                  }));
                }}
                style={{ width: 160, margin: "0 10px" }}
              />
              <DatePickerFormat
                label={translate("ra.field.To")}
                value={filterDate.to}
                onChange={(e) => {
                  setUriQueryString({ ...uriQueryString, Page: 1 });
                  setFilterDate((state) => ({
                    ...state,
                    to: e,
                  }));
                }}
                minDate={filterDate.from}
                minDateMessage={"Date must be more than from date"}
                style={{ width: 160, margin: "0 10px" }}
              />
            </Box>
          )}
          <Box p={1}>
            <FormControl variant="outlined">
              <InputLabel id="status">{translate("ra.field.Status")}</InputLabel>
              <Select
                variant="outlined"
                margin="dense"
                labelId="status"
                label={translate("ra.field.Status")}
                value={status}
                onChange={(e) => {
                  setUriQueryString({ ...uriQueryString, Page: 1 });
                  const showc = wfSteps?.find((item) => item.StepNo === parseInt(e.target.value));
                  if (showc && wfSteps) {
                    setShowCheckBox(true);
                  } else {
                    setShowCheckBox(false);
                  }
                  setStatus(e.target.value);
                  setWfStatus(null);
                }}
                style={{ width: 160 }}
              >
                <MenuItem key={"All"} value={"All"}>
                  {translate("ra.field.All")}
                </MenuItem>
                <MenuItem key={"E"} value={"E"}>
                  {translate("ra.actionMenu.Effective")}
                </MenuItem>
                <MenuItem key={"P"} value={"P"}>
                  {translate("ra.actionMenu.Printed")}
                </MenuItem>
                <MenuItem key={"V"} value={"V"}>
                  {translate("ra.actionMenu.Void")}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        {wfSteps?.length > 0 ? (
          <Typography variant="h6" gutterBottom style={{ marginLeft: 18, marginTop: 4, marginBottom: 0 }}>
            Workflow
          </Typography>
        ) : null}
        <Box display="flex">
          {wfSteps?.map((item, idx) => (
            <Box p={1} key={idx}>
              <Chip
                clickable
                variant={item.StepNo === status ? "default" : "outlined"}
                color={item.StepNo === status ? "primary" : "default"}
                value={item.StepNo?.toString()}
                label={item.StepName}
                onClick={(e) => {
                  setUriQueryString({ ...uriQueryString, Page: 1 });
                  setWfStatus(e.target.outerText);
                  setStatus(item.StepNo);
                }}
              />
            </Box>
          ))}
        </Box>
      </>
    );
  };

  const MbFilterList = () => {
    return (
      <>
        <Typography variant="h6" gutterBottom style={{ marginLeft: 18, marginTop: 4 }}>
          A/P Payment
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginLeft: 16 }}>
            <MbTextFieldSearch
              optionDate={optionDate}
              setOptionDate={setOptionDate}
              arrFilterDate={arrFilterDate}
              filterDate={filterDate}
              setFilterDate={setFilterDate}
              status={status}
              setStatus={setStatus}
              setShowCheckBox={setShowCheckBox}
              wfSteps={wfSteps}
              searchFnc={handleSearch}
              searchText={searchText}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const MbCards = ({ item }) => {
    return (
      // rome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
      <div
        style={{ margin: "5px 10px", padding: "10px 6px 0 6px" }}
        onClick={() => redirect("show", basePath, item.PyhSeq)}
      >
        <MbCard
          data={item}
          NumberFormat={NumberFormat}
          DateToString={DateToString}
          wfSteps={wfSteps}
          wfEnumStatus={wfEnumStatus}
        />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <ActionMenu
        menuControl={menuControlProp}
        permission={permissions.find((i) => i.Name === permissionName["AP.Payment"])}
      />
      {isXSmall && <MbFilterList />}

      {isXSmall ? (
        Data.length > 0 ? (
          Data.map((item, idx) => <MbCards key={idx} item={item} />)
        ) : (
          <MbCards item={null} />
        )
      ) : (
        <MuiTranslateTable
          title={"A/P Payment"}
          data={Data}
          columns={columns}
          options={options}
          components={{
            TableFilterList: CustomFilterList,
          }}
        />
      )}
    </div>
  );
};

export default List;
