/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { GblContext } from "providers/formatter";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { addDays, startOfMonth, endOfMonth } from "date-fns";
import { callStoreReport } from "services/callStoreProcedure";
import { Box, FormControl, Select, InputLabel, MenuItem, Typography, Button } from "@material-ui/core";
import { MTableToolbar } from "material-table";
import MeterialtableTranslate from "components/MeterialtableTranslate";
import DatePickerFormat from "components/DatePickerFormat";
import { Loading, useTranslate } from "react-admin";
import ActionMenu from "components/ActionMenu";
import SnackbarUtils from "utils/SnackbarUtils";
import XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  rootTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogBankExport = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { onClose, open } = props;
  const translate = useTranslate();
  const [isLoading, setLoading] = useState(false);
  const { DateToString, NumberFormat, ToMySqlDate } = useContext(GblContext);
  const [paymentDate, setPaymentDate] = useState({
    from: startOfMonth(addDays(new Date(), 1)),
    to: endOfMonth(addDays(new Date(), 1)),
  });
  const [chequeDate, setChequeDate] = useState("");
  const [selectedBankCode, setSelectedBankCode] = useState("");
  const [listOfBankCode, setListOfBankCode] = useState();
  const [listOfChequeDate, setListOfChequeDate] = useState();
  const [stateView, setStateView] = useState(false);
  const [data, setData] = useState();

  const menuControlProp = [
    {
      name: "Export",
      fnc: async () => {
        let mappingData = data.map((item, idx) => {
          return {
            ลำดับที่: idx + 1,
            ชื่อ: item.BankAccountName,
            จำนวนเงิน: NumberFormat(item.PyhAmt),
            รหัสธนาคาร: item.BankRef1,
            รหัสสาขา: item.BankRef2,
            เลขที่บัญชี: item.BankAccountNo,
            //PyhSeq: item.PyhSeq,
          };
        });
        // let period = `${filter.year}-${filter.month}`;
        // let id = listId.length > 0 ? listId.join() : "";
        // var ws_data = [];
        // var data = await postApWhtReconExpSp(period, id);
        if (data) {
          // data.forEach((item) => {
          //   ws_data.push([Object.values(item).join("|")]);
          // });
          /* make the worksheet */
          //var ws_data = [["1"],[2]];
          var ws = XLSX.utils.json_to_sheet(mappingData);
          /* add to workbook */
          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          XLSX.writeFile(wb, `${selectedBankCode}-${chequeDate}.xlsx`);
        } else {
          SnackbarUtils.error("No data");
        }
      },
      disabled: data?.length < 1,
    },
    // { name: "Export", fnc: () => setOpenDialog((state) => ({ ...state, export: true })), disabled: Data?.length < 1 },
  ];

  const stringDateToMysqlDate = (s) => {
    let d = s.split("/");
    return `${d[2]}-${d[1]}-${d[0]}`;
  };

  const fetchChequeDate = async () => {
    setLoading(true);
    let p = {
      fromDate: ToMySqlDate(paymentDate.from),
      toDate: ToMySqlDate(paymentDate.to),
      chqueDate: "",
      bankCode: "",
    };
    const r = await callStoreReport("spGetDataBankExport", p);
    if (!r) {
      setData();
      setLoading(false);
      return;
    } else {
      const listChequeDate = r.map((item) => DateToString(item.PyhChqDt));
      setListOfChequeDate(listChequeDate);
      setChequeDate(listChequeDate[0]);
      let p = {
        fromDate: ToMySqlDate(paymentDate.from),
        toDate: ToMySqlDate(paymentDate.to),
        chqueDate: stringDateToMysqlDate(listChequeDate[0]),
        bankCode: "",
      };
      const listBank = await callStoreReport("rptGetListCodeBankExport", p);
      const bankList = listBank.map((item) => item.ApPyCode);
      setListOfBankCode(bankList);
      setSelectedBankCode(bankList[0]);
      setStateView(true);
      setLoading(false);
    }
  };

  const fetchSearchList = async (mounted) => {
    setLoading(true);
    let p = {
      fromDate: ToMySqlDate(paymentDate.from),
      toDate: ToMySqlDate(paymentDate.to),
      chqueDate: stringDateToMysqlDate(chequeDate) ?? "",
      bankCode: selectedBankCode ?? "",
    };
    const response = await callStoreReport("spGetDataBankExport", p);
    if (response) {
      setData(response);
    } else {
      setData();
    }
    if (mounted) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (stateView) {
      fetchSearchList(mounted);
    }
    return function cleanup() {
      mounted = false;
    };
  }, [chequeDate, selectedBankCode, stateView]);

  const columns = [
    {
      title: "#",
      field: "PyhSeq",
      sorting: true,
    },
    { title: "Payment Date", field: "PyhDate", sorting: false, render: (rowData) => DateToString(rowData.PyhDate) },
    // { title: "Cheque Date", field: "PyhChqDt", sorting: false, render: (rowData) => DateToString(rowData.PyhChqDt) },
    {
      title: "Vendor",
      field: "Vncode",
      sorting: true,
      cellStyle: {
        width: 500,
      },
      render: (rowData) => (
        <>
          {rowData.Vncode}
          <br />
          <small>{rowData.VnName}</small>
        </>
      ),
    },
    { title: "WHT. Form", field: "WhtTypeCode", sorting: true },
    {
      title: "WHT. Amount",
      field: "WhtTotalAmt",
      sorting: true,
      type: "numeric",
      render: (rowData) => NumberFormat(rowData.WhtTotalAmt),
    },
    {
      title: "Payment Amount",
      field: "PyhAmt",
      sorting: true,
      type: "numeric",
      render: (rowData) => NumberFormat(rowData.PyhAmt),
    },
  ];

  const options = {
    draggable: false,
    headerStyle: {
      backgroundColor: theme.palette.primary.main,
      color: "#FFF",
    },
    padding: "dense",
    paging: true,
    pageSize: 15,
    pageSizeOptions: [15, 50, 100],
    //selection: true,
    selectionProps: () => ({
      color: "primary",
    }),
    rowStyle: (item) => {
      if (item.tableData.id % 2) {
        return { backgroundColor: "#f2f2f2" };
      }
    },
  };

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.rootTitle} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  const handleCancel = () => {
    onClose();
  };

  const CustomFilterList = (props) => {
    return (
      <>
        <MTableToolbar {...props} />
        <Box display="flex" style={{ marginTop: props.selectedRows.length > 0 ? 0 : -50 }}>
          {listOfChequeDate && (
            <Box p={1}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label">Cheque Date</InputLabel>
                <Select
                  labelId="label"
                  variant="outlined"
                  margin="dense"
                  label="Cheque Date"
                  style={{ width: 160 }}
                  value={chequeDate}
                  onChange={(e) => {
                    setChequeDate(e.target.value);
                  }}
                >
                  {listOfChequeDate
                    ? listOfChequeDate.map((item, idx) => (
                        <MenuItem key={idx} value={item}>
                          {item}
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </FormControl>
            </Box>
          )}

          {listOfBankCode && (
            <Box p={1}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label">BankCode</InputLabel>
                <Select
                  labelId="label"
                  variant="outlined"
                  margin="dense"
                  label="BankCode"
                  style={{ width: 160 }}
                  value={selectedBankCode}
                  onChange={(e) => {
                    setSelectedBankCode(e.target.value);
                  }}
                >
                  {listOfBankCode
                    ? listOfBankCode.map((item, idx) => (
                        <MenuItem key={idx} value={item}>
                          {item}
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
      </>
    );
  };

  return (
    <Dialog fullWidth maxWidth="md" aria-labelledby="confirmation-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleCancel}>
        BankExport
      </DialogTitle>
      <DialogContent dividers>
        <Box p={1} display="flex" alignItems="center" justifyContent={"center"}>
          <Typography variant="h6" className={classes.title2} style={{ margin: "0 10px" }}>
            Payment Date
          </Typography>
          <Box>
            <DatePickerFormat
              label={translate("ra.field.From")}
              value={paymentDate.from}
              onChange={(e) => {
                setPaymentDate((state) => ({
                  ...state,
                  from: e,
                  to: e > paymentDate.to ? e : paymentDate.to,
                }));
              }}
              style={{ width: 160, margin: "0 10px" }}
            />
            <DatePickerFormat
              label={translate("ra.field.To")}
              value={paymentDate.to}
              onChange={(e) => {
                setPaymentDate((state) => ({
                  ...state,
                  to: e,
                }));
              }}
              minDate={paymentDate.from}
              minDateMessage={"Date must be more than from date"}
              style={{ width: 160, margin: "0 10px" }}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "0 10px" }}
              onClick={() => {
                fetchChequeDate();
              }}
            >
              View
            </Button>
          </Box>
        </Box>

        {data?.length > 0 ? (
          <>
            <ActionMenu justifyContent="flex-start" menuControl={menuControlProp} />
            <MeterialtableTranslate
              title=""
              data={data}
              columns={columns}
              options={options}
              isLoading={isLoading}
              components={{
                Toolbar: CustomFilterList,
              }}
            />
          </>
        ) : (
          <>
            {isLoading ? (
              <Loading />
            ) : (
              <Box p={1} display="flex" alignItems="center" justifyContent={"center"} style={{ height: 640 }}>
                No data
              </Box>
            )}
            {data?.length === 0 && !isLoading && (
              <Box p={1} display="flex" alignItems="center" justifyContent={"center"} style={{ height: 640 }}>
                No data
              </Box>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DialogBankExport;
