import React, { useContext, useEffect, useRef, useState } from "react";
import { TableCell, TableRow, IconButton } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { GblContext } from "providers/formatter";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomToolbar from "./CustomToolbar";

const useStyles = makeStyles(() => ({
  colorCell: {
    backgroundColor: "#34558b",
    color: "#fff",
  },
  cellHeaderName: {
    fontWeight: "bold",
    paddingTop: 8,
    paddingBottom: 8,
  },
  cellHeaderNumber: {
    fontWeight: "bold",
    paddingTop: 8,
    paddingBottom: 8,
    borderBottom: "1px solid black",
  },
  cellHeader: {
    backgroundColor: "#B8CCE4",
    fontWeight: "bold",
    borderBottom: "1px solid black",
  },
  TableRow: {
    cursor: "pointer",
    paddingLeft: 0,
  },
  TableRowSub: {
    cursor: "default",
    paddingLeft: 0,
  },
  IconWrapper: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.10)",
      borderRadius: "50%",
    },
  },
}));

const Table = ({ basePath, dataTable, title }) => {
  const { NumberFormat } = useContext(GblContext);
  const classes = useStyles();

  const [open, setOpen] = useState({});

  const toggleRow = (name) => {
    const newRowState = { ...open };
    newRowState[name] = !newRowState[name];
    setOpen(newRowState);
  };

  let shouldGet = useRef(true);
  useEffect(() => {
    if (shouldGet.current) {
      shouldGet.current = false;
      const openSub = [];
      if (dataTable) {
        dataTable.map((el, i) => {
          if (el.children?.length > 0) {
            el.children.forEach((jItem) => {
              openSub.push({
                [`${jItem.Name}`]: true,
              });
              if (jItem.children?.length > 0) {
                jItem.children.forEach((kItem) => {
                  openSub.push({
                    [`${kItem.Name}`]: true,
                  });
                  if (kItem.children?.length > 0) {
                    kItem.children.forEach((lItem) => {
                      openSub.push({
                        [`${lItem.Name}`]: true,
                      });
                    });
                  }
                });
              }
            });
          }
          return el;
        });

        const result = openSub.reduce((acc, curr) => {
          return { ...acc, ...curr };
        }, {});

        setOpen(result);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findTotal = (data) => {
    let dTotal = data.Name.slice(0, 5) === "Total";
    if (dTotal) {
      return true;
    } else {
      return false;
    }
  };

  const columns = [
    {
      name: "Name",
      label: "Name",
      options: {
        customHeadRender: ({ label }) => {
          return (
            <TableCell align="center" className={classes.colorCell}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (value, data) => {
          return <b style={{ marginLeft: -20 }}>{value}</b>;
        },
      },
    },
    {
      name: "Current",
      label: "CURRENT YEAR",
      options: {
        customHeadRender: ({ label }) => {
          return (
            <TableCell align="right" className={classes.colorCell}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: () => {
          return null;
        },
      },
    },
    {
      name: "Prior",
      label: "PRIOR YEAR",
      options: {
        customHeadRender: ({ label }) => {
          return (
            <TableCell align="right" className={classes.colorCell}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: () => {
          return null;
        },
      },
    },
    {
      name: "Varience",
      label: "VARIENCE",
      options: {
        customHeadRender: ({ label }) => {
          return (
            <TableCell align="right" className={classes.colorCell}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: () => {
          return null;
        },
      },
    },
    {
      name: "Percent",
      label: "%",
      options: {
        customHeadRender: ({ label }) => {
          return (
            <TableCell align="right" className={classes.colorCell}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: () => {
          return null;
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    filter: false,
    filterType: "dropdown",
    responsive: "standard",
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    filters: false,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    pagination: false,
    customToolbar: () => {
      return <CustomToolbar basePath={basePath} id={"BS"} />;
    },
    setTableProps: () => ({
      size: "small",
    }),
    isRowExpandable: (dataIndex, expandedRows) => {
      if (dataTable[dataIndex]?.hasOwnProperty("children") === false) {
        return false;
      }
      return true;
    },
    rowsExpanded: dataTable && dataTable?.map((el, i) => i),
    renderExpandableRow: (rowData, rowMeta) => {
      const rowIndex = rowMeta.rowIndex;
      const subChild = dataTable[rowIndex].children;

      if (subChild && Array.isArray(subChild) && subChild.length > 0) {
        return subChild.map((subItem, index) => (
          <React.Fragment key={`sub${index}`}>
            <TableRow onClick={() => toggleRow(subItem.Name)}>
              <TableCell style={{ paddingLeft: 25 }}>
                {subItem.children && (
                  <IconButton onClick={() => toggleRow(subItem.Name)} size="small">
                    {open[subItem.Name] ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </IconButton>
                )}
              </TableCell>

              {findTotal(subItem) ? (
                <TableCell style={{ paddingLeft: 0 }} className={classes.cellHeaderName}>
                  {subItem.children ? <strong>{subItem.Name}</strong> : subItem.Name}
                </TableCell>
              ) : (
                <TableCell style={{ paddingLeft: 15 }}>
                  {subItem.children ? <strong>{subItem.Name}</strong> : subItem.Name}
                </TableCell>
              )}

              <TableCell align="right" className={findTotal(subItem) ? classes.cellHeader : ""}>
                {subItem.children ? null : NumberFormat(subItem.Current ?? 0)}
              </TableCell>
              <TableCell align="right" className={findTotal(subItem) ? classes.cellHeader : ""}>
                {subItem.children ? null : NumberFormat(subItem.Prior ?? 0)}
              </TableCell>
              <TableCell align="right" className={findTotal(subItem) ? classes.cellHeader : ""}>
                {subItem.children ? null : NumberFormat(subItem.Varience ?? 0)}
              </TableCell>
              <TableCell align="right" className={findTotal(subItem) ? classes.cellHeader : ""}>
                {subItem.children ? null : NumberFormat(subItem.Percent ?? 0)}
              </TableCell>
            </TableRow>

            {open[subItem.Name] &&
              subItem.children &&
              subItem.children.map((item, childIndex) => (
                <>
                  <TableRow
                    key={`child${childIndex}`}
                    className={classes.TableRowSub}
                    onClick={() => toggleRow(item.Name)}
                  >
                    <TableCell style={{ paddingLeft: 40 }}>
                      {item.children && (
                        <IconButton onClick={() => toggleRow(item.Name)} size="small">
                          {open[item.Name] ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ paddingLeft: findTotal(item) ? 15 : 30 }}
                      className={findTotal(item) ? classes.cellHeaderName : ""}
                    >
                      {item.Name}
                    </TableCell>
                    <TableCell align="right" className={findTotal(item) ? classes.cellHeaderNumber : ""}>
                      {item.children ? null : NumberFormat(item.Current ?? 0)}
                    </TableCell>
                    <TableCell align="right" className={findTotal(item) ? classes.cellHeaderNumber : ""}>
                      {item.children ? null : NumberFormat(item.Prior ?? 0)}
                    </TableCell>
                    <TableCell align="right" className={findTotal(item) ? classes.cellHeaderNumber : ""}>
                      {item.children ? null : NumberFormat(item.Varience ?? 0)}
                    </TableCell>
                    <TableCell align="right" className={findTotal(item) ? classes.cellHeaderNumber : ""}>
                      {item.children ? null : NumberFormat(item.Percent ?? 0)}
                    </TableCell>
                  </TableRow>

                  {open[item.Name] &&
                    item.children &&
                    item.children.map((subChild, subChildIndex) => (
                      <>
                        <TableRow
                          key={`subChild${subChildIndex}`}
                          className={classes.TableRowSub}
                          onClick={() => toggleRow(subChild.Name)}
                        >
                          <TableCell style={{ paddingLeft: 60 }}>
                            {subChild.children && (
                              <IconButton onClick={() => toggleRow(subChild.Name)} size="small">
                                {open[subChild.Name] ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell
                            style={{ paddingLeft: findTotal(subChild) ? 30 : 45 }}
                            className={findTotal(subChild) ? classes.cellHeaderName : ""}
                          >
                            {subChild.Name}
                          </TableCell>
                          <TableCell align="right" className={findTotal(subChild) ? classes.cellHeaderNumber : ""}>
                            {NumberFormat(subChild.Current ?? 0)}
                          </TableCell>
                          <TableCell align="right" className={findTotal(subChild) ? classes.cellHeaderNumber : ""}>
                            {NumberFormat(subChild.Prior ?? 0)}
                          </TableCell>
                          <TableCell align="right" className={findTotal(subChild) ? classes.cellHeaderNumber : ""}>
                            {NumberFormat(subChild.Varience ?? 0)}
                          </TableCell>
                          <TableCell align="right" className={findTotal(subChild) ? classes.cellHeaderNumber : ""}>
                            {NumberFormat(subChild.Percent ?? 0)}
                          </TableCell>
                        </TableRow>

                        {open[subChild.Name] &&
                          subChild.children &&
                          subChild.children.map((lastChild, lastChildIndex) => (
                            <TableRow key={`lastChild${lastChildIndex}`} className={classes.TableRowSub}>
                              <TableCell />
                              <TableCell style={{ paddingLeft: 60 }}>{lastChild.Name}</TableCell>
                              <TableCell align="right">{NumberFormat(lastChild.Current ?? 0)}</TableCell>
                              <TableCell align="right">{NumberFormat(lastChild.Prior ?? 0)}</TableCell>
                              <TableCell align="right">{NumberFormat(lastChild.Varience ?? 0)}</TableCell>
                              <TableCell align="right">{NumberFormat(lastChild.Percent ?? 0)}</TableCell>
                            </TableRow>
                          ))}
                      </>
                    ))}
                </>
              ))}
          </React.Fragment>
        ));
      }

      return null;
    },
  };

  const theme = createTheme({
    overrides: {
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: "#34558b",
          color: "#fff",
        },
        expandDisabled: {
          // Soft hide the button.
          visibility: "hidden",
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      {dataTable && <MUIDataTable title={title} data={dataTable ?? []} columns={columns} options={options} />}
    </MuiThemeProvider>
  );
};

export default Table;
