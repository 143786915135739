import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import { useAuthState, useTranslate, useLocale } from "react-admin";
import {
  TextFieldInForm,
  NumberFormatInForm,
  MuiAutosuggest,
  DateInForm,
  DescInForm,
  SelectInForm,
  CheckBoxInForm,
} from "components/Form";
import { getAccountCodeList, getDepartmentList, getUnitList, getMailProfile } from "services/setting";
import { getWorkflowByCode, getWorkflowStep } from "services/workflow";
import { getEnumApInvoiceStatus } from "services/enum";
import { getLookupCurrency } from "services/lookup";
import { getArProfileList } from "services/accountReceivable";
import List from "./List";
import Show from "./Show";
import Edit from "./Edit";
import Create from "./Create";
import { VatTypeOptions, VatTypeAR } from "utils/options";
import { makeStyles } from "@material-ui/core/styles";
import { addDays } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 12,
    marginBottom: 12,
  },
  footerCell: {
    backgroundColor: theme.palette.background.paper,
    borderTop: "2px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    textAlign: "right",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  drawerOpen: {
    marginRight: drawerWidth,
  },
  drawerClose: {
    marginRight: 54,
  },
  borderTableTop: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  borderTable: {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  content: {
    padding: 4,
  },
  colorCell: {
    backgroundColor: "#34558b",
    color: "#fff",
    width: "50px",
  },
  divComment: {
    position: "relative",
    height: "20px",
    width: "200px",
  },
  parentStyle: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "100%",
  },
  cellStyleEllipsis: {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const SwitchActionMode = (props) => {
  const locale = useLocale();
  const translate = useTranslate();
  const { authenticated } = useAuthState();
  const [wfSteps, setWfSteps] = useState();
  const [wfEnumStatus, setWfEnumStatus] = useState();
  const { settingAll, ToMySqlDate, ToNumber } = useContext(GblContext);
  const { SettingClosePeriod, SettingSystem } = settingAll;
  const { ClosePeriodAr } = SettingClosePeriod;
  const newClosePeriodAr = addDays(new Date(ClosePeriodAr), 1);
  const [oldAccList, setOldAccList] = useState([]);
  const addMode = props.location.pathname.search("create") !== -1;
  const copyMode = props.location.pathname.search("copy") !== -1;
  const [lookupList, setLookupList] = useState({
    accountCodeList: [],
    departmentList: [],
    currencyList: [],
    arProfileList: [],
    unitList: [],
  });
  const fetchAccLookup = useCallback(async () => {
    const { Data } = await getAccountCodeList("Ar");
    setLookupList((state) => ({
      ...state,
      accountCodeList: Data,
    }));
    setOldAccList(Data);
  }, []);
  const fetchDeptLookup = useCallback(async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      departmentList: Data,
    }));
  }, []);
  const fetchCurrencyLookup = useCallback(async () => {
    const p = {
      Module: "AR",
      CurrDate: ToMySqlDate(new Date()),
    };
    const arr = await getLookupCurrency(p);
    setLookupList((state) => ({
      ...state,
      currencyList: arr,
    }));
  }, [ToMySqlDate]);
  const fetchArProfileLookup = useCallback(async () => {
    const { Data } = await getArProfileList();
    setLookupList((state) => ({
      ...state,
      arProfileList: Data,
    }));
  }, []);
  const fetchUnitLookup = useCallback(async () => {
    const { Data } = await getUnitList();
    setLookupList((state) => ({
      ...state,
      unitList: Data,
    }));
  }, []);
  const fetchWorkflowByCode = useCallback(async (wfCode) => {
    const r = await getWorkflowByCode(wfCode);
    if (r?.data?.Active) {
      const enumData = await getEnumApInvoiceStatus();
      setWfEnumStatus(enumData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchWorkflowSteps = useCallback(async (wfCode) => {
    const { data } = await getWorkflowStep(wfCode);
    setWfSteps(data);
  }, []);

  useEffect(() => {
    if (authenticated) {
      fetchAccLookup();
      fetchDeptLookup();
      fetchArProfileLookup();
      fetchCurrencyLookup();
      fetchUnitLookup();
      fetchWorkflowByCode("AR_INVOICE");
      fetchWorkflowSteps("AR_INVOICE");
    }
  }, [
    authenticated,
    fetchAccLookup,
    fetchDeptLookup,
    fetchArProfileLookup,
    fetchCurrencyLookup,
    fetchUnitLookup,
    fetchWorkflowByCode,
    fetchWorkflowSteps,
  ]);

  const formFieldsEdit = [
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Invoice No.")}
          name="InvNo"
          variant="outlined"
          margin="dense"
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
            maxLength: {
              value: 30,
              message: "maximum length is 30",
            },
          }}
          disabled={true}
        />
      ),
    },
    {
      size: 1,
      field: <CheckBoxInForm label="Auto" name="RunNoType" disabled={!(addMode || copyMode)} />,
    },
    {
      size: 1,
      field: <CheckBoxInForm label="DisabledArNo" name="DisabledArNo" style={{ display: "none" }} />,
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.A/R No.")}`}
          name="ArNo"
          optKey="ArNo"
          optDesc="Company"
          optDesc2="FirstName"
          options={lookupList["arProfileList"]}
          updateOtherField={[
            { key: "Company", optKey: "Company", optKey2: "FirstName" },
            { key: "CreditTerm", optKey: "CreditTerm" },
          ]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
          disabledByOtherField={"DisabledArNo"}
        />
      ),
    },
    {
      size: 2,
      name: "Company",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="Company"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 1,
      name: "CreditTerm",
      field: (
        <DescInForm
          name="CreditTerm"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
      style: { display: "none" },
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Currency")}`}
          name="CurrCode"
          optKey="CurrCode"
          optDesc="CurrRate"
          options={lookupList["currencyList"]}
          updateOtherField={[{ key: "CurrRate", optKey: "CurrRate" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <NumberFormatInForm
          label={`*${translate("ra.field.Rate")}`}
          name="CurrRate"
          rule={{
            min: {
              value: 0.000001,
              message: "* Required",
            },
            required: {
              value: true,
              message: "* Required",
            },
          }}
          decimal={SettingSystem.CurrencyRateDecimal}
          // decimalSep={SettingSystem.DecimalSeparator}
          // thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm label={translate("ra.field.Prefix")} name="InvPrefix" variant="outlined" margin="dense" />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Tax Inv No.")}
          name="InvhTaxNo"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 30,
              message: "maximum length is 30",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: <SelectInForm label={translate("ra.field.Tax Status")} name="TaxType" options={VatTypeAR} />,
    },
    {
      size: 2,
      field: (
        <DateInForm
          label={translate("ra.field.Date")}
          name="InvhDate"
          minDate={new Date(newClosePeriodAr)}
          minDateMessage={"Date must be more than close period"}
          //for custom advance update field
          useFncUpdate={true}
          fncUpdate={async (value, methods) => {
            const p = {
              Module: "AR",
              CurrDate: ToMySqlDate(value),
            };
            const newCurrList = await getLookupCurrency(p);
            const currentCurCode = methods.watch("CurrCode");
            const currentCurRate = methods.watch("CurrRate");
            const newCurrRate = newCurrList.find((i) => i.CurrCode === currentCurCode).CurrRate;
            if (newCurrRate !== ToNumber(currentCurRate)) {
              SnackbarUtils.confirm(translate("ra.question.currencyWarning"), function () {
                methods.setValue("CurrRate", newCurrRate);
              });
            }
            setLookupList((state) => ({
              ...state,
              currencyList: newCurrList,
            }));
          }}
          required
        />
      ),
    },
    {
      size: 2,
      field: (
        <DateInForm
          label={translate("ra.field.Due Date")}
          name="InvhDueDate"
          minDate={new Date(newClosePeriodAr)}
          minDateMessage={"Date must be more than close period"}
          required
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Description")}
          name="InvhDesc"
          variant="outlined"
          margin="dense"
          //multiline
          //rows={4}
          rule={{
            maxLength: {
              value: 255,
              message: "maximun length is 255",
            },
          }}
        />
      ),
    },
  ];

  const formFieldsDetail = [
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Department")}`}
          name="DeptCode"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "DeptDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeList: daccList,
              }));
              //remove acc if not in defaultaccount
              const drAcc = methods.getValues("DrAcc");
              const crAcc = methods.getValues("CrAcc");
              const crTax1Acc = methods.getValues("TaxAcc1");
              const crTax2Acc = methods.getValues("TaxAcc2");
              if (drAcc !== "" && !daccList.find((i) => i.AccCode === drAcc)) {
                methods.setValue("DrAcc", "");
                methods.setValue("DrAccDesc", "");
              }
              if (crAcc !== "" && !daccList.find((i) => i.AccCode === crAcc)) {
                methods.setValue("CrAcc", "");
                methods.setValue("CrAccDesc", "");
              }
              if (crTax1Acc !== "" && !daccList.find((i) => i.AccCode === crTax1Acc)) {
                methods.setValue("TaxAcc1", "");
                methods.setValue("TaxAcc1Desc", "");
              }
              if (crTax2Acc !== "" && !daccList.find((i) => i.AccCode === crTax2Acc)) {
                methods.setValue("TaxAcc2", "");
                methods.setValue("TaxAcc2Desc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeList: oldAccList,
              }));
            }
          }}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 6,
      name: "DeptDesc",
      field: (
        <DescInForm
          name="DeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Comment")}
          name="InvdDesc"
          variant="outlined"
          margin="dense"
          multiline
          rows={3}
          rule={{
            maxLength: {
              value: 255,
              message: "maximun length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Reference")}
          name="InvdRemark"
          variant="outlined"
          margin="dense"
          multiline
          rows={3}
          rule={{
            maxLength: {
              value: 255,
              message: "maximun length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 6,
      field: <TextFieldInForm label="GroupNo" name="GroupNo" variant="outlined" margin="dense" />,
    },
    {
      size: 6,
      field: (
        <DateInForm
          label={translate("ra.field.Date")}
          name="InvdDate"
          minDate={new Date(newClosePeriodAr)}
          minDateMessage={"Date must be more than close period"}
          required
        />
      ),
    },
    {
      size: 4,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Unit")}`}
          name="Unit"
          optKey="UnitCode"
          optDesc="Description"
          options={lookupList["unitList"]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <NumberFormatInForm
          label={`*${translate("ra.field.Qty")}`}
          name="Qty"
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
          decimal={SettingSystem.BaseQtyDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 4,
      field: (
        <NumberFormatInForm
          label={`*${translate("ra.field.Price/Unit")}`}
          name="Price"
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
          decimal={SettingSystem.CostPerUnitBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 4,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Dr Acc. Code")}`}
          name="DrAcc"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeList"]}
          updateOtherField={[{ key: "DrAccDesc", optKey: locale === "en-US" ? "Description" : "Description2" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      name: "DrAccDesc",
      field: (
        <DescInForm
          name="DrAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 4,
      name: "TotalAmt",
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Total")}
          name="TotalAmt"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
          readOnly
        />
      ),
    },
    {
      size: 4,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Cr Acc. Code")}`}
          name="CrAcc"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeList"]}
          updateOtherField={[{ key: "CrAccDesc", optKey: locale === "en-US" ? "Description" : "Description2" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      name: "CrAccDesc",
      field: (
        <DescInForm
          name="CrAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 4,
      name: "NetAmt",
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Amount")}
          name="NetAmt"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
          readOnly
        />
      ),
    },
  ];

  const formFieldsTax1 = [
    {
      size: 4,
      field: <SelectInForm label={`${translate("ra.field.Tax Type")}1`} name="TaxType1" options={VatTypeOptions} />,
    },
    {
      size: 4,
      field: (
        <NumberFormatInForm
          label={`${translate("ra.field.Tax Rate")}1`}
          name="TaxRate1"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
      style: { marginTop: 4 },
    },
    {
      size: 4,
      field: <CheckBoxInForm label={translate("ra.field.Overwrite")} name="TaxOverwrite1" />,
    },
    {
      size: 4,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.Tax Cr Acc. Code")}1`}
          name="TaxAcc1"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeList"]}
          updateOtherField={[{ key: "TaxAcc1Desc", optKey: locale === "en-US" ? "Description" : "Description2" }]}
        />
      ),
    },
    {
      size: 4,
      name: "TaxAcc1Desc",
      field: (
        <DescInForm
          name="TaxAcc1Desc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Tax 1")}
          name="TaxAmt1"
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
  ];

  const formFieldsTax2 = [
    {
      size: 4,
      field: <SelectInForm label={`${translate("ra.field.Tax Type")}2`} name="TaxType2" options={VatTypeOptions} />,
    },
    {
      size: 4,
      field: (
        <NumberFormatInForm
          label={`${translate("ra.field.Tax Rate")}2`}
          name="TaxRate2"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 4,
      field: <CheckBoxInForm label={translate("ra.field.Overwrite")} name="TaxOverwrite2" />,
    },
    {
      size: 4,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.Tax Cr Acc. Code")}2`}
          name="TaxAcc2"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeList"]}
          updateOtherField={[{ key: "TaxAcc2Desc", optKey: locale === "en-US" ? "Description" : "Description2" }]}
        />
      ),
    },
    {
      size: 4,
      name: "TaxAcc2Desc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="TaxAcc2Desc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Tax 2")}
          name="TaxAmt2"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
  ];

  if (addMode) {
    return (
      <Create
        {...props}
        formFields={formFieldsEdit}
        formFieldsDetail={formFieldsDetail}
        formFieldsTax1={formFieldsTax1}
        formFieldsTax2={formFieldsTax2}
        arProfileList={lookupList["arProfileList"]}
        lookupList={lookupList}
        useStyles={useStyles}
      />
    );
  } else {
    return (
      <Edit
        {...props}
        copyMode={copyMode}
        formFields={formFieldsEdit}
        formFieldsDetail={formFieldsDetail}
        formFieldsTax1={formFieldsTax1}
        formFieldsTax2={formFieldsTax2}
        arProfileList={lookupList["arProfileList"]}
        lookupList={lookupList}
        wfEnumStatus={wfEnumStatus}
        wfSteps={wfSteps}
        useStyles={useStyles}
      />
    );
  }
};

const ViewMode = (props) => {
  const [wfActive, setWfActive] = useState(false);
  const [wfSteps, setWfSteps] = useState();
  const [wfEnumStatus, setWfEnumStatus] = useState();
  const [hideMailBtn, setHideMailBtn] = useState(false);
  const fetchMail = useCallback(async (pCode) => {
    const r = await getMailProfile(pCode);
    if (r?.Active) {
      setHideMailBtn(false);
    } else {
      setHideMailBtn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchWorkflowByCode = useCallback(async (wfCode) => {
    const r = await getWorkflowByCode(wfCode);
    if (r?.data?.Active) {
      setWfActive(true);
      const enumData = await getEnumApInvoiceStatus();
      setWfEnumStatus(enumData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchWorkflowSteps = useCallback(async (wfCode) => {
    const { data } = await getWorkflowStep(wfCode);
    setWfSteps(data);
  }, []);

  useEffect(() => {
    fetchMail("AR_Invoice");
    fetchWorkflowByCode("AR_INVOICE");
    fetchWorkflowSteps("AR_INVOICE");
  }, [fetchMail, fetchWorkflowByCode, fetchWorkflowSteps]);

  return (
    <Show
      {...props}
      useStyles={useStyles}
      hideMailBtn={hideMailBtn}
      wfActive={wfActive}
      wfEnumStatus={wfEnumStatus}
      wfSteps={wfSteps}
    />
  );
};

export default {
  list: List,
  show: ViewMode,
  edit: SwitchActionMode,
  create: SwitchActionMode,
};
