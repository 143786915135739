/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import { Grid, Typography } from "@material-ui/core";
import { TextFieldInForm } from "components/Form";
import ButtonFooter from "components/ButtonFooter";
import { getUserSearchList } from "services/setting";
import MultiSelectListBox from "react-multiselect-listbox";
import { updateWorkflowDetail } from "services/workflow";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 20,
  },
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: 4,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogItem = (props) => {
  const classes = useStyles();
  const { children, dataDetail, open, onClose } = props;
  const [isBtnLoading, setBtnLoading] = React.useState(false);
  const [data, setData] = useStateWithCallbackLazy(dataDetail);
  const [selectedUser, setSelectedUser] = useState(dataDetail.Users);
  const [userList, setUserList] = useState([]);
  const methods = useForm({ defaultValues: data });

  const { handleSubmit } = methods;

  const fetchUserActiveList = async () => {
    let uriQueryString = {
      WhereRaw: "Active = true",
    };
    const ul = await getUserSearchList(uriQueryString);

    if (ul?.Code === 403) {
      return;
    }
    if (ul) {
      setUserList(ul.Data);
    } else {
      setUserList([]);
    }
  };

  useEffect(() => {
    fetchUserActiveList();
  }, []);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    //Adjust parameter before save
    setData(
      (state) => ({
        ...state,
        ...values,
        Users: [...selectedUser],
      }),
      (nextState) => Save(nextState)
    );
    return false;
  };

  const Save = async (values) => {
    const { Code, InternalMessage, UserMessage } = await updateWorkflowDetail(values);
    if (Code === 0) {
      SnackbarUtils.success(UserMessage);
      handleClose();
      setBtnLoading(false);
    } else {
      setBtnLoading(false);
      if (InternalMessage) {
        SnackbarUtils.error(InternalMessage);
      } else {
        SnackbarUtils.warning(UserMessage);
      }
    }
  };

  const handleClose = () => {
    onClose();
  };

  const CancelFnc = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose(event);
          }
        }}
        scroll={"paper"}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title" onClose={onClose}>
          {props.title}
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <form onKeyDown={disableFormEnter}>
            <div className={classes.root}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} elevation={2}>
                  <TextFieldInForm
                    label="* StepName"
                    name="StepName"
                    variant="outlined"
                    margin="dense"
                    methods={methods}
                    rule={{
                      required: {
                        value: true,
                        message: "* Required",
                      },
                      maxLength: {
                        value: 50,
                        message: "maximum length is 50",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} elevation={2}>
                  <TextFieldInForm
                    label="Description"
                    name="Description"
                    variant="outlined"
                    margin="dense"
                    methods={methods}
                    rule={{
                      maxLength: {
                        value: 255,
                        message: "maximum length is 255",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            {children}
          </form>

          <div className={classes.root}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} elevation={2}>
                <b>Approvals :</b>
              </Grid>
              <Grid item xs={12} elevation={2}>
                <MultiSelectListBox
                  className={"multi-select"}
                  overrideStrings={{
                    search: "Search...",
                    selectAll: "Select All",
                    removeAll: "Remove All",
                    selectedInfo: "Items selected",
                  }}
                  sortable={true}
                  options={userList}
                  textField="UserName"
                  valueField="UserName"
                  value={selectedUser}
                  rowHeight={30}
                  onSelect={({ item, sortedList }) => {
                    setSelectedUser(sortedList.map((i) => i.UserName));
                  }}
                  onRemove={({ item }) => {
                    setSelectedUser([...selectedUser.filter((i) => i !== item.UserName)]);
                  }}
                  onSelectAll={(selectedItems) => {
                    if (selectedItems.length > 0) {
                      const selected = [...selectedUser, ...selectedItems.map((item) => item.UserName)];
                      setSelectedUser(selected);
                    }
                  }}
                  onRemoveAll={() => setSelectedUser([])}
                  onSort={({ sortedList }) => setSelectedUser([...sortedList.map((i) => i.UserName)])}
                  onSearch={({ items, textField, query }) =>
                    items.filter((i) => i.UserName.toLowerCase().includes(query.toLowerCase()))
                  }
                />
              </Grid>
            </Grid>
          </div>

          <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
        </DialogContent>
        <DialogActions>
          <ButtonFooter noBorder disabled={isBtnLoading} SaveFnc={handleSubmit(onSubmit)} CancelFnc={CancelFnc} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogItem;
