/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import clsx from "clsx";
import { Loading, Error, useRedirect, useTranslate } from "react-admin";
import { useMediaQuery, Paper, Grid, Typography } from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiTranslateTable from "components/MuiTranslateTable";
import TextTopInGrid from "components/TextTopInGrid";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import NavRight from "components/NavRightSide";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { permissionName } from "utils/constants";
import { GetWfStatus, WithholdingTaxRefOption } from "utils/options";
import { getPaymentDetail, delPaymentDetail, updatePaymentDetail } from "services/accountPayable";
import { getWorkflowHistory } from "services/workflow";
import CustomToolbarSelect from "./CustomToolbarSelect";
import { showReportByName } from "pages/Report/services";
import DialogSendEmail from "components/DialogSendEmail";
import SnackbarUtils from "utils/SnackbarUtils";
import ReportBackDrop from "components/ReportBackDrop";
import DialogPrintLanguageOptions from "components/DialogPrintLanguageOptions";
import DialogViewPaymentAP from "components/DialogViewPaymentAP";

const Show = (props) => {
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const classes = props.useStyles();
  const { settingAll, DateToString, NumberFormat, ToNumber } = useContext(GblContext);
  const { SettingClosePeriod, SettingAp } = settingAll;
  const redirect = useRedirect();
  const [data, setData] = useState();
  const [sumInvAmt, setSumInvAmt] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error] = useState();
  const [openDim, setOpenDim] = useState(false);
  const [dataDim, setDataDim] = useState();
  const [dataInvoice, setDataInvoice] = useState();
  const [dataId, setDataId] = useState();
  const [whtRef, setWhtRef] = useState();
  const [wfHisList, setWfHisList] = useState();
  const [openDialogIV, setOpenDialogIV] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [openPrintChqDialog, setOpenPrintChqDialog] = useState(false);
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const { basePath, id, permissions, hideMailBtn, wfActive, wfEnumStatus, wfSteps } = props;

  const CheckDisableEditBtn = () => {
    if (wfActive) {
      if (data?.PyhStatus === "Effective") {
        return true;
      }
      //check step
      if (data?.PyhStatus && wfEnumStatus) {
        if (data?.PyhStatus === "Step0") {
          return false;
        }
        var haveItem = wfSteps?.find((item) => item.StepNo === parseInt(wfEnumStatus[data?.PyhStatus]));
        if (typeof haveItem === "undefined") {
          return true;
        }
      }
    }
    if (data?.PyhStatus === "Void" || data?.PyhStatus === "Printed") {
      return true;
    }
    return false;
  };

  const CheckDisableDelBtn = () => data?.PyhStatus === "Void" || data?.PyhStatus === "Printed";

  const CheckPeriod = (date) => {
    if (date < SettingClosePeriod.ClosePeriodAp) {
      return false;
    } else {
      return true;
    }
  };

  const CheckEdit = () => {
    var msgClosePeriod = translate("ra.closePeriod.warning", {
      name: "payment",
      action: "edit",
    });
    // eslint-disable-next-line default-case
    switch (SettingAp.PaymentPostingBy) {
      case "ChequeClearingDate":
        if (!CheckPeriod(data.PyhClearDt)) {
          SnackbarUtils.warning(msgClosePeriod);
        } else {
          if (data.PyhStatus !== "Void" && data.PyhStatus !== "Printed") {
            redirect("edit", basePath, id);
          }
        }
        break;
      case "ChequeDate":
        if (!CheckPeriod(data.PyhChqDt)) {
          SnackbarUtils.warning(msgClosePeriod);
        } else {
          if (data.PyhStatus !== "Void" && data.PyhStatus !== "Printed") {
            redirect("edit", basePath, id);
          }
        }
        break;
      case "PaymentDate":
        if (!CheckPeriod(data.PyhDate)) {
          SnackbarUtils.warning(msgClosePeriod);
        } else {
          if (data.PyhStatus !== "Void" && data.PyhStatus !== "Printed") {
            redirect("edit", basePath, id);
          }
        }
        break;
    }
  };

  const CheckVoid = () => {
    var msgClosePeriod = translate("ra.closePeriod.warning", {
      name: "payment",
      action: "void",
    });
    // eslint-disable-next-line default-case
    switch (SettingAp.PaymentPostingBy) {
      case "ChequeClearingDate":
        if (!CheckPeriod(data.PyhClearDt)) {
          SnackbarUtils.warning(msgClosePeriod);
        } else {
          DelOrVoid();
        }
        break;
      case "ChequeDate":
        if (!CheckPeriod(data.PyhChqDt)) {
          SnackbarUtils.warning(msgClosePeriod);
        } else {
          DelOrVoid();
        }
        break;
      case "PaymentDate":
        if (!CheckPeriod(data.PyhDate)) {
          SnackbarUtils.warning(msgClosePeriod);
        } else {
          DelOrVoid();
        }
        break;
    }
  };

  const CheckDisablePrintCheque = () => {
    if (SettingAp?.AllowRePrintCheque) {
      return false;
    } else {
      return CheckDisableDelBtn();
    }
  };

  const ConfirmPrintCheque = () => {
    SnackbarUtils.confirm(translate("ra.question.confirmPrintCheque"), async function () {
      data.PyhStatus = "Printed";
      props.showReportLoader();
      const { Code, InternalMessage, UserMessage } = await updatePaymentDetail(data);
      if (Code === 0) {
        fetchPaymentById(id);
      } else {
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
      setTimeout(() => {
        props.hideReportLoader();
      }, 2000);
    });
  };

  const CheckPrintCheque = async () => {
    var msg = translate("ra.apPayment.chequeWarnning");
    if (!!data.PyhChqFr === false || !!data.PyhChqDt === false) {
      SnackbarUtils.warning(msg);
      return;
    } else {
      setOpenPrintChqDialog(true);
    }
  };

  const onClosePrintChqDialog = (language) => {
    if (language) {
      setLoadingBtn(true);
      let status = showReportByName("AP_PaymentCheque", [
        { Name: "PyhSeq", Value: id },
        { Name: "LanguageCode", Value: language },
      ]).then(() => {
        if (data.PyhStatus !== "Printed") {
          ConfirmPrintCheque();
        } else {
          if (!SettingAp?.AllowRePrintCheque) {
            ConfirmPrintCheque();
          }
        }
      });
      if (status) {
        setLoadingBtn(false);
      }
    }

    setOpenPrintChqDialog(false);
  };

  const onCloseDialogEmail = (statusCode, UserMessage) => {
    setLoading(true);
    if (statusCode === undefined) {
      setOpenEmailDialog(false);
      setLoading(false);
    }
    if (statusCode === 0) {
      SnackbarUtils.success(UserMessage);
      setOpenEmailDialog(false);
      setLoading(false);
    }
    if (statusCode !== 0 && statusCode !== undefined) {
      SnackbarUtils.error(UserMessage);
      setOpenEmailDialog(false);
      setLoading(false);
    }
  };

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", fnc: () => redirect("create", basePath) },
    {
      name: "Edit",
      disabled: CheckDisableEditBtn(),
      fnc: CheckEdit,
    },
    { name: "Void", disabled: CheckDisableDelBtn(), fnc: CheckVoid },
    {
      name: "Print",
      //disabled: data?.PyhStatus === "Void" ? true : false,
      fnc: () => {
        props.showReportLoader();
        showReportByName("AP_PaymentVoucher", [{ Name: "PyhSeq", Value: id }]);
        setTimeout(() => {
          props.hideReportLoader();
        }, 2000);
      },
    },
    {
      name: "Print Cheque",
      disabled: CheckDisablePrintCheque(),
      fnc: CheckPrintCheque,
    },
    {
      name: "Print WHT",
      //disabled: data?.PyhStatus === "Void" ? true : false,
      fnc: () => {
        props.showReportLoader();
        showReportByName("AP_PaymentWHT", [{ Name: "PyhSeq", Value: id }]);
        setTimeout(() => {
          props.hideReportLoader();
        }, 2000);
      },
    },
    {
      name: "Send Email",
      fnc: () => setOpenEmailDialog(true),
      hide: hideMailBtn,
    },
  ];

  const fetchPaymentById = useCallback(
    async (mounted) => {
      const response = await getPaymentDetail(id);
      if (response) {
        setData(response);
        setSumInvAmt(NumberFormat(response.CurPayAmt));
        setWhtRef(WithholdingTaxRefOption.find((i) => i.key === response.PayWht.WhtRemark1));
        // let dataRow = response?.Detail[0]?.Info;
        // let tempDataInvoice = {
        //   ...dataRow,
        // };
        // setDataInvoice(tempDataInvoice);
      }
      if (mounted) {
        setLoading(false);
      }
    },
    [id, NumberFormat]
  );

  const fetchWorkflowHistory = useCallback(
    async (wfCode) => {
      const { data } = await getWorkflowHistory(wfCode, id);
      if (data) {
        setWfHisList(data);
      }
    },
    [id]
  );

  useEffect(() => {
    let mounted = true;
    fetchPaymentById(mounted);
    fetchWorkflowHistory("AP_PAYMENT");
    return function cleanup() {
      mounted = false;
    };
  }, [fetchPaymentById]);

  const DelOrVoid = async () => {
    let msg = translate("ra.question.confirmVoid", { module: "A/P Payment" });
    SnackbarUtils.voidConfirm(msg, async function (remark) {
      const { Code, InternalMessage, UserMessage } = await delPaymentDetail(
        id,
        localStorage.getItem("UserName"),
        remark
      );
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          fetchPaymentById(id);
        });
      } else {
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    });
  };

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          let dataRow = data?.Detail[value]?.Info;
          let dataId = data?.Detail[value]?.InvhSeq;

          return (
            <>
              <VisibilityIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenDialogIV(!openDialogIV);
                  setDataInvoice(dataRow);
                  setDataId(dataId);

                  // ShowDim(tableMeta.rowData[9].Dim);
                }}
              />
            </>
          );
        },
      },
    },
    {
      name: "Info",
      label: "Invoice No.",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return typeof value === "object" ? value?.InvhInvNo : "";
        },
      },
    },
    {
      name: "Info",
      label: "Date",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return typeof value === "object" ? DateToString(value?.InvhInvDate) : "";
        },
      },
    },
    {
      name: "Info",
      label: "Description",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return typeof value === "object" ? value?.InvdDesc : "";
        },
      },
    },
    {
      name: "Info",
      label: "Currency",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return typeof value === "object" ? value?.CurCode : "";
        },
      },
    },
    {
      name: "Info",
      label: "Rate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return typeof value === "object" ? NumberFormat(value?.CurRate, "currency") : "";
        },
      },
    },
    {
      name: "Info",
      label: "Net Amount",
      options: {
        id: "NetAmount",
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return typeof value === "object" ? NumberFormat(value?.InvoiceDetail?.NetAmt) : "";
        },
      },
    },
    {
      name: "Info",
      label: "Tax Amount",
      options: {
        id: "TaxAmount",
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return typeof value === "object"
            ? NumberFormat(value?.InvoiceDetail.InvdTaxC1 + value?.InvoiceDetail.InvdTaxC2)
            : "";
        },
      },
    },
    {
      name: "Info",
      label: "Total",
      options: {
        id: "Total",
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return typeof value === "object" ? NumberFormat(value?.TotalPrice) : "";
        },
      },
    },
    {
      name: "Paid",
      label: "Paid",
      options: {
        id: "Paid",
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "PaidBAmt",
      label: "Base Amt.",
      options: {
        id: "PaidBAmt",
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DimList",
      label: "DimList",
      options: {
        display: false,
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const options = {
    responsive: "standard",
    selectableRows: "none",
    // serverSide: true,
    fixedHeader: true,
    tableBodyHeight: "300px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    customTableBodyFooterRender: function (opts) {
      let sumNetAmount = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[6]);
        return NumberFormat(s ?? 0);
      }, 0);
      let sumTaxAmount = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[7]);
        return NumberFormat(s ?? 0);
      }, 0);
      let sumAmount = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[8]);
        return NumberFormat(s ?? 0);
      }, 0);
      let sumPaid = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[9]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumBase = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[10]);
        return NumberFormat(s ?? 0);
      }, 0);

      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            {opts.columns.map((col) => {
              if (col.display === "true") {
                switch (col.label) {
                  case "Net Amount":
                    return (
                      <TableCell key={col.label?.toString()} className={footerClasses}>
                        {sumNetAmount}
                      </TableCell>
                    );
                  case "Tax Amount":
                    return (
                      <TableCell key={col.label?.toString()} className={footerClasses}>
                        {sumTaxAmount}
                      </TableCell>
                    );
                  case "Total":
                    return (
                      <TableCell key={col.label?.toString()} className={footerClasses}>
                        {sumAmount}
                      </TableCell>
                    );
                  case "Paid":
                    return (
                      <TableCell key={col.label?.toString()} className={footerClasses}>
                        {sumPaid}
                      </TableCell>
                    );
                  case "Base Amt.":
                    return (
                      <TableCell key={col.label?.toString()} className={footerClasses}>
                        {sumBase}
                      </TableCell>
                    );
                  default:
                    return <TableCell key={col.id?.toString()} className={footerClasses} />;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
  };

  const columnsWht = [
    {
      name: "Id",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        display: false,
      },
    },
    {
      name: "WhtCode",
      label: "Code",
      options: {
        sort: true,
      },
    },
    {
      name: "WhtDesc",
      label: "Description",
      options: {
        sort: true,
      },
    },
    {
      name: "WhtRate",
      label: "Tax Rate %",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "Amount",
      label: "Total",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "TaxAmt",
      label: "Tax",
      options: {
        id: "Tax",
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
  ];

  const optionsWht = {
    responsive: "standard",
    selectableRows: "none",
    // serverSide: true,
    fixedHeader: true,
    tableBodyHeight: "200px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    customTableBodyFooterRender: function (opts) {
      let sumTaxamt = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[5]);
        return NumberFormat(s ?? 0);
      }, 0);
      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            {opts.columns.map((col) => {
              if (col.display === "true") {
                if (col.label === "Tax") {
                  return (
                    <TableCell key={col.label?.toString()} className={footerClasses}>
                      {sumTaxamt}
                    </TableCell>
                  );
                } else {
                  return <TableCell key={col.label?.toString()} className={footerClasses} />;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
    onRowsDelete: (rowsDeleted) => {
      const removeArray = rowsDeleted.data.map((i) => i.index);
      for (var i = removeArray.length - 1; i >= 0; i--) data.PayWht.Items.splice(removeArray[i], 1);
    },
  };

  const ShowDim = (values) => {
    if (!values) {
      setDataDim();
      setOpenDim(true);
    } else {
      setDataDim();
      setOpenDim(true);
    }
  };

  const SearchByNo = (e) => {
    if (e) {
      redirect("show", basePath, e.PyhSeq);
    }
  };

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <div
      // className={clsx(classes.drawer, {
      //   [classes.drawerOpen]: openDim,
      //   [classes.drawerClose]: !openDim,
      // })}
      className={classes.drawerClose}
    >
      <ActionMenu
        menuControl={menuControlProp}
        permission={permissions.find((i) => i.Name === permissionName["AP.Payment"])}
      />

      {data &&
        wfEnumStatus &&
        data.PyhStatus !== "Effective" &&
        data.PyhStatus !== "Void" &&
        data.PyhStatus !== "Printed" &&
        data.PyhStatus !== "Step0" && (
          <CustomToolbarSelect
            selectedRows={{ data: data }}
            status={parseInt(wfEnumStatus[data.PyhStatus])}
            wfCode={"AP_PAYMENT"}
            fncSuccess={() => {
              fetchPaymentById();
              fetchWorkflowHistory("AP_PAYMENT");
            }}
          />
        )}

      {/* Payment Form */}
      <Paper className={classes.root}>
        <BoxHeader
          header={"Payment"}
          showSearch
          searchOption={{
            placeholder: `${translate("ra.fieldAbbr.searchby")} ${translate("ra.field.Payment No.")}`,
            update: SearchByNo,
            module: "AP_Payment",
            keyCode: "PyhSeq",
            keyDesc: "PyhDesc",
          }}
          data={data}
          wfStatus={GetWfStatus(wfSteps, wfEnumStatus, data.PyhStatus)}
          status={
            GetWfStatus(wfSteps, wfEnumStatus, data.PyhStatus)
              ? GetWfStatus(wfSteps, wfEnumStatus, data.PyhStatus)
              : data.PyhStatus
          }
        />
        <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 6 }}>
          <TextTopInGrid sizeSm={2} label="Payment No." value={data.PyhSeq} />
          <TextTopInGrid sizeSm={2} label="Payment Date" value={DateToString(data.PyhDate)} />
          <TextTopInGrid sizeSm={4} label="Vendor" value={`${data.VnCode} ${data.VnName ? ` : ${data.VnName}` : ""}`} />

          <TextTopInGrid sizeSm={2} label="Currency" value={data.CurCode} />
          <TextTopInGrid sizeSm={2} label="Rate" value={NumberFormat(data.CurRate, "currency")} />
          <TextTopInGrid sizeSm={2} label="Cheq. No. From" value={data.PyhChqFr} />
          <TextTopInGrid sizeSm={2} label="Cheq. No. To" value={data.PyhChqTo} />
          <TextTopInGrid
            sizeSm={4}
            label="Payment Type"
            value={data.AppyCode ? `${data.AppyCode} ${data.AppyDesc ? ` : ${data.AppyDesc}` : ""}` : ""}
          />
          <TextTopInGrid sizeSm={4} label="Description" multiline={true} value={data.PyhDesc} />

          <TextTopInGrid
            sizeSm={2}
            label="Cheq. Date"
            value={DateToString(data.PyhChqDt)}
            style={{ marginTop: !isXSmall ? -46 : 4 }}
          />
          <TextTopInGrid
            sizeSm={2}
            label="Clearing Date"
            value={DateToString(data.PyhClearDt)}
            style={{ marginTop: !isXSmall ? -46 : 4 }}
          />
        </Grid>
        <MuiTranslateTable data={data.Detail} columns={columns} options={options} />
      </Paper>
      {/* Withholding Tax */}
      <Paper elevation={1} style={{ marginBottom: 6 }}>
      <Accordion defaultExpanded={false}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            <Typography className={classes.heading}>{translate("ra.field.Withholding Tax")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 6 }}>
              <TextTopInGrid sizeSm={2} label="WHT. Form" value={data.PayWht.WhtTypeCode} />
              <TextTopInGrid sizeSm={2} label="TAX ID." value={data.PayWht.TaxId} />
              <TextTopInGrid sizeSm={2} label="Branch No." value={data.PayWht.BranchNo} />
              <TextTopInGrid sizeSm={1} label="Title" value={data.PayWht.PrePayee} />
              <TextTopInGrid sizeSm={2} label="WHT. Name" value={data.PayWht.Payee} />
              <TextTopInGrid sizeSm={3} label="Address" value={data.PayWht.Address} />
              <TextTopInGrid sizeSm={2} label="WHT. No" value={data.PayWht.WhtNo} />
              <TextTopInGrid
                sizeSm={2}
                label="WHT Condition"
                value={whtRef ? `${whtRef.key} : ${whtRef.value}` : null}
              />
              <TextTopInGrid sizeSm={8} label="Remark" value={data.PayWht.WhtRemark2} />
              <Grid item xs={12}>
                <MuiTranslateTable data={data.PayWht.Items} columns={columnsWht} options={optionsWht} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>

      {/* Payment Account */}
      <Paper className={classes.root}>
        <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 6 }}>
          <Grid item xs={8} />
          <Grid item xs={4}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6}>
                <Typography variant="body2">{translate("ra.field.Payment Amount")}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="right">
                  {NumberFormat(data.CurAmt ?? 0)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.heading} gutterBottom>
                  {translate("ra.field.Payment Account")}
                </Typography>
              </Grid>
              <TextTopInGrid
                sizeSm={4}
                label={translate("Dept.")}
                value={data.DeptPayCode ? `${data.DeptPayCode} ${data.DeptPayDesc ? `: ${data.DeptPayDesc}` : ""}` : ""}
              />
              <TextTopInGrid
                sizeSm={5}
                label={translate("Acc.")}
                value={data.PyhCr ? `${data.PyhCr} ${data.PyhCrDesc ? `: ${data.PyhCrDesc}` : ""}` : ""}
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={1} alignItems="center" style={{ marginTop: 8 }}>
              <Grid item xs={6}>
                <Typography variant="body2">{translate("ra.field.Settlement Base Amount")}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="right">
                  {NumberFormat(sumInvAmt)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.heading}> {translate("ra.field.Gain/Loss Account")} </Typography>
              </Grid>
              <TextTopInGrid
                sizeSm={4}
                label={translate("Dept.")}
                value={data.GainLossDept ? `${data.GainLossDept} : ${data.GainLossDeptDesc}` : ""}
              />
              <TextTopInGrid
                sizeSm={5}
                label={translate("Acc.")}
                value={data.GainLossAcc ? `${data.GainLossAcc} : ${data.GainLossAccDesc}` : ""}
              />
              {/* <Grid item xs={3}>
                <Typography variant="body2" className="wrapText">
                  {data.DeptGL ? `${data.DeptGLDesc} : ${data.DeptGLDesc}` : ""}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" className="wrapText">
                  {data.GainLossAcc ? `${data.GainLossAccDesc} : ${data.GainLossAccDesc}` : ""}
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={1} alignItems="center" style={{ marginTop: 8 }}>
              <Grid item xs={6}>
                <Typography variant="body2"> {translate("ra.field.Gain/Loss Amount")} </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="right">
                  {NumberFormat(data.GainLossAmt ?? 0)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.heading}> {translate("ra.field.WHT. Account")} </Typography>
              </Grid>
              <TextTopInGrid
                sizeSm={4}
                label={translate("Dept.")}
                value={
                  data.PayWht.WhtDeptCode
                    ? `${data.PayWht.WhtDeptCode} ${data.PayWht.WhtDeptDesc ? `: ${data.PayWht.WhtDeptDesc}` : ""}`
                    : ""
                }
              />
              <TextTopInGrid
                sizeSm={5}
                label={translate("Acc.")}
                value={
                  data.PayWht.WhtTaxCr
                    ? `${data.PayWht.WhtTaxCr} ${data.PayWht.WhtTaxCrDesc ? `: ${data.PayWht.WhtTaxCrDesc}` : ""}`
                    : ""
                }
              />
              {/* <Grid item xs={3}>
                <Typography variant="body2" className="wrapText">
                  {data.WhtDept ? `${data.WhtDeptDesc} : ${data.WhtDeptDesc}` : ""}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" className="wrapText">
                  {data.WhtAcc ? `${data.WhtAccDesc} : ${data.WhtAccDesc}` : ""}
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={1} alignItems="center" style={{ marginTop: 8 }}>
              <Grid item xs={6}>
                <Typography variant="body2">{translate("ra.field.Withholding Tax")}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="right">
                  {NumberFormat(data.PayWht?.WhtTotalAmt ?? 0)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8} />
          <Grid item xs={4}>
            <Grid container spacing={1} alignItems="center" style={{ marginTop: 8 }}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  <b>{translate("ra.field.Net Payment")}</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="right">
                  <b>{NumberFormat(data.PyhAmt)}</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <NavRight
        open={openDim}
        close={() => setOpenDim(false)}
        ShowDim={() => ShowDim()}
        dataDim={dataDim}
        dataInvoice={dataInvoice}
        module={"AP_Payment"}
        moduleId={id}
        wfHisList={wfHisList}
      />
      {openEmailDialog && (
        <DialogSendEmail
          title={"Send A/P Payment To Email"}
          open={openEmailDialog}
          onClose={onCloseDialogEmail}
          data={data}
          module={"AP_Payment"}
          keyId="PyhSeq"
        />
      )}
      {openPrintChqDialog && (
        <DialogPrintLanguageOptions
          open={openPrintChqDialog}
          onClose={onClosePrintChqDialog}
          headerText="Select display text in language"
          isLoading={isLoadingBtn}
        />
      )}

      {openDialogIV && (
        <DialogViewPaymentAP
          open={openDialogIV}
          onClose={() => setOpenDialogIV(false)}
          data={dataInvoice}
          Id={`${dataId}`}
        />
      )}

      <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
    </div>
  );
};

export default ReportBackDrop(Show);
