import List from "./List";
import AccountDetail from "./AccountDetail";

const Show = (props) => {
  console.log(props);
  return <AccountDetail {...props} />;
};

export default {
  list: List,
  show: Show,
};
