import React, { useState, useContext, createElement, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import { withStyles } from "@material-ui/core/styles";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { Divider, useMediaQuery, Box, Typography, Avatar, Icon } from "@material-ui/core";
import { DashboardMenuItem, MenuItemLink, getResources, usePermissions, useTranslate } from "react-admin";
import { getLicenseList } from "services/setting";
import SubMenu from "./SubMenu";
import settingsSvg from "assets/settings.svg";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Skeleton from "@material-ui/lab/Skeleton";
import { differenceInDays, format } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";

const avartarWidth = {
  width: 36,
  height: 36,
  backgroundColor: "#2196f3",
};

const avatarText = {
  fontSize: 18,
};

const styles = (theme) => ({
  flexOutside: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "flex-start",
    height: "100%",
  },
  flexInside: {
    display: "flex",
    flexFlow: "column nowrap",
  },
  liveChatBtn: {
    width: "100%",
    position: "absolute",
  },
  liveChatBtnMini: {
    bottom: 0,
    position: "fixed",
    width: 60,
    marginLeft: 8,
    marginBottom: 10,
    cursor: "pointer",
  },
});

const Menu = withStyles(styles)(({ onMenuClick, dense, logout, classes }) => {
  const [state, setState] = useState({
    menuGl: false,
    menuAp: false,
    menuAr: false,
    menuAsset: false,
  });
  const [licenseList, setLicenseList] = useStateWithCallbackLazy([]);
  const [complete, setComplete] = useState(false);
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const { DateToString } = useContext(GblContext);
  // useSelector((state) => {
  //   if (window.location.hash === "#/setting" || window.location.hash === "#/administrator") {
  //     state.admin.ui.sidebarOpen = false;
  //   } else {
  //     state.admin.ui.sidebarOpen = true;
  //   }
  // });
  useSelector((state) => state.theme); // force rerender on theme change
  const { loaded, permissions } = usePermissions();
  const permissionDashboard = permissions?.find((i) => i.Name === "Sys.Dashboard");
  const resources = useSelector(getResources);
  const resourcesSubmenu = resources.filter((i) => i.options.IsSubmenu);
  const translate = useTranslate();

  const handleToggle = (menu) => {
    const newState = {};
    Object.keys(state).forEach((item) => {
      if (menu === item) {
        newState[item] = !state[item];
      } else {
        newState[item] = false;
      }
    });
    setState(newState);
  };

  const CheckProductLicense = useCallback(async () => {
    const response = await getLicenseList();
    const list = response?.InterfacePermissionItems;
    if (list) {
      setLicenseList(list, async (nextState) => await SetLicenstInMenu(nextState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SetLicenstInMenu = async (list) => {
    resourcesSubmenu.forEach((element) => {
      element.show = list.find((i) => i.UniqueId.Id === element.options.id).CanUse;
    });
    setComplete(true);
  };

  useEffect(() => {
    if (!complete) {
      CheckProductLicense();
    }
  }, [CheckProductLicense, complete]);

  useEffect(() => {
    if (licenseList && licenseList.length > 0) {
      resourcesSubmenu.forEach((element) => {
        element.show = licenseList.find((i) => i.UniqueId.Id === element.options.id).CanUse;
      });
    }
  }, [resources, resourcesSubmenu, licenseList]);

  const MenuWithPermission = ({ resource }) => {
    let disabled = false;
    if (permissions) {
      const foundItem = permissions.find((i) => i.Name === resource.options?.permitName);
      if (foundItem) {
        disabled = !foundItem.View;
      }
    }

    return loaded ? (
      <>
        {open && (
          <Typography
            variant="h6"
            style={{
              fontSize: "1rem",
              color: "rgba(0, 0, 0, 0.54)",
              marginLeft: 14,
            }}
          >
            {resource.options.headerTitle}
          </Typography>
        )}
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={translate(`ra.module.${resource.options.label}`) || resource.name}
          //primaryText={resource.options.label || resource.name}
          leftIcon={resource.icon ? createElement(resource.icon) : null}
          onClick={() => {
            window.sessionStorage.removeItem("basePath");
            let t = localStorage.getItem("Tenant");
            window.sessionStorage.removeItem(`${t}_searchDetails`);
            return onMenuClick;
          }}
          sidebarIsOpen={open}
          disabled={disabled}
        />
        {resource.options.endHeader && <Divider style={{ margin: "4px 0" }} />}
      </>
    ) : null;
  };

  const AlertExpLicense = () => {
    const lsExpDate = localStorage.getItem("expLicense");
    if (!lsExpDate) {
      return null;
    }
    const expiryDate = new Date(localStorage.getItem("expLicense"));
    const days = differenceInDays(expiryDate, new Date());
    let statusAlert = "success";
    if (days > 30 && days <= 60) {
      statusAlert = "warning";
    }
    if (days <= 30) {
      statusAlert = "error";
    }
    return (
      <>
        <Alert
          severity={statusAlert}
          style={{
            padding: 0,
            cursor: statusAlert === "success" ? "none" : "pointer",
          }}
          onClick={(e) => {
            if (days > 30 && days <= 60) {
              return SnackbarUtils.warning(
                `Product license will be expired in ${days} day(s) (${format(
                  expiryDate,
                  "dd/MM/yyyy"
                )}).\nPlease contact your administrator to extend the license.`
              );
            }
            if (days <= 30) {
              return SnackbarUtils.warning(
                `Product license will be expired in ${days} day(s) (${format(
                  expiryDate,
                  "dd/MM/yyyy"
                )}).\nPlease contact your administrator to extend the license.`
              );
            }
            e.preventDefault();
          }}
        >
          <Typography component="span" variant="body2" color="text.primary">
            {DateToString(expiryDate)}
          </Typography>
        </Alert>
      </>
    );
  };

  return (
    <div className={classes.flexOutside}>
      <div style={open ? { width: 240 } : {}} className={classes.flexInside}>
        {permissionDashboard?.View ? <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} /> : null}
        {resourcesSubmenu.map((subResource, idx) => (
          <React.Fragment key={idx}>
            {
              {
                true: (
                  <SubMenu
                    key={subResource.name}
                    handleToggle={() => handleToggle(subResource.name)}
                    isOpen={state[subResource.name]}
                    sidebarIsOpen={open}
                    name={translate(`ra.module.${subResource.options.label}`) || subResource.name}
                    //name={subResource.options.label || subResource.name}
                    icon={subResource.icon}
                    dense={dense}
                  >
                    {resources.map((resource) =>
                      resource.options && subResource.name === resource.options.parentName ? (
                        <MenuWithPermission key={resource.name} resource={resource} open={open} />
                      ) : (
                        ""
                      )
                    )}
                  </SubMenu>
                ),
                false: null,
                undefined: (
                  <Box display="flex" alignItems="center" style={{ height: 48, padding: 4 }} key={subResource.name}>
                    <Box margin={1}>
                      <Skeleton variant="circle">
                        <Avatar />
                      </Skeleton>
                    </Box>
                    <Box width="70%">
                      <Skeleton width="100%">
                        <Typography>.</Typography>
                      </Skeleton>
                    </Box>
                  </Box>
                ),
              }[subResource.show]
            }
          </React.Fragment>
        ))}
        {/* {!isXSmall && ( */}
        <>
          <MenuItemLink
            key={"setting"}
            to={"/setting"}
            primaryText={translate("ra.module.Setting")}
            //primaryText={"Setting"}
            leftIcon={
              <Avatar
                variant="square"
                style={{
                  width: 24,
                  height: 24,
                  margin: 1,
                }}
                alt="Settings"
                src={settingsSvg}
              />
            }
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />

          <MenuItemLink
            key={"Administrator"}
            to={"/administrator"}
            primaryText={translate("ra.module.Configuration")}
            //primaryText={"Administrator"}
            leftIcon={<Icon>settings</Icon>}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        </>
        {open ? (
          <div
            style={{
              bottom: !isXSmall ? 54 : 0,
              marginTop: "20px",
            }}
            //className={classes.liveChatBtn}
          >
            {localStorage.getItem("expLicense") && (
              <ListItem style={{ paddingBottom: 0 }}>
                <ListItemText disableTypography primary="Subscription end date" secondary={<AlertExpLicense />} />
              </ListItem>
            )}

            <Divider />
            <ListItem
              onClick={() => {
                window.open(
                  "https://salesiq.zoho.com/signaturesupport.ls?widgetcode=bb036bbbc0b76993498e317b47a24066e84f8b12503b2ed35389aaff97d9666bbf2341d7694395a95bdee6c3a472246e"
                );
              }}
              style={{ cursor: "pointer" }}
            >
              <ListItemAvatar>
                <Avatar style={avartarWidth}>
                  <Icon style={avatarText}>chat</Icon>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Live Chat" />
            </ListItem>
          </div>
        ) : (
          <div className={classes.liveChatBtnMini}>
            <Avatar
              style={avartarWidth}
              onClick={() => {
                window.open(
                  "https://salesiq.zoho.com/signaturesupport.ls?widgetcode=bb036bbbc0b76993498e317b47a24066e84f8b12503b2ed35389aaff97d9666bbf2341d7694395a95bdee6c3a472246e"
                );
              }}
            >
              <Icon style={avatarText}>chat</Icon>
            </Avatar>
          </div>
        )}

        {isXSmall && logout}
      </div>
    </div>
  );
});

export default Menu;
