import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import { useAuthState, useTranslate, useLocale } from "react-admin";
import {
  TextFieldInForm,
  NumberFormatInForm,
  MuiAutosuggest,
  DateInForm,
  DescInForm,
  SelectInForm,
  CheckBoxInForm,
} from "components/Form";
import { Typography } from "@material-ui/core";
import {
  getAccountCodeList,
  getDepartmentList,
  getArPaymentTypeList,
  getUnitList,
  getMailProfile,
} from "services/setting";
import { getWorkflowByCode, getWorkflowStep } from "services/workflow";
import { getEnumApInvoiceStatus } from "services/enum";
import { getLookupCurrency } from "services/lookup";
import { getArProfileList } from "services/accountReceivable";
import List from "./List";
import Show from "./Show";
import Edit from "./Edit";
import Create from "./Create";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import { addDays } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 12,
    marginBottom: 12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  bold: {
    fontWeight: 600,
  },
  footerCell: {
    backgroundColor: theme.palette.background.paper,
    borderTop: "2px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    textAlign: "right",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  drawerOpen: {
    marginRight: drawerWidth,
  },
  drawerClose: {
    marginRight: 54,
  },
  borderTableTop: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  borderTable: {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  divComment: {
    position: "relative",
    height: "20px",
    width: "200px",
  },
  parentStyle: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "100%",
  },
  cellStyleEllipsis: {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const ViewMode = (props) => {
  const [wfActive, setWfActive] = useState(false);
  const [wfSteps, setWfSteps] = useState();
  const [wfEnumStatus, setWfEnumStatus] = useState();
  const [hideMailBtn, setHideMailBtn] = useState(false);
  const fetchMail = useCallback(async (pCode) => {
    const r = await getMailProfile(pCode);
    if (r?.Active) {
      setHideMailBtn(false);
    } else {
      setHideMailBtn(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchWorkflowByCode = useCallback(async (wfCode) => {
    const r = await getWorkflowByCode(wfCode);
    if (r?.data?.Active) {
      setWfActive(true);
      const enumData = await getEnumApInvoiceStatus();
      setWfEnumStatus(enumData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchWorkflowSteps = useCallback(async (wfCode) => {
    const { data } = await getWorkflowStep(wfCode);
    setWfSteps(data);
  }, []);

  useEffect(() => {
    fetchMail("AR_Receipt");
    fetchWorkflowByCode("AR_Receipt");
    fetchWorkflowSteps("AR_Receipt");
  }, [fetchMail, fetchWorkflowByCode, fetchWorkflowSteps]);

  return (
    <Show
      {...props}
      useStyles={useStyles}
      hideMailBtn={hideMailBtn}
      wfActive={wfActive}
      wfEnumStatus={wfEnumStatus}
      wfSteps={wfSteps}
    />
  );
};
const SwitchActionMode = (props) => {
  const locale = useLocale();
  const translate = useTranslate();
  const classes = useStyles();
  const { authenticated } = useAuthState();
  const [wfSteps, setWfSteps] = useState();
  const [wfEnumStatus, setWfEnumStatus] = useState();
  const { settingAll, ToMySqlDate, ToNumber } = useContext(GblContext);
  const { SettingClosePeriod, SettingSystem } = settingAll;
  const { ClosePeriodAr } = SettingClosePeriod;
  const newClosePeriodAr = addDays(new Date(ClosePeriodAr), 1);
  const [oldAccList, setOldAccList] = useState([]);
  const addMode = props.location.pathname.search("create") !== -1;
  const [lookupList, setLookupList] = useState({
    accountCodeListCA: [],
    accountCodeListGA: [],
    accountCodeListWA: [],
    accountCodeListBC: [],
    accountCodeListDA: [],
    departmentList: [],
    currencyList: [],
    arProfileList: [],
    arPaymentTypeList: [],
    unitList: [],
  });
  const fetchAccLookup = useCallback(async () => {
    const { Data } = await getAccountCodeList("Ar");
    setLookupList((state) => ({
      ...state,
      accountCodeListCA: Data,
      accountCodeListGA: Data,
      accountCodeListWA: Data,
      accountCodeListBC: Data,
      accountCodeListDA: Data,
    }));
    setOldAccList(Data);
  }, []);
  const fetchDeptLookup = useCallback(async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      departmentList: Data,
    }));
  }, []);
  const fetchCurrencyLookup = useCallback(async () => {
    const p = {
      Module: "AR",
      CurrDate: ToMySqlDate(new Date()),
    };
    const arr = await getLookupCurrency(p);
    setLookupList((state) => ({
      ...state,
      currencyList: arr,
    }));
  }, [ToMySqlDate]);
  const fetchArProfileLookup = useCallback(async () => {
    const { Data } = await getArProfileList();
    setLookupList((state) => ({
      ...state,
      arProfileList: Data,
    }));
  }, []);
  const fetchPaymentTypeLookup = useCallback(async () => {
    const { Data } = await getArPaymentTypeList();
    setLookupList((state) => ({
      ...state,
      arPaymentTypeList: Data,
    }));
  }, []);
  const fetchUnitLookup = useCallback(async () => {
    const { Data } = await getUnitList();
    setLookupList((state) => ({
      ...state,
      unitList: Data,
    }));
  }, []);
  const fetchWorkflowByCode = useCallback(async (wfCode) => {
    const r = await getWorkflowByCode(wfCode);
    if (r?.data?.Active) {
      const enumData = await getEnumApInvoiceStatus();
      setWfEnumStatus(enumData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchWorkflowSteps = useCallback(async (wfCode) => {
    const { data } = await getWorkflowStep(wfCode);
    setWfSteps(data);
  }, []);

  useEffect(() => {
    if (authenticated) {
      fetchAccLookup();
      fetchDeptLookup();
      fetchArProfileLookup();
      fetchCurrencyLookup();
      fetchPaymentTypeLookup();
      fetchUnitLookup();
      fetchWorkflowByCode("AR_RECEIPT");
      fetchWorkflowSteps("AR_RECEIPT");
    }
  }, [
    authenticated,
    fetchAccLookup,
    fetchDeptLookup,
    fetchArProfileLookup,
    fetchCurrencyLookup,
    fetchPaymentTypeLookup,
    fetchUnitLookup,
    fetchWorkflowByCode,
    fetchWorkflowSteps,
  ]);

  const handleFocus = (e) => {
    e.target.select();
    setTimeout(function () {
      e.target.setSelectionRange(0, e.target.value.length);
    }, 0);
  };

  const formFieldsEdit = [
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Receipt Ref. No.")}
          name="RcptRefNo"
          variant="outlined"
          margin="dense"
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
            maxLength: {
              value: 30,
              message: "maximum length is 30",
            },
          }}
          disabled
        />
      ),
    },
    {
      size: 1,
      field: <CheckBoxInForm label="Auto" name="RunNoType" disabled={!addMode} />,
    },
    {
      size: 2,
      field: (
        <DateInForm
          label={translate("ra.field.Receipt Date")}
          name="RcpthDate"
          minDate={new Date(newClosePeriodAr)}
          minDateMessage={"Date must be more than close period"}
          //for custom advance update field
          useFncUpdate={true}
          fncUpdate={async (value, methods) => {
            const p = {
              Module: "AR",
              CurrDate: ToMySqlDate(value),
            };
            const newCurrList = await getLookupCurrency(p);
            const currentCurCode = methods.watch("CurrCode");
            const currentCurRate = methods.watch("CurrRate");
            const newCurrRate = newCurrList.find((i) => i.CurrCode === currentCurCode).CurrRate;
            if (newCurrRate !== ToNumber(currentCurRate)) {
              SnackbarUtils.confirm(translate("ra.question.currencyWarning"), function () {
                methods.setValue("CurrRate", newCurrRate);
              });
            }
            setLookupList((state) => ({
              ...state,
              currencyList: newCurrList,
            }));
          }}
          required
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.A/R No.")}`}
          name="ArNo"
          optKey="ArNo"
          optDesc="Company"
          optDesc2="FirstName"
          options={lookupList["arProfileList"]}
          disabled={!addMode}
          updateOtherField={[
            { key: "Company", optKey: "Company", optKey2: "FirstName" },
            { key: "BillToName", optKey: "FirstName" },
            { key: "BillToCompany", optKey: "Company" },
            { key: "AddressInfo", optKey: "AddressInfo" },
            { key: "PayType", optKey: "PaymentCode" },
            { key: "PayTypeDesc", optKey: "PaymentDesc" },
          ]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      name: "Company",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="Company"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Currency")}`}
          name="CurrCode"
          optKey="CurrCode"
          optDesc="CurrRate"
          options={lookupList["currencyList"]}
          updateOtherField={[{ key: "CurrRate", optKey: "CurrRate" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 1,
      field: (
        <NumberFormatInForm
          label={`*${translate("ra.field.Rate")}`}
          name="CurrRate"
          rule={{
            min: {
              value: 0.000001,
              message: "* Required",
            },
            required: {
              value: true,
              message: "* Required",
            },
          }}
          decimal={SettingSystem.CurrencyRateDecimal}
          // decimalSep={SettingSystem.DecimalSeparator}
          // thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Pay Ref No.")}
          name="PayRefNo"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 30,
              message: "maximum length is 30",
            },
          }}
        />
      ),
    },
    {
      size: 1,
      field: <div />,
      implementation: "css",
      smDown: true,
      component: { Hidden },
    },
    {
      size: 2,
      field: (
        <DateInForm
          label={translate("ra.field.Pay Date")}
          name="PayDate"
          minDate={new Date(newClosePeriodAr)}
          minDateMessage={"Date must be more than close period"}
          required
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Pay Type")}`}
          name="PayType"
          optKey="Code"
          optDesc="Desc"
          options={lookupList["arPaymentTypeList"]}
          updateOtherField={[{ key: "PayTypeDesc", optKey: "Desc" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      name: "PayTypeDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="PayTypeDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    // {
    //   size: 4,
    //   field: <div></div>,
    //   implementation: "css",
    //   smDown: true,
    //   component: { Hidden },
    // },
    {
      size: 3,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Description")}
          name="RcptDesc"
          variant="outlined"
          margin="dense"
          multiline
          rows={5}
          rule={{
            maxLength: {
              value: 255,
              message: "maximun length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Tax Inv No.")}
          name="TaxInvNo"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 30,
              message: "maximum length is 30",
            },
          }}
          disabledByOtherField={"RunTaxType"}
        />
      ),
      style: { marginTop: -70 },
    },
    {
      size: 1,
      field: <CheckBoxInForm label="Auto" name="RunTaxType" />,
      style: { marginTop: -70 },
    },
    {
      size: 2,
      field: (
        <NumberFormatInForm
          label={`${translate("ra.fieldAbbr.rate")}(%)`}
          name="TaxRate"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
      style: { marginTop: -70 },
    },
    {
      size: 2,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Amount")}
          name="TaxAmt"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
          disabledByOtherField={"TaxOverwrite"}
        />
      ),
      style: { marginTop: -70 },
    },
    {
      size: 2,
      field: <CheckBoxInForm label={translate("ra.field.Overwrite")} name="TaxOverwrite" />,
      style: { marginTop: -70 },
    },
  ];

  const formFieldsReceiptCrAccount = [
    {
      size: 2,
      field: <Typography className={classes.heading}> {translate("ra.field.Cr Account")} </Typography>,
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Cr Dept. Code")}`}
          name="DeptCr"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "DeptCrDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeListCA: daccList,
              }));
              //remove acc if not in defaultaccount
              const acc = methods.getValues("CrAcc");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("CrAcc", "");
                methods.setValue("CrAccDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeListCA: oldAccList,
              }));
            }
          }}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      name: "DeptCrDesc",
      field: (
        <DescInForm
          name="DeptCrDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Cr Acc. Code")}`}
          name="CrAcc"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeListCA"]}
          updateOtherField={[
            {
              key: "CrAccDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      name: "CrAccDesc",
      field: (
        <DescInForm
          name="CrAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
  ];

  const formFieldsGainLossAccount = [
    {
      size: 2,
      field: <Typography className={classes.heading}>{translate("ra.field.Gain/Loss Account")}</Typography>,
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Dept. Code")}
          name="DeptGL"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "DeptGLDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeListGA: daccList,
              }));
              //remove acc if not in defaultaccount
              const acc = methods.getValues("GainLossAcc");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("GainLossAcc", "");
                methods.setValue("GainLossAccDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeListGA: oldAccList,
              }));
            }
          }}
          clearable
        />
      ),
    },
    {
      size: 2,
      name: "DeptGLDesc",
      field: (
        <DescInForm
          name="DeptGLDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Acc. Code")}
          name="GainLossAcc"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeListGA"]}
          updateOtherField={[
            {
              key: "GainLossAccDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
          clearable
        />
      ),
    },
    {
      size: 4,
      name: "GainLossAccDesc",
      field: (
        <DescInForm
          name="GainLossAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
  ];

  const formFieldsBank = [
    {
      size: 2,
      field: <Typography className={classes.heading}>{translate("ra.field.Bank Charge")}</Typography>,
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Dept. Code")}
          name="BankChargeDept"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "BankChargeDeptDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeListBC: daccList,
              }));
              //remove acc if not in defaultaccount
              const acc = methods.getValues("BankChargeAcc");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("BankChargeAcc", "");
                methods.setValue("BankChargeAccDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeListBC: oldAccList,
              }));
            }
          }}
        />
      ),
    },
    {
      size: 2,
      name: "BankChargeDeptDesc",
      field: (
        <DescInForm
          name="BankChargeDeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Acc. Code")}
          name="BankChargeAcc"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeListBC"]}
          updateOtherField={[
            {
              key: "BankChargeAccDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
        />
      ),
    },
    {
      size: 4,
      name: "BankChargeAccDesc",
      field: (
        <DescInForm
          name="BankChargeAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
  ];

  const formFieldsReceiptDrAccount = [
    {
      size: 2,
      field: <Typography className={classes.heading}>{translate("ra.field.Dr Account")}</Typography>,
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Dr Dept. Code")}`}
          name="DeptDr"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "DeptDrDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeListDA: daccList,
              }));
              //remove acc if not in defaultaccount
              const acc = methods.getValues("DrAcc");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("DrAcc", "");
                methods.setValue("DrAccDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeListDA: oldAccList,
              }));
            }
          }}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      name: "DeptDrDesc",
      field: (
        <DescInForm
          name="DeptDrDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Dr Acc. Code")}`}
          name="DrAcc"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeListDA"]}
          updateOtherField={[
            {
              key: "DrAccDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      name: "DrAccDesc",
      field: (
        <DescInForm
          name="DrAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
  ];

  const formFieldsBilling = [
    {
      size: 5,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Name")}
          name="BillToName"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 5,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Company")}
          name="BillToCompany"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <SelectInForm
          label={translate("ra.field.Billing To")}
          name="BillTo"
          options={["Address1", "Address2", "Address3"]}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Address")}
          name="BillToAddress"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
        // <TextFieldInForm
        //   label="Address"
        //   name="BillToAddress"
        //   variant="outlined"
        //   margin="dense"
        //   multiline
        //   rows={4}
        //   rule={{
        //     maxLength: {
        //       value: 255,
        //       message: "maximun length is 255",
        //     },
        //   }}
        // />
      ),
    },
  ];

  const formFieldsWht = [
    {
      size: 2,
      field: <Typography className={classes.heading}>{translate("ra.field.WHT. Account")}</Typography>,
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Dept.")}
          name="WhtDept"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[
            { key: "WhtDeptDesc", optKey: "Description" },
            { key: "WhtDept2", optKey: "DeptCode" },
            { key: "WhtDeptDesc2", optKey: "Description" },
          ]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeListWA: daccList,
              }));
              //remove acc if not in defaultaccount
              const acc = methods.getValues("WhtAcc");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("WhtAcc", "");
                methods.setValue("WhtAcc2", "");
                methods.setValue("WhtAccDesc", "");
                methods.setValue("WhtAccDesc2", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeListWA: oldAccList,
              }));
            }
          }}
        />
      ),
    },
    {
      size: 2,
      name: "WhtDeptDesc",
      field: (
        <DescInForm
          name="WhtDeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Acc. Code")}
          name="WhtAcc"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeListWA"]}
          updateOtherField={[
            {
              key: "WhtAccDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
            { key: "WhtAcc2", optKey: "AccCode" },
            {
              key: "WhtAccDesc2",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
        />
      ),
    },
    {
      size: 4,
      name: "WhtAccDesc",
      field: (
        <DescInForm
          name="WhtAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
  ];

  const formFieldsWht2 = [
    {
      size: 2,
      field: <Typography className={classes.heading}>{translate("ra.field.WHT. Account")}</Typography>,
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Dept.")}
          name="WhtDept2"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "WhtDeptDesc2", optKey: "Description" }]}
          disabled
        />
      ),
    },
    {
      size: 2,
      name: "WhtDeptDesc2",
      field: (
        <DescInForm
          name="WhtDeptDesc2"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Acc. Code")}
          name="WhtAcc2"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeListWA"]}
          updateOtherField={[
            {
              key: "WhtAccDesc2",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
          disabled
        />
      ),
    },
    {
      size: 4,
      name: "WhtAccDesc2",
      field: (
        <DescInForm
          name="WhtAccDesc2"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
  ];

  if (addMode) {
    return (
      <Create
        {...props}
        formFields={formFieldsEdit}
        formFieldsReceiptCrAccount={formFieldsReceiptCrAccount}
        formFieldsReceiptDrAccount={formFieldsReceiptDrAccount}
        formFieldsGainLossAccount={formFieldsGainLossAccount}
        formFieldsBank={formFieldsBank}
        formFieldsBilling={formFieldsBilling}
        formFieldsWht={formFieldsWht}
        formFieldsWht2={formFieldsWht2}
        arProfileList={lookupList["arProfileList"]}
        useStyles={useStyles}
        handleFocus={handleFocus}
      />
    );
  } else {
    return (
      <Edit
        {...props}
        formFields={formFieldsEdit}
        formFieldsReceiptCrAccount={formFieldsReceiptCrAccount}
        formFieldsReceiptDrAccount={formFieldsReceiptDrAccount}
        formFieldsGainLossAccount={formFieldsGainLossAccount}
        formFieldsBank={formFieldsBank}
        formFieldsBilling={formFieldsBilling}
        formFieldsWht={formFieldsWht}
        formFieldsWht2={formFieldsWht2}
        arProfileList={lookupList["arProfileList"]}
        wfEnumStatus={wfEnumStatus}
        wfSteps={wfSteps}
        useStyles={useStyles}
        handleFocus={handleFocus}
      />
    );
  }
};

export default {
  list: List,
  show: ViewMode,
  edit: SwitchActionMode,
  create: SwitchActionMode,
};
