/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useTranslate } from "react-admin";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Box, Button, Typography, Divider } from "@material-ui/core";
import ButtonFooter from "components/ButtonFooter";
import ButtonUpload from "components/ButtonUpload";
import fileReader from "utils/fileReader";
import SnackbarUtils from "utils/SnackbarUtils";

import { UnControlled as CodeMirror } from "react-codemirror2";
require("codemirror/mode/properties/properties.js");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 10,
  },
  flexBox: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: 4,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default function DialogItem(props) {
  const translate = useTranslate();
  const classes = useStyles();
  const { data, open, onClose, update, filename } = props;
  const [originalData, setOriginalData] = useState([]);
  const [newData, setNewData] = useState(data);
  const [fileUpload, setFileUpload] = useState();

  React.useEffect(() => {
    const original = _.cloneDeep(data);
    setOriginalData(original);
  }, []);

  function useForceUpdate() {
    let [value, setState] = useState(true);
    return () => setState(!value);
  }

  let forceUpdate = useForceUpdate();

  const uploadFile = async (e) => {
    if (e.target.files.length >= 1) {
      let msg = fileReader.CheckLimitImgSize(e.target.files);
      if (msg) {
        document.getElementById("fileConfig").value = "";
        SnackbarUtils.error(translate(msg, { size: "5" }));
        return;
      }
      setFileUpload(e.target.files[0].name);
      let files = Array.from(e.target.files);
      const filePathsPromises = [];
      files.forEach((file) => {
        filePathsPromises.push(fileReader.ToBase64(file));
      });
      const txt = await Promise.all(filePathsPromises);
      const original = _.cloneDeep(data);
      setOriginalData(original);
      setNewData(txt.join(""));
      forceUpdate();
      update(txt.join(""));
    }
  };

  const exportFile = async (data) => {
    var a = document.createElement("a");
    var json = data,
      blob = new Blob([json], { type: "octet/stream" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const Save = () => {
    update(newData);
    onClose();
  };

  const CancelFnc = () => {
    update(originalData);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose(event);
        }
      }}
      scroll={"paper"}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="scroll-dialog-title" contenteditable>
        ConfigFile
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        <div className={classes.root}>
          <Box p={1} display="flex">
            <div id="placeholder"></div>
            <Box p={1} flexGrow={1}>
              <ButtonUpload
                id="fileConfig"
                name="fileConfig"
                style={{ display: "none" }}
                type="file"
                onChange={uploadFile}
                accept=".txt,.ini,(*.*)"
                fileUpload={fileUpload}
                cancelUpload={() => {
                  setFileUpload();
                  document.getElementById("fileConfig").value = "";
                }}
              />
            </Box>
            <Box p={1}>
              <Button variant="contained" color="primary" onClick={() => exportFile(newData)}>
                Export
              </Button>
            </Box>
          </Box>
          <Divider />
          <Box p={1} flexGrow={1}>
            <CodeMirror
              value={data}
              options={{
                mode: "text/x-ini",
                readOnly: false,
                lineNumbers: true,
                lineWrapping: false,
              }}
              onChange={(editor, data, value) => {
                setNewData(value);
              }}
            />
            {/* <TextField
                style={{ width: "100%", height: 580 }}
                label="Config"
                name="Config"
                variant="outlined"
                value={data}
                onChange={(e) => setNewData(e.target.value)}
                margin="dense"
                multiline
              /> */}
            {/* <TextareaAutosize
            style={{ width: "100%" }}
            aria-label="minimum height"
            defaultValue={data}
            onChange={(e) => setNewData(e.target.value)}
          /> */}
          </Box>
        </div>
      </DialogContent>

      <DialogActions>
        <ButtonFooter noBorder SaveFnc={Save} CancelFnc={CancelFnc} />
      </DialogActions>
    </Dialog>
  );
}
