/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useTranslate, useLocale } from "react-admin";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { getMappingInfByCode, updateMappingInfByCode } from "services/interface";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { TableHead } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Typography, MenuItem, Hidden } from "@material-ui/core";
import MUIDataTable, { TableHead as MuiTableHead } from "mui-datatables";
import { MuiAutosuggest, TextFieldInForm, SelectInForm, DescInForm } from "components/Form";
import fileReader from "utils/fileReader";
import PopupTable from "components/PopupTable";
import SnackbarUtils from "utils/SnackbarUtils";
import ButtonUpload from "components/ButtonUpload";
import csv from "csvtojson";
import gbl from "utils/formatter";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 20,
  },
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: 4,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  circulLoading: {
    margin: 50,
    display: "flex",
    justifyContent: "center",
  },
  textCancel: {
    color: "inherit",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default function DialogItem(props) {
  const classes = useStyles();
  const translate = useTranslate();
  const [isLoading, setBtnLoading] = useState(false);
  const locale = useLocale();
  const [loading, setLoading] = useState(false);
  const { open, onClose, lookupList, id, postType, docType, configuration } = props;
  const [data, setData] = useStateWithCallbackLazy();
  const [newFormField, setNewFormField] = useState([]);
  const [columns, setColumns] = useState();
  const [showAdd, setShowAdd] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [fileMapping, setFileMapping] = useState();
  const cfg = configuration();

  const SwitchTypeBox = (item, arr) => {
    if (item.ListOfValues !== null) {
      var arrListOfValue = [];
      if (item.ListOfValues !== null) {
        let items = item.ListOfValues.replace(/\n/g, "")
          .split("],[")
          .map((item) => item.replace(/[[\]]/g, ""));
        let result = items.map((item) => {
          let [code, desc] = item.split(":");
          return { key: code?.replace(/ /g, ""), value: desc };
        });
        arrListOfValue = result;
      }
    }

    switch (item.Type) {
      case "Text":
        arr.push({
          size: 12,
          field: <TextFieldInForm label={item.Caption} name={item.Caption} variant="outlined" margin="dense" />,
        });
        break;
      case "List":
        arr.push({
          size: 12,
          field: (
            <SelectInForm
              style={{ marginTop: 0 }}
              label={item.Caption}
              name={item.Caption}
              options={arrListOfValue}
              emptyOption={
                <MenuItem value="">
                  <em>--None--</em>
                </MenuItem>
              }
              keyInDesc
            />
          ),
        });
        break;
      default:
        return <div></div>;
    }
  };

  const fetchMappingList = useCallback(async () => {
    setLoading(true);
    var r;
    r = await getMappingInfByCode(id, docType, "GL");
    let c = gbl.Base64DecodeUnicode(cfg.ConfigBase64);
    let cString = gbl.ParseINIString(c);
    const mapCode = cString.System.Mapping_MapCode;
    // Remove the square brackets and split the string by ']['
    const parts = mapCode.slice(1, -1).split("][");
    // Map over the array to create objects
    const mapCodeObj = parts.reduce((acc, part) => {
      const [key, value] = part.split(":");
      acc[key] = value;
      return acc;
    }, {});

    const valueOfMapCode = Object.values(mapCodeObj);

    // if (postType !== "Json") {
    //   r = await getMappingInfByCode(id, docType, "GL");
    // }

    const { Columns, Rows } = r;
    if (Columns) {
      const newColumns = Columns.map(({ ColumnName: name, Caption: label }) => ({
        name,
        label,
      }));
      newColumns.unshift({
        name: "index",
        label: " ",
        options: {
          filter: false,
          viewColumns: false,
          download: false,
          customBodyRender: (value) => {
            return (
              <>
                <EditIcon
                  fontSize="small"
                  color="primary"
                  style={{ cursor: "pointer", marginLeft: 10 }}
                  onClick={() => UpdateRow(value)}
                />
              </>
            );
          },
        },
      });
      Rows.forEach((item, idx) => {
        item.index = idx;
      });

      let newArr = [];
      newColumns.forEach((item, idx) => {
        let valuesArr = valueOfMapCode.find((k) => k === item.name);
        if (valuesArr) {
          if (valuesArr === "Description") {
            newArr.push({
              size: 12,
              field: (
                <TextFieldInForm
                  {...item}
                  variant="outlined"
                  margin="dense"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ),
            });
          } else {
            newArr.push({
              size: 6,
              field: (
                <TextFieldInForm
                  {...item}
                  variant="outlined"
                  margin="dense"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ),
            });
          }
        }
        if (item.name === "DeptCode") {
          if (idx % 2 === 0) {
            newArr.push({
              size: 6,
              field: <div></div>,
              implementation: "css",
              smDown: true,
              component: { Hidden },
            });
          }
          newArr.push(
            {
              size: 6,
              field: (
                <MuiAutosuggest
                  label="* Department"
                  name="DeptCode"
                  optKey="DeptCode"
                  optDesc="Description"
                  options={lookupList["departmentList"]}
                  updateOtherField={[{ key: "DeptDesc", optKey: "Description" }]}
                  rule={{
                    required: {
                      value: true,
                      message: "* Required",
                    },
                  }}
                />
              ),
            },
            {
              size: 6,
              name: "DeptDesc",
              field: (
                <DescInForm
                  style={{ marginTop: 8 }}
                  name="DeptDesc"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ),
            }
          );
        }
        if (item.name === "AccCode") {
          newArr.push(
            {
              size: 6,
              field: (
                <MuiAutosuggest
                  label="* Account #"
                  name="AccCode"
                  optKey="AccCode"
                  optDesc={locale === "en-US" ? "Description" : "Description2"}
                  options={lookupList["accountCodeList"]}
                  updateOtherField={[{ key: "AccDesc", optKey: locale === "en-US" ? "Description" : "Description2" }]}
                  rule={{
                    required: {
                      value: true,
                      message: "* Required",
                    },
                  }}
                />
              ),
            },
            {
              size: 6,
              name: "AccDesc",
              field: (
                <DescInForm
                  style={{ marginTop: 8 }}
                  name="AccDesc"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ),
            }
          );
        }
        if (item.name === "TA") {
          newArr.push({
            size: 6,
            field: <TextFieldInForm label={item.label} name={item.name} variant="outlined" margin="dense" disabled />,
          });
        }
        if (item.name === "Descr") {
          newArr.push({
            size: 6,
            field: <TextFieldInForm label={item.label} name={item.name} variant="outlined" margin="dense" disabled />,
          });
        }
        if (cfg?.Dimension) {
          var k = cfg.Dimension.find((i) => i.Caption === item.name);
          if (k) {
            SwitchTypeBox(k, newArr);
            // newArr.push({
            //   size: 12,
            //   field: <TextFieldInForm label={item.label} name={item.name} variant="outlined" margin="dense" />,
            // });
          }
        }
      });

      setNewFormField(newArr);
      setColumns(newColumns);
      setData(Rows);
    }

    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  useEffect(() => {
    fetchMappingList();
  }, [fetchMappingList]);

  const Save = async () => {
    setBtnLoading(true);
    const newData = data.map(({ OriginalRow, RowState, ...rest }) => {
      return rest;
    });

    try {
      var r;
      if (postType !== "Json") {
        r = await updateMappingInfByCode(id, docType, "GL", newData);
      } else if (postType === "Json") {
        r = await updateMappingInfByCode(id, docType, "GL", newData);
      }

      const { Code, UserMessage } = r;
      if (Code === 0) {
        setBtnLoading(false);
        SnackbarUtils.success(UserMessage, function () {
          onClose();
        });
      }
    } catch (error) {
      setBtnLoading(false);
    }
  };

  const options = {
    responsive: "standard",
    selectableRows: "multiple",
    fixedHeader: true,
    tableBodyHeight: "500px",
    search: false,
    download: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      return `\uFEFF${buildHead(columns)}${buildBody(data)}`;
    },
    downloadOptions: {
      separator: ",",
      filename: "AccountMapping.csv",
      filterOptions: {
        useDisplayedRowsOnly: true,
        useDisplayedColumnsOnly: true,
      },
    },
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    onRowsDelete: (rowsDeleted) => {
      const removeArray = rowsDeleted.data.map((i) => i.index);
      for (var i = removeArray.length - 1; i >= 0; i--) data.splice(removeArray[i], 1);
    },
  };

  const UpdateRow = (value) => {
    setEditIndex(value);
    setShowAdd(true);
  };

  const SaveFromPopup = (arr, row) => {
    const index = arr.findIndex((el) => el.index === editIndex);
    if (editIndex !== "") {
      //update
      arr[index] = row;
      setShowAdd(false);
    } else {
      //create
      row.index = arr.length;
      arr = [...arr, row];
      setShowAdd(false);
    }
    setData(arr);
  };

  const CancelFromPopup = () => {
    setShowAdd(false);
  };

  const CustomHeader = (props) => {
    return (
      <>
        <TableHead>
          {/* <TableRow>
            <TableCell align="center" colSpan={1}>
              <IconButton size={"small"} onClick={() => AddNewRow()} style={{ marginLeft: 6 }}>
                <AddIcon />
              </IconButton>
            </TableCell>
            <TableCell align="right" colSpan={10}></TableCell>
          </TableRow> */}
        </TableHead>
        <MuiTableHead {...props} />
      </>
    );
  };

  const uploadFile = async (e) => {
    if (e.target.files.length >= 1) {
      let file = e.target.files[0];
      setFileMapping(file);

      var csvData = await fileReader.ToBase64(file);
      const json = await csv().fromString(csvData);
      //remove last array if empty
      if (Object.values(json[json.length - 1]).length === 1) {
        json.pop();
      }
      //add index
      for (const [i] of json.entries()) {
        json[i].index = i;
      }
      setData(json);
    } else {
      if (e.target.id === "fileMapping") {
        document.getElementById("fileMapping").value = "";
      }
    }
  };

  const LoadingButton = ({ text, disabled, fnc }) => {
    return (
      <div className={classes.wrapper}>
        <Button variant="contained" color="primary" disabled={disabled} onClick={fnc}>
          {text}
        </Button>
        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose(event);
          }
        }}
        scroll={"paper"}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="scroll-dialog-title" onClose={onClose}>
          Mapping code
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          {loading ? (
            <div className={classes.circulLoading}>
              <CircularProgress />
            </div>
          ) : (
            <MUIDataTable
              title={
                <div style={{ textAlign: "left" }}>
                  <ButtonUpload
                    id="fileMapping"
                    name="fileMapping"
                    style={{ display: "none" }}
                    type="file"
                    onChange={uploadFile}
                    accept=".csv,(*.*)"
                    fileUpload={fileMapping?.name}
                    btntext={"Import file mapping"}
                    noCaption
                    cancelUpload={() => {
                      setFileMapping();
                      document.getElementById("fileMapping").value = "";
                    }}
                  />
                </div>
              }
              data={data}
              columns={columns}
              options={options}
              components={{
                TableHead: CustomHeader,
              }}
            />
          )}
          {showAdd && (
            <PopupTable
              initialValues={data[editIndex]}
              formFields={newFormField}
              update={() => {}}
              open={showAdd}
              save={(row) => SaveFromPopup(data, row)}
              cancel={CancelFromPopup}
            />
          )}
          <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
        </DialogContent>
        <DialogActions>
          <LoadingButton text="OK" disabled={isLoading} fnc={() => Save(data)} />
          <Button variant="outlined" className={classes.textCancel} onClick={onClose}>
            {translate("ra.action.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
