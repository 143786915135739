import React from "react";
import { Tooltip, IconButton, Icon } from "@material-ui/core";

const CustomToolbar = ({ basePath, id }) => {
  return (
    <React.Fragment>
      <Tooltip title={"Settings"}>
        <a href={`#${basePath}/${id}/show`}>
          <IconButton aria-label="close">
            <Icon className="material-icons-outlined" fontSize={"medium"}>
              settings
            </Icon>
          </IconButton>
        </a>
      </Tooltip>
    </React.Fragment>
  );
};

export default CustomToolbar;
