/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useRedirect, useTranslate } from "react-admin";
import { Loading } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  useMediaQuery,
  FormControl,
  Chip,
  Select,
  InputLabel,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { getApVendorSearchList } from "services/accountPayable";
import { getWorkflowStep } from "services/workflow";
import { getEnumApInvoiceStatus } from "services/enum";
import MuiTranslateTable from "components/MuiTranslateTable";
import ActionMenu from "components/ActionMenu";
import CustomTablePagination from "components/CustomTablePagination";
import MbCard from "./MbCard";
import MbTextFieldSearch from "./MbTextFieldSearch";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ModelUriQueryString from "models/uriQueryString";
import CustomToolbarSelect from "./CustomToolbarSelect";
import { permissionName } from "utils/constants";
import { GetWfStatus } from "utils/options";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  bullStatusActive: {
    height: 10,
    width: 10,
    backgroundColor: "green",
    borderRadius: "50%",
    display: "inline-block",
  },
  bullStatusInActive: {
    height: 10,
    width: 10,
    backgroundColor: "red",
    borderRadius: "50%",
    display: "inline-block",
  },
  statusDraft: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
  },
  statusNormal: {
    backgroundColor: "#2196f3",
    color: "white",
  },
  statusVoid: {
    backgroundColor: "#e57373",
    color: "white",
  },
}));

const List = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const { basePath, permissions } = props;

  //initial Value with window.sessionStorage
  const ws = window.sessionStorage;
  let t = localStorage.getItem("Tenant");
  const filterValue =
    ws.getItem("basePath") === basePath && ws.getItem(`${t}_searchDetails`)
      ? JSON.parse(ws.getItem(`${t}_searchDetails`)).filterValue
      : undefined;
  const searchQuery =
    ws.getItem("basePath") === basePath && ws.getItem(`${t}_searchDetails`)
      ? JSON.parse(ws.getItem(`${t}_searchDetails`)).searchQuery
      : undefined;
  const searchResults =
    ws.getItem("basePath") === basePath && ws.getItem(`${t}_searchDetails`)
      ? JSON.parse(ws.getItem(`${t}_searchDetails`)).searchResults
      : undefined;

  const [Data, setData] = useState(searchResults ? searchResults : undefined);
  const [Paging, setPaging] = useState();
  const [uriQueryString, setUriQueryString] = useState({
    Limit: searchQuery ? searchQuery.Limit : ModelUriQueryString.Limit,
    Page: searchQuery ? searchQuery.Page : ModelUriQueryString.Page,
    OrderBy: searchQuery ? searchQuery.OrderBy : ModelUriQueryString.OrderBy,
    WhereGroupList: searchQuery ? searchQuery.WhereGroupList : ModelUriQueryString.WhereGroupList,
    Exclude: searchQuery ? searchQuery.Exclude : ModelUriQueryString.Exclude,
    WhereRaw: searchQuery ? searchQuery.WhereRaw : ModelUriQueryString.WhereRaw,
    WhereLike: searchQuery ? searchQuery.WhereLike : ModelUriQueryString.WhereLike,
    WhereLikeFields: searchQuery ? searchQuery.WhereLikeFields : ModelUriQueryString.WhereLikeFields,
  });
  const [searchText, setSearchText] = useState(filterValue ? filterValue.searchValue : undefined);
  const [wfSteps, setWfSteps] = useState();
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [wfEnumStatus, setWfEnumStatus] = useState();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("E");
  const [wfStatus, setWfStatus] = useState(
    filterValue ? filterValue.status : localStorage.getItem("defaultWfVendor") ?? "All"
  );

  const redirect = useRedirect();

  const handleFilterList = () => {
    uriQueryString.WhereGroupList = [
      {
        AndOr: "And",
        ConditionList: [],
      },
    ];

    switch (status) {
      case "All":
        if (wfSteps) {
          uriQueryString.WhereGroupList[0].ConditionList.push({
            AndOr: "And",
            Field: "VdStatus",
            Operator: "=",
            Value: "E",
          });
          uriQueryString.WhereGroupList[0].ConditionList.push({
            AndOr: "Or",
            Field: "VdStatus",
            Operator: "=",
            Value: "V",
          });
        } else {
          uriQueryString.WhereGroupList[0].ConditionList.push({
            AndOr: "And",
            Field: "Active",
            Operator: "=",
            Value: true,
          });
          uriQueryString.WhereGroupList[0].ConditionList.push({
            AndOr: "Or",
            Field: "Active",
            Operator: "=",
            Value: false,
          });
        }
        break;
      default:
        if (wfSteps) {
          uriQueryString.WhereGroupList[0].ConditionList.push({
            AndOr: "And",
            Field: "Active",
            Operator: "=",
            Value: wfSteps?.length === parseInt(status) ? "E" : status,
          });
        } else {
          uriQueryString.WhereGroupList[0].ConditionList.push({
            AndOr: "And",
            Field: "Active",
            Operator: "=",
            Value: status === "E" ? true : false,
          });
        }
        break;
    }
  };

  const fetchSearchList = async (uriQueryString, mounted = true) => {
    setLoading(true);
    handleFilterList();
    const { Data, Paging } = await getApVendorSearchList(uriQueryString);
    if (Data) {
      setData(Data);
      setPaging(Paging);
      setUriQueryString(uriQueryString);
    } else {
      setData([]);
    }
    if (mounted) {
      setLoading(false);
    }
  };

  const fetchWorkflow = async () => {
    const enumData = await getEnumApInvoiceStatus();
    setWfEnumStatus(enumData);
    const { data } = await getWorkflowStep("AP_VENDOR");
    if (data) {
      const showc = data?.find((item) => item.StepNo === parseInt(status));
      if (parseInt(status) === data?.length) {
        setShowCheckBox(false);
      }
      if (showc && data && parseInt(status) !== data?.length) {
        setShowCheckBox(true);
      } else {
        setShowCheckBox(false);
      }
      setWfSteps(data);
    }
    return data;
  };

  useEffect(() => {
    let mounted = true;
    fetchSearchList(uriQueryString, mounted);
    fetchWorkflow();
    return function cleanup() {
      mounted = false;
    };
  }, [uriQueryString, status]);

  const handleRequestSort = (property, order) => {
    uriQueryString.OrderBy = { [property]: order };
    fetchSearchList(uriQueryString);
  };

  if (loading) return <Loading />;
  if (!Data) return null;

  const handleChangePage = (e, newPage) => {
    uriQueryString.Page = newPage + 1;
    fetchSearchList(uriQueryString);
  };

  const handleChangeRowsPerPage = (e) => {
    const newRowsPerPage = parseInt(e.target.value);
    uriQueryString.Limit = newRowsPerPage;
    fetchSearchList(uriQueryString);
  };

  const handleTableChange = (action, tableState) => {
    if (action === "onSearchClose") {
      //clear window.sessionstorage (when user click close icon)
      ws.clear();
      setSearchText();
      uriQueryString.WhereLike = "";
      fetchSearchList(uriQueryString);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    uriQueryString.WhereLike = `%${value}%`;
    uriQueryString.WhereLikeFields = ["VnId", "VnCode", "VnName", "VnAttn", "VnCateCode"];
    uriQueryString.Page = 1;
    fetchSearchList(uriQueryString);
  };

  const columns = [
    {
      name: "Id",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <a href={`#${basePath}/${value}/show`}>
              <VisibilityIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  //set window.storage value
                  const filterValueObj = {
                    searchValue: searchText,
                    status: status,
                  };
                  ws.setItem("basePath", basePath);
                  ws.setItem(
                    `${t}_searchDetails`,
                    JSON.stringify({
                      filterValue: filterValueObj,
                      searchQuery: uriQueryString,
                      searchDetails: Data,
                    })
                  );
                  redirect("show", basePath, value);
                }}
              />
            </a>
          );
        },
      },
    },
    {
      name: "VnCode",
      label: "Vendor",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "VnName",
      label: "Vendor Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "VnAttn",
      label: "Contact Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "VnCateCode",
      label: "Category",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "VnEmail",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: wfSteps ? "VdStatus" : "Active",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (wfSteps) {
            return (
              <Chip
                label={
                  wfSteps?.length === status
                    ? GetWfStatus(wfSteps, wfEnumStatus, wfStatus)
                    : GetWfStatus(wfSteps, wfEnumStatus, value)
                }
                size="small"
                className={clsx(classes.statusDraft, {
                  [classes.statusNormal]: value === "Effective",
                  [classes.statusVoid]: value === "Void",
                })}
              />
            );
          } else {
            return (
              <>
                <span
                  className={clsx({
                    [classes.bullStatusActive]: value,
                    [classes.bullStatusInActive]: !value,
                  })}
                />{" "}
                {value ? translate("ra.fieldAbbr.active") : translate("ra.fieldAbbr.inactive")}
              </>
            );
          }
        },
      },
    },
  ];

  const options = {
    filter: false, // show the filter icon in the toolbar (true by default)
    responsive: "standard",
    selectableRows: showCheckBox ? "multiple" : "none",
    isRowSelectable: (dataIndex) => {
      if (Data[dataIndex].VdStatus === "Effective" || Data[dataIndex].VdStatus === "Void") {
        return false;
      }
      return true;
    },
    serverSide: true,
    confirmFilters: true,
    searchText: searchText,
    searchProps: {
      onKeyUp: (e) => {
        if (e.keyCode === 13) {
          handleSearch(e.target.value);
        }
      },
    },
    download: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      return `\uFEFF${buildHead(columns)}${buildBody(data)}`;
    },
    onTableChange: (action, tableState) => handleTableChange(action, tableState),
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    sortOrder: {
      name: Object.keys(uriQueryString.OrderBy)[0],
      direction: Object.values(uriQueryString.OrderBy)[0],
    },
    onColumnSortChange: (changedColumn, direction) => {
      handleRequestSort(changedColumn, direction);
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <CustomToolbarSelect
          noBorder
          data={Data}
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          status={status}
          wfCode={"AP_VENDOR"}
          fncSuccess={() => fetchSearchList(uriQueryString)}
        />
      );
    },
    customFooter: () => {
      return (
        <CustomTablePagination
          rowsPerPageOptions={[15, 50, 100]}
          component="div"
          count={Paging?.TotalRecordCount ?? 0}
          rowsPerPage={Paging?.Limit ?? 15}
          page={Paging?.Page ? Paging.Page - 1 : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      );
    },
  };

  const menuControlProp = [{ name: "Add", fnc: () => redirect("create", basePath) }];

  const CustomFilterList = () => {
    return (
      <>
        <Box display="flex">
          <Box p={1}>
            <FormControl variant="outlined">
              <InputLabel id="status">{translate("ra.field.ViewBy")}</InputLabel>
              <Select
                variant="outlined"
                margin="dense"
                labelId="status"
                label={translate("ra.field.ViewBy")}
                value={status}
                onChange={(e) => {
                  setUriQueryString({ ...uriQueryString, Page: 1 });
                  const showc = wfSteps?.find((item) => item.StepNo === parseInt(e.target.value));
                  if (showc && wfSteps) {
                    setShowCheckBox(true);
                  } else {
                    setShowCheckBox(false);
                  }
                  setStatus(e.target.value);
                  setWfStatus(null);
                }}
                style={{ width: 180 }}
              >
                <MenuItem key={"All"} value={"All"}>
                  {translate("ra.field.All")}
                </MenuItem>
                <MenuItem key={"E"} value={"E"}>
                  {translate("ra.field.Active")}
                </MenuItem>
                <MenuItem key={"V"} value={"V"}>
                  {translate("ra.field.In-Active")}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        {wfSteps?.length > 0 ? (
          <Typography variant="h6" gutterBottom style={{ marginLeft: 18, marginTop: 4, marginBottom: 0 }}>
            Workflow
          </Typography>
        ) : null}
        <Box display="flex">
          {wfSteps?.map((item, idx) => (
            <Box p={1} key={idx}>
              <Chip
                clickable
                variant={item.StepName === wfStatus ? "default" : "outlined"}
                color={item.StepName === wfStatus ? "primary" : "default"}
                value={item.StepNo?.toString()}
                label={item.StepName}
                onClick={(e) => {
                  setUriQueryString({ ...uriQueryString, Page: 1 });
                  setWfStatus(e.target.outerText);
                  setStatus(item.StepNo);
                }}
              />
            </Box>
          ))}
        </Box>
      </>
    );
  };

  const MbFilterList = () => {
    return (
      <>
        <Typography variant="h6" gutterBottom style={{ marginLeft: 18, marginTop: 4 }}>
          Vendor List
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginLeft: 16 }}>
            <MbTextFieldSearch
              status={status}
              setStatus={setStatus}
              setShowCheckBox={setShowCheckBox}
              wfSteps={wfSteps}
              searchFnc={handleSearch}
              searchText={searchText}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const MbCards = ({ item }) => {
    return (
      <div
        style={{ margin: "5px 10px", padding: "10px 6px 0 6px" }}
        onClick={() => redirect("show", basePath, item.InvhSeq)}
      >
        <MbCard data={item} />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <ActionMenu
        menuControl={menuControlProp}
        permission={permissions.find((i) => i.Name === permissionName["AP.Vendor"])}
      />

      {isXSmall && <MbFilterList />}

      {isXSmall ? (
        Data.length > 0 ? (
          Data.map((item, idx) => <MbCards key={idx} item={item} />)
        ) : (
          <MbCards item={null} />
        )
      ) : (
        <MuiTranslateTable
          title={"Vendor"}
          data={Data}
          columns={columns}
          options={options}
          components={{
            TableFilterList: CustomFilterList,
          }}
        />
      )}
    </div>
  );
};

export default List;
